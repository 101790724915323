import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ManageOrderTransportMode } from '../../models/manage-order-transport-mode.models';
import { ManageOrderDriverDelivery } from '../../models/manage-order-driver-delivery.models';

@Component({
    selector: 'driver-delivery',
    template: require('./driver-delivery.component.html'),
    styles: [require('./driver-delivery.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDeliveryComponent {
    @Input() public delivery: ManageOrderDriverDelivery;
    @Input() public transportMode: ManageOrderTransportMode;

    @HostBinding('class.map-hovered')
    @Input() public mapHovered: boolean;
}
