import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
    IAcceptEditTimeEvent,
    IDeliveryTimesItem,
    IDeliveryTimesTimeFormState
} from "../../../../interfaces/delivery-times";
import { FormBuilder, FormGroup } from "@angular/forms";
import { generateRange } from "../../utils";

@Component({
    selector: 'modal-delivery-times-time-edit',
    template: require('./modal-delivery-times-time-edit.component.html'),
    styles: [require('./modal-delivery-times-time-edit.component.less')],
})
export class ModalDeliveryTimesTimeEditComponent {
    @ViewChild('modal')
    public modal: ModalDirective;

    @Output()
    public ok: EventEmitter<IAcceptEditTimeEvent> = new EventEmitter();

    public item: IDeliveryTimesItem;
    public form: FormGroup;

    public timeRanges: number[];
    public translations: {[key: string]: string};

    constructor(
        fb: FormBuilder,
    ) {
        this.form = fb.group({
            acceptTime: null,
        });

        this.timeRanges = (() => {
            const STARTAT = 5;
            const ENDAT = 60;
            const STEP = 5;
            return generateRange(STARTAT, ENDAT, STEP);
        })();
    }

    public open(item: IDeliveryTimesItem): void {
        this.item = item;
        const {acceptTime} = item;
        this.form.setValue({acceptTime});
        this.modal.show();
    }

    public close(): void {
        this.modal.hide();
    }

    public complete(): void {
        if (this.form.valid) {
            this.ok.emit({formState: this.form.value as IDeliveryTimesTimeFormState, item: this.item});
        }
    }

    public displayRange(range: number): string {
        return range === 0 ? this.translations.open : `${range} min`;
    }
}
