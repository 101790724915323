import { Response as NgxResponse } from '@angular/http';
import { Response } from './response';

export class ResponseFactory {
    public static fromResponse<T>(response: NgxResponse): Response<T> {
        return new Response<T>(response.json());
    }

    public static toData<T>(ngxResponse: NgxResponse): T {
        return ResponseFactory.fromResponse<T>(ngxResponse).toData();
    }
}
