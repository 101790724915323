
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { User } from './user';
import { HungrigClient, ResponseFactory, ErrorFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import {RestaurantOnlinestatus} from "../../../../../admin/src/app/http";

@Injectable()
export class UserService extends BaseService<User> {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getRestaurantOnlinestatus(clientKey: string): Observable<RestaurantOnlinestatus> {
        return this.hungrigClient.get(this.url('/cms/restaurants/' + clientKey + '/onlinestatus')).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public setOnline(clientKey: string, online: boolean): Observable<RestaurantOnlinestatus> {
        console.log(clientKey)
        console.log(online)
        return this.hungrigClient.put(this.url(`/rocms/status/setOnline/${clientKey}`, false), { online }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public brandUsers(): Observable<User[]> {
        return this.hungrigClient.get(this.url('restaurants/me/brand/restaurants')).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getLoginUrl(clientKey: string): Observable<string> {
        return this.hungrigClient.get(this.url(`rocms/auth/url/${clientKey}`)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
