import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HungrigClient, BaseService, ParamsUtil } from '@ro-ngx/core';
import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeliveryManageModuleConfig } from '../modules/delivery-manage/delivery-manage-module-config';
import {
    ManageOrderAssignOrderParams,
    ManageOrderGetDriversParams,
} from '../modules/delivery-manage/models/request.models';

import { ManageOrderBase } from '../modules/delivery-manage/models/manage-order-base.models';
import { ManageOrderDirections } from '../modules/delivery-manage/models/manage-orders-directions.models';
import { ManageOrderStatus } from '../modules/delivery-manage/models/manage-order-status.models';
import { ManageOrderWithCart } from '../modules/delivery-manage/models/ManageOrderWithCart.models';

import { ManageOrderDistrict } from '../modules/delivery-manage/models/district.models';
import { ManageOrderDriver } from '../modules/delivery-manage/models/manage-order-driver.models';
import { subMinutes } from 'date-fns';

@Injectable()
export class DeliveryManageService extends BaseService {
    constructor(
        public readonly hungrigClient: HungrigClient,
        private readonly http: HttpClient,
        private config: DeliveryManageModuleConfig,
    ) {
        super(hungrigClient);
        this.apiUrl = `${hungrigClient.baseUrl}/`;
    }

    public getDistricts(params: any): Observable<ManageOrderDistrict[]> {
        return this.get<ManageOrderDistrict[]>('delivery/district', { params }).pipe(
            catchError(() => of([])),
        );
    }

    public getOrders(deliveryDistrictID: number, showAssigned: boolean = false): Observable<ManageOrderBase[]> {
        const pickupLine = (subMinutes(Date.now(), 60).getTime() / 1000).toFixed(0);
        const search = ParamsUtil.toHttpParams({
            pickupLine,
            deliveryDistrictID: [deliveryDistrictID],
            status: showAssigned
                ? [ManageOrderStatus.Unconfirmed, ManageOrderStatus.Confirmed]
                : [ManageOrderStatus.Unconfirmed],
        });

        return this.get<ManageOrderBase[]>('manage/delivery-orders', { search }).pipe(
            catchError(() => of([])),
        );
    }

    public getOrder(deliveryOrderID: number): Observable<ManageOrderWithCart> {
        return this.get<ManageOrderWithCart>(`manage/delivery-order/${deliveryOrderID}`).pipe(
            catchError(() => of(null)),
        );
    }

    public getDrivers(options: ManageOrderGetDriversParams): Observable<ManageOrderDriver[]> {
        const search = ParamsUtil.toHttpParams({ ...options });
        return this.get<ManageOrderDriver[]>('manage/delivery-drivers/', { search }).pipe(
            catchError(() => of([])),
        );
    }

    public getDriversInDistrict(deliveryDistrictID: number): Observable<ManageOrderDriver[]> {
        return this.get<ManageOrderDriver[]>(`manage/delivery-drivers/delivery-district/${deliveryDistrictID}`).pipe(
            catchError(() => of([])),
        );
    }

    public assignOrder(deliveryOrderID: number, params: ManageOrderAssignOrderParams): Observable<any> {
        return this.post(`manage/delivery-order/${deliveryOrderID}/assign`, params);
    }

    public getDirections(serializedCoords: string, withAlternatives: boolean): Observable<ManageOrderDirections> {
        const MAPBOX_DIRECTION_BASE_URL = 'https://api.mapbox.com/directions/v5';
        const params = new HttpParams()
            .set('access_token', this.config.accessToken)
            .set('geometries', 'geojson')
            .set('exclude', 'ferry')
            .set('alternatives', withAlternatives.toString());

        // @TODO cycling assumed!
        const url = `${MAPBOX_DIRECTION_BASE_URL}/mapbox/cycling/${serializedCoords}`;
        return this.http.get<ManageOrderDirections>(url, { params }).pipe(
            catchError(() => of(null)),
        );
    }
}
