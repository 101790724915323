import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from '@ro-ngx/core';
import { Credentials } from '../interfaces/credentials';
import { Observable } from 'rxjs';
import { AuthenticationToken } from '../interfaces/authentication-token';
import { tap } from 'rxjs/operators';
import { AuthenticationTokenStorage } from './authentication-token.storage';

@Injectable()
export class AuthenticationService extends BaseService {

    constructor(public hungrigClient: HungrigClient, protected authenticationTokenStorage: AuthenticationTokenStorage) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
        this.authenticationTokenStorage = authenticationTokenStorage;
    }

    public authenticate(credentials: Credentials): Observable<AuthenticationToken> {
        return this.post('/auth', credentials)
            .pipe(tap((token: AuthenticationToken) => this.setToken(token))) as Observable<AuthenticationToken>;
    }

    public refresh(refreshToken: string): Observable<AuthenticationToken> {
        return this.post('/auth/refresh', {
                refresh_token: refreshToken
            })
            .pipe(tap((token: AuthenticationToken) => this.setToken(token))) as Observable<AuthenticationToken>;
    }

    protected setToken(token: AuthenticationToken): void {
        this.authenticationTokenStorage.setAuthorizationToken(token);
    }
}
