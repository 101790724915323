import { Observable ,  Subject } from 'rxjs';
import { Type } from '@angular/core';
import { DialogContract } from '../dialog.contract';

export interface NotifyDialogOptions {
    title: string;
    body?: string;
    ok?: string;
}

export class NotifyDialog implements DialogContract<void> {
    public result$: Observable<void>;
    protected result: Subject<void>;

    public constructor(
        public readonly component: Type<any>,
        public options: NotifyDialogOptions
    ) {
        this.result = new Subject<void>();
        this.result$ = this.result.asObservable();
    }

    public complete(): void {
        this.result.next();
        this.result.complete();
    }
}
