import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { Role } from '../interfaces/role';

@Injectable()
export class RoleService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getUserConnectionRoles(userConnectionID: number): Observable<Role[]> {
        return this.get(`/user-connection/${userConnectionID}/roles`, {
            search: ParamsUtil.toHttpParams({})
        });
    }
}
