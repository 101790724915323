/*tslint:disable:max-line-length*/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TimedProgressBarModule } from '@ro-ngx/common';
import { RoNg2Module } from '@ro-ngx/core';
import {  ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule, } from 'ngx-bootstrap/buttons';
import { ENV } from '../../../../../apps/admin/src/app/enviroment';
import { DeliveryTimesHurrierDelayComponent } from './components/delivery-times-hurrier-delay/delivery-times-hurrier-delay.component';
import { DeliveryTimesRangeComponent } from './components/delivery-times-range/delivery-times-range.component';
import { DeliveryTimesTimeComponent } from './components/delivery-times-time/delivery-times-time.component';
import { DeliveryTimesPageComponent } from './containers/delivery-times-tiles/delivery-times-page.component';
import { DeliveryTimesComponent } from './containers/delivery-times/delivery-times.component';
import { ModalDeliveryTimesHurrierDelayEditComponent } from './modals/modal-delivery-times-hurrier-delay-edit/modal-delivery-times-hurrier-delay-edit.component';
import { ModalDeliveryTimesRangeEditComponent } from './modals/modal-delivery-times-range-edit/modal-delivery-times-range-edit.component';
import { ModalDeliveryTimesTimeEditComponent } from './modals/modal-delivery-times-time-edit/modal-delivery-times-time-edit.component';
/*tslint:enable:max-line-length*/

const isGlodny = ENV.APP_BRAND === 'Glodny';
const routes: Routes = [
    {
        path: '',
        component: DeliveryTimesPageComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: DeliveryTimesComponent,
                data: { show: isGlodny ? ['distance', 'time'] : ['hurrier', 'distance', 'time'] },
            },
            {
                path: 'time',
                component: DeliveryTimesComponent,
                data: { show: ['time'] },
            },
            {
                path: 'distance',
                component: DeliveryTimesComponent,
                data: { show: ['distance'] },
            },
            {
                path: 'hurrier',
                component: DeliveryTimesComponent,
                data: { show: ['hurrier'] },
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        RoNg2Module,
        ButtonsModule,
        ModalModule,
        TimedProgressBarModule,
    ],
    declarations: [
        DeliveryTimesComponent,
        DeliveryTimesPageComponent,
        DeliveryTimesRangeComponent,
        DeliveryTimesTimeComponent,
        DeliveryTimesHurrierDelayComponent,
        ModalDeliveryTimesTimeEditComponent,
        ModalDeliveryTimesRangeEditComponent,
        ModalDeliveryTimesHurrierDelayEditComponent,
    ],
    providers: [],
})
export class DeliveryTimesModule {
}
