import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProductCategoryService, ProductRestaurantCatalogService } from "./services";

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class ProductsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ProductsModule,
            providers: [
                ProductRestaurantCatalogService,
                ProductCategoryService
            ]
        };
    }
}
