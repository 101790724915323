import { Routes } from '@angular/router';
import { CouponGroupsComponent } from './groups';
import { CouponGroupComponent } from './groups/group';
import { GenerateCouponComponent } from './coupon-form/generate-coupon.component';
import { CouponsComponent } from './coupons';

export const routes: Routes = [
    {
        path: '',
        component: CouponGroupsComponent,
        data: {
            title: 'route.coupons_title'
        },
        children: [
            {
                path: 'groups/:groupId',
                component: CouponGroupComponent,
                children: [
                    {
                        path: 'generate',
                        component: GenerateCouponComponent
                    },
                    {
                        path: '',
                        component: CouponsComponent
                    }
                ]
            }
        ]
    }
];
