import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DialogQueueComponent, } from './dialog-queue.component';
import { DialogDirective } from './dialog.directive';
import { DialogService } from './dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotifyDialogComponent } from './notify';
import { ConfirmDialogComponent } from './confirm';
import { PromptDialogComponent } from './prompt';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        ReactiveFormsModule,
        TranslateModule.forChild()
    ],
    exports: [
        DialogQueueComponent,
        NotifyDialogComponent,
        ConfirmDialogComponent,
        PromptDialogComponent,
        DialogDirective
    ],
    declarations: [
        DialogDirective,
        DialogQueueComponent,
        NotifyDialogComponent,
        ConfirmDialogComponent,
        PromptDialogComponent
    ],
    bootstrap: [
        DialogQueueComponent,
        NotifyDialogComponent,
        ConfirmDialogComponent,
        PromptDialogComponent
    ]
})
export class DialogModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: DialogModule,
            providers: [
                DialogService
            ]
        };
    }

    //public constructor(@Optional() @SkipSelf() parentModule: DialogModule) {
    //    if (parentModule) {
    //        throw new Error('DialogModule is already loaded. Import it in the root module only.');
    //    }
    //}
}
