import { Injectable } from '@angular/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import { find, isNil, isArray } from 'lodash';
import { CollectionStorage } from '../../../http/resource/collection.storage';
import {startWith} from "rxjs/operators";

export type ItemCallback = (item: any) => any;
export type Items$Callback = (multipleSelect: MultipleSelect) => Observable<any>;

export interface MultipleSelectOptions {
    itemKeyId: string;
    itemKeyVisible: string;
    placeholder: string;
    inputFree: boolean;
    inputFilter: boolean;
    maxSelections: number;
    initialValue?: any;

    items$: Observable<any> | Items$Callback;

    onSelection: ItemCallback;
    onSelections: ItemCallback;
    onDeselection: ItemCallback;
}

export const DEFAULT_OPTIONS: MultipleSelectOptions = {
    itemKeyId: 'id',
    itemKeyVisible: 'title',
    placeholder: '',

    inputFree: false,
    inputFilter: true,
    maxSelections: 0,

    items$: null,

    onSelection: (item) => item,
    onSelections: (item) => item,
    onDeselection: (item) => item,
};

@Injectable()
export class MultipleSelect {

    /**
     * @type {*}
     */
    public options: MultipleSelectOptions;

    /**
     * @type {boolean}
     */
    public showDropdown: boolean = false;

    /**
     * @type {Observable}
     */
    public searchText$: Observable<string>;

    /**
     * @type {Observable}
     */
    public selectedItems$: Observable<any>;

    /**
     * @type {Observable}
     */
    public dropdownItems$: Observable<any>;

    /**
     * @type {Observable}
     */
    public items$: Observable<any>;

    /**
     * Is the multiple select loading?
     * This would be set manually.
     *
     * @type {boolean}
     */
    public loading: boolean = false;

    /**
     * @type {string}
     */
    protected searchText: Subject<string>;

    /**
     * @type {CollectionStorage}
     */
    protected selectedItems: CollectionStorage<any>;

    constructor(options: MultipleSelectOptions | any) {
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);

        this.searchText = new Subject();
        this.searchText$ = this.searchText.asObservable();

        this.selectedItems = new CollectionStorage();

        if (! isNil(options.initialValue)) {
            this.selectedItems.nextSubject(
                isArray(options.initialValue) ? options.initialValue : [options.initialValue]
            );
        }

        this.selectedItems$ = this.selectedItems.$;
        this.selectedItems$
            .subscribe((items) => this.options.onSelections(items));

        this.items$ = typeof this.options.items$ === 'function' ? this.options.items$(this) : this.options.items$;
        this.dropdownItems$ = this.dropdownItemsObservable();
    }

    public search(string: string): void {
        this.searchText.next(string);
    }

    public selectItem(item: any): void {
        if (this.canSelectMultiple()) {
            this.selectedItems.singleUnionSubject(item, this.options.itemKeyId);
        } else {
            this.selectedItems.nextSubject([item]);
        }
        this.options.onSelection(item);
        this.closeDropdown();

    }

    public removeItem(item: any): void {
        this.selectedItems.spliceSubject(item[this.options.itemKeyId], this.options.itemKeyId);
        this.options.onDeselection(item);
    }

    public reset(items: any[] = []): void {
        this.selectedItems.nextSubject(items);
        this.options.onSelections(items);
        this.closeDropdown();
    }

    public closeDropdown(): void {
        this.showDropdown = false;
    }

    public openDropdown(): void {
        this.showDropdown = true;
    }

    public setLoading(boolean: boolean): this {
        this.loading = boolean;

        return this;
    }

    public canSelectMultiple(): boolean {
        return this.options.maxSelections > 1 || this.options.maxSelections === 0;
    }

    protected dropdownItemsObservable(): Observable<any> {
        return combineLatest([
            this.items$,
            this.selectedItems$,
            this.searchText$.pipe(startWith(''))
        ], (items: any[], selectedItems: any, searchText: string) => {
            let availableItems = items.slice();

            if (this.options.inputFree) {
                availableItems.unshift({
                    [this.options.itemKeyId]: searchText,
                    [this.options.itemKeyVisible]: searchText
                });
            }

            if (this.options.inputFilter) {
                availableItems = availableItems.filter((item) => {
                    if (searchText.length < 1) {
                        return true;
                    }

                    if (find(selectedItems, { [this.options.itemKeyId]: item[this.options.itemKeyId] })) {
                        return false;
                    }

                    return item[this.options.itemKeyVisible].toLowerCase().includes(searchText.toLowerCase());
                });
            }

            return availableItems;
        });
    }
}
