import { FormGroup, AbstractControl } from '@angular/forms';

export class FormManager {

    constructor(protected formGroup: FormGroup) {
    }

    public error(key: string, error?: string, errorKey?: string | boolean): boolean {
        if (errorKey) {
            if (typeof errorKey === 'string') {
                return this.control(key).errors[error][errorKey];
            }

            return this.control(key).errors[error];
        }

        if (error) {
            return this.control(key).hasError(error);
        }

        return ! this.control(key).valid && ! this.control(key).pristine;
    }

    public toggle(key: string, trueValue: any = 1, falseValue: any = 0): void {
        this.formGroup.patchValue({
            [key]: this.value(key) == trueValue ? falseValue : trueValue
        });

        this.control(key).markAsDirty();
    }

    public value(controlName: string): any {
        if (! this.formGroup.contains(controlName)) {
            throw new Error('FormGroup not containing "' + controlName + '" control.');
        }

        return this.formGroup.value[controlName];
    }

    public control(controlName: string): AbstractControl {
        if (! this.formGroup.contains(controlName)) {
            throw new Error('FormGroup not containing "' + controlName + '" control.');
        }

        return this.formGroup.controls[controlName];
    }
}
