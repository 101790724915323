import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

const ORDER_SIZE_BADGE_NORMAL_CLASS = 'badge-secondary';
const ORDER_SIZE_BADGE_WARNING_CLASS = 'badge-warning';
const ORDER_SIZE_BADGE_DANGER_CLASS = 'badge-danger';

export const getOrderSize = (size: number): string => {
    /*
        Order badge:
        RED: > 8
        ORANGE: > 5
        DEFAULT: <= 5
    */

    const MIN_FOR_DANGER = 5;
    const MIN_FOR_WARNING = 8;

    if (size > MIN_FOR_DANGER) {
        return ORDER_SIZE_BADGE_DANGER_CLASS;
    } else if (size > MIN_FOR_WARNING) {
        return ORDER_SIZE_BADGE_WARNING_CLASS;
    } else {
        return ORDER_SIZE_BADGE_NORMAL_CLASS;
    }
};

/*
    Colors Bootstrap's .badge element in response to totalCourses. Requires .badge class to be on the element.
 */
@Directive({
    selector: '[orderSize]',
})
export class OrderSizeDirective {
    constructor(
        private readonly renderer: Renderer2,
        private readonly elRef: ElementRef,
    ) {
    }

    @Input('orderSize')
    public set order(size: number) {
        const el = this.elRef.nativeElement;

        // Remove possible previous class
        [ORDER_SIZE_BADGE_NORMAL_CLASS, ORDER_SIZE_BADGE_WARNING_CLASS, ORDER_SIZE_BADGE_DANGER_CLASS]
            .forEach((klass) => {
                this.renderer.removeClass(el, klass);
            });

        // Apply new class
        this.renderer.addClass(el, getOrderSize(size));
    }
}
