import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Http, HttpModule } from '@angular/http';
import { HungrigClient } from './http';
import { NotificationModule } from './components';
import { ButtonActiveComponent } from './components/button/button-active/button-active.component';
import { ButtonLoadableComponent } from './components/button/button-loadable/button-loadable.component';
import { LoadingLinearComponent } from './components/loading/loading-linear/loading-linear.component';
import { LoadingOverlayComponent } from './components/loading/loading-overlay/loading-overlay.component';
import { NotificationStorage } from './components/notification/notification.storage';
import { SelectModule } from './components/select/select.module';
import { PaginatorComponent } from './components/pagination/paginator.component';
import { Defaults, DefaultTranslations } from './i18n/default-translations';
import { MultipleSelectComponent } from './components/select/multiple-select/multiple-select.component';

export * from './currency';
export * from './env';
export * from './locale';
export * from './http';
export * from './components';
export * from './i18n';

export interface RoNg2ModuleConfig {
    baseUrl: string;
    defaultTranslations?: Defaults;
}

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        NotificationModule,
        SelectModule
    ],
    declarations: [
        ButtonActiveComponent,
        ButtonLoadableComponent,
        LoadingLinearComponent,
        LoadingOverlayComponent,
        MultipleSelectComponent,
        PaginatorComponent
    ],
    exports: [
        NotificationModule,
        SelectModule,
        ButtonActiveComponent,
        ButtonLoadableComponent,
        LoadingLinearComponent,
        LoadingOverlayComponent,
        MultipleSelectComponent,
        PaginatorComponent
    ]
})
export class RoNg2Module {
    public static forRoot(config: RoNg2ModuleConfig): ModuleWithProviders {
        return {
            ngModule: RoNg2Module,
            providers: [
                {
                    provide: HungrigClient,
                    useFactory: (http: Http) => {
                        const hungrigClient = new HungrigClient(http);
                        hungrigClient.baseUrl = config.baseUrl;

                        return hungrigClient;
                    },
                    deps: [Http]
                },
                {
                    provide: DefaultTranslations,
                    useFactory: () => {
                        const defaults = config.defaultTranslations ? config.defaultTranslations : {};

                        return new DefaultTranslations(defaults);
                    }
                },
                NotificationStorage
            ]
        };
    }
}
