import { ModuleWithProviders, NgModule } from '@angular/core';
import { PushNotificationConfig } from "./push-notification-config";

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class PushNotificationConfigModule {
    public static forRoot(config: PushNotificationConfig): ModuleWithProviders {
        return {
            ngModule: PushNotificationConfigModule,
            providers: [
                {
                    provide: PushNotificationConfig,
                    useValue: config
                }
            ]
        };
    }
}
