import { Pipe, PipeTransform } from '@angular/core';
import { isOrderPositionable } from '../helpers';
import { ManageOrderDriverDelivery } from '../models/manage-order-driver-delivery.models';
import { ManageOrderBase } from '../models/manage-order-base.models';

/**
 * The pipe returns distance value if order is positionable or null otherwise.
 * Null value can then be passed further to asKilometers pipe.
 */
@Pipe({
    name: 'orderDistance',
    pure: false,
})
export class OrderDistancePipe implements PipeTransform {
    public transform(order: ManageOrderBase | ManageOrderDriverDelivery): number | null {
        if (!order || !isOrderPositionable(order)) {
            return null;
        }
        return order.distanceBike; // @TODO don't assume transportMode
    }
}
