import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DebtorOption, getDebtorOptions, SourceOption, sourceOptions } from '../../common/options';
import { CompensationCalculator, CompensationDebit } from '../../interfaces';
import { DeliveryType, Order } from '@ro-ngx/orders';
import { MultipleSelect } from '@ro-ngx/core';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { DeliveryCompany, DeliveryCompanyService } from '@ro-ngx/delivery';
import { Debtor, Source } from '../../enums';
import { getDebtorID } from '../../common/debtor';
import { CompensationsConfig } from '../../compensations-config';

@Component({
    selector: 'compensation-debit-form',
    template: require('./compensation-debit-form.component.html')
})
export class CompensationDebitFormComponent implements OnInit {
    @Input()
    public order: Order;

    @Input()
    public debit: CompensationDebit;

    @Input()
    public calculators: CompensationCalculator[];

    public form: FormGroup;

    public debtorOptions: DebtorOption[];

    public sourceOptions: SourceOption[];

    public deliveryCompanySelect: MultipleSelect;

    public Debtor: typeof Debtor = Debtor;

    constructor(
        protected formBuilder: FormBuilder,
        protected deliveryCompanyService: DeliveryCompanyService,
        protected changeDetectorRef: ChangeDetectorRef,
        public config: CompensationsConfig
    ) {
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            reason: [null, Validators.required],
            summaryReason: [null, Validators.required],
            debtor: [null, Validators.required],
            source: [null, Validators.required],
            taxRate: null,
            deliveryCompanyID: null,
            deliveryDriverID: null,
            clientKey: null,
            debtorID: null,
            amount: [null, Validators.required]
        }, {
            validator: (group: FormGroup) => {
                if (group.get('source').value === Source.CUSTOM) {
                    return Validators.required(group.get('taxRate'));
                }

                return null;
            }
        });

        this.debtorOptions = getDebtorOptions(this.order);
        this.sourceOptions = sourceOptions;

        if (this.order.deliveryType !== DeliveryType.Pickup) {
            this.deliveryCompanySelect = new MultipleSelect({
                onSelection: (deliveryCompany: DeliveryCompany) => {
                    this.setDebtor(Debtor.DELIVERY_COMPANY, deliveryCompany.deliveryCompanyID);
                },
                initialValue: this.order.deliveryorder.deliverycompany,
                items$: (multipleSelect) => {
                    const debounceTimeMs: number = 600;
                    return multipleSelect.searchText$
                        .pipe(
                            tap(() => multipleSelect.setLoading(true)),
                            debounceTime(debounceTimeMs),
                            switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                            tap(() => {
                                multipleSelect.setLoading(false);
                                this.changeDetectorRef.markForCheck();
                            })
                        );
                },
                itemKeyVisible: 'companyName',
                itemKeyId: 'deliveryCompanyID',
                maxSelections: 1
            });
        }

        this.form.get('debtor').valueChanges.subscribe((debtor) => this.onDebtorChange(debtor));

        if (this.debit) {
            this.form.patchValue(this.debit);
        }
    }

    public reset(): void {
        this.form.reset();
    }

    public validate(): void {
        this.form.markAsTouched();
    }

    public isValid(): boolean {
        return this.form.valid;
    }

    public getValue(): Pick<CompensationDebit, 'reason' | 'debtor' | 'amount'> {
        return this.form.value;
    }

    public setReason(reason: string): void {
        this.form.get('reason').setValue(reason);
    }

    protected onDebtorChange(debtor: Debtor): void {
        if (debtor === Debtor.DELIVERY_COMPANY) {
            let deliveryCompany;

            if (typeof this.debit !== 'undefined' && typeof this.debit.deliveryCompany !== 'undefined') {
                deliveryCompany = this.debit.deliveryCompany;
            } else {
                deliveryCompany = this.order.deliveryorder.deliverycompany;
            }

            this.deliveryCompanySelect.selectItem(deliveryCompany);
        } else if (debtor === Debtor.DELIVERY_DRIVER) {
            if (typeof this.debit !== 'undefined' && typeof this.debit.deliveryDriver !== 'undefined') {
                this.setDebtor(debtor, this.debit.deliveryDriver.deliveryDriverID);
            } else {
                this.setDebtor(debtor, getDebtorID(this.order, debtor));
            }
        } else if (debtor !== null) {
            this.setDebtor(debtor, getDebtorID(this.order, debtor));
        }
    }

    protected setDebtor(debtor: Debtor, debtorID: number|string): void {
        const patch = {
            debtorID,
            deliveryCompanyID: null,
            deliveryDriverID: null,
            clientKey: null
        };

        const debtorKey = this.getDebtorKey(debtor);
        if (debtorKey !== null) {
            patch[debtorKey] = debtorID;
        }

        this.form.patchValue(patch);
    }

    protected getDebtorKey(debtor: Debtor): string {
        switch (debtor) {
            case Debtor.DELIVERY_COMPANY:
                return 'deliveryCompanyID';
            case Debtor.DELIVERY_DRIVER:
                return 'deliveryDriverID';
            case Debtor.RESTAURANT:
                return 'clientKey';
            default:
                return null;
        }
    }
}
