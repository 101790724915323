import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { Permission } from '../interfaces/permission';

@Injectable()
export class PermissionService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getUserConnectionPermissions(userConnectionID: number): Observable<Permission[]> {
        return this.get(`/user-connection/${userConnectionID}/permissions`, {
            search: ParamsUtil.toHttpParams({})
        });
    }
}
