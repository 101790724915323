import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DialogContract } from './dialog.contract';
import { ConfirmDialogComponent, ConfirmDialog, ConfirmDialogOptions} from './confirm';
import { NotifyDialogComponent, NotifyDialog, NotifyDialogOptions } from './notify';
import { PromptDialogComponent, PromptDialog, PromptDialogOptions } from './prompt';

@Injectable()
export class DialogService {
    public dialog$: Observable<DialogContract<any>>;
    protected dialog: Subject<DialogContract<any>>;

    constructor() {
        this.dialog = new Subject();
        this.dialog$ = this.dialog.asObservable();
    }

    public confirm(options: ConfirmDialogOptions): Observable<boolean> {
        const dialog = new ConfirmDialog(ConfirmDialogComponent, options);
        this.dialog.next(dialog);
        return dialog.result$;
    }

    public notify(options: NotifyDialogOptions): Observable<void> {
        const dialog = new NotifyDialog(NotifyDialogComponent, options);
        this.dialog.next(dialog);
        return dialog.result$;
    }

    public prompt(options: PromptDialogOptions): Observable<string> {
        const dialog = new PromptDialog(PromptDialogComponent, options);
        this.dialog.next(dialog);
        return dialog.result$;
    }
}
