



export enum ManageOrderTransportMode {
    Car = 0,
    Bike = 1,
}

export interface ManageOrderRouteProperties {
    deliveryOrderID: number;
    deliveryDriverID: number;
    restaurantName: string;
    severity: string;
    type: string
}
