import { ServerResponseBody } from './server-response-body';

export class Response<T> {

    public data: T;

    public meta: any | any[] = {};

    public error: boolean = false;

    public errors: any[] = null;

    // tslint:disable
    public errorCode: number = 0;

    // tslint:disable
    public errorMessage: string = '';

    constructor(responseBody: ServerResponseBody) {
        this.assertServerResponse(responseBody);

        this.data = responseBody.data;
        this.meta = responseBody.meta;
        this.error = responseBody.error;
        this.errors = responseBody.errors ? responseBody.errors : null;
        this.errorCode = responseBody.error_code;
        this.errorMessage = responseBody.error_message;
    }

    /**
     * To data.
     *
     * @returns {*}
     */
    public toData(): T {
        return this.data;
    }

    /**
     * Gracefully assert server response.
     *
     * @param responseBody
     */
    public assertServerResponse(responseBody: ServerResponseBody) {
        ['data', 'meta', 'error', 'errors', 'error_code', 'error_message']
            .forEach((key) => {
                if ( ! responseBody.hasOwnProperty(key)) {
                    console.warn(`${key} is missing in server response.`);
                }
            });
    }

    /**
     * Meta key getter.
     *
     * @param key
     * @returns {*}
     */
    public getMeta(key: string): any | any[] {
        if (!this.meta.hasOwnProperty(key)) {
            console.warn(`Trying to get metaKey ${key} from missing. Missing. Returning null gracefully.`);

            return null;
        }

        return this.meta[key];
    }
}
