import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyModule, defaultTranslate, RoNg2Module } from '@ro-ngx/core';
import { OrderTabCartComponent } from './order-tab-cart.component';
import { OrderTabCartConfig } from './order-tab-cart-config';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(defaultTranslate),
        RoNg2Module,
        CurrencyModule
    ],
    exports: [
        OrderTabCartComponent
    ],
    declarations: [
        OrderTabCartComponent
    ],
    providers: [],
})
export class OrderTabCartModule {
    public static forRoot(config: OrderTabCartConfig): ModuleWithProviders {
        return {
            ngModule: OrderTabCartModule,
            providers: [
                {
                    provide: OrderTabCartConfig,
                    useValue: config
                }
            ]
        };
    }
}
