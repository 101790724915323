import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MultipleSelect } from '@ro-ngx/core';

import { GeoValidators } from '@ro-ngx/mapbox-support';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { DeliveryFleet } from '../../../../../../apps/admin/src/app/http/delivery/district/delivery-fleet';
import { DeliveryCompany } from '../../../interfaces';
import { DeliveryCompanyService } from '../../../services';
import { DistrictSettings } from '../../../interfaces/delivery-district';

export interface EditDistrictFormState {
    deliveryDistrictID: string;
    dDistrictSettingsID: number;
    companies: DeliveryCompany[];
    name: string;
    latitude: number;
    longitude: number;
    settings?: Partial<DistrictSettings>;
}

@Component({
    selector: 'district-form',
    template: require('./district-form.component.html'),
    styles: [require('./district-form.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrictFormComponent implements OnChanges {
    @Input() public companies: DeliveryCompany[];
    @Input() public fleets: DeliveryFleet[];
    @Input() public isUpdateMode = false;
    @Output() public ok: EventEmitter<void> = new EventEmitter();
    @ViewChild('nameFld') public nameFld: ElementRef;

    public form: FormGroup;
    public multipleSelect: MultipleSelect;
    private blankState: EditDistrictFormState = {
        deliveryDistrictID: null,
        dDistrictSettingsID: null,
        name: null,
        latitude: null,
        longitude: null,
        companies: [],
        settings: {
            deliveryFleet: '',
        }
    };

    constructor(
        private fb: FormBuilder,
        private cdRef: ChangeDetectorRef,
        private deliveryCompanyService: DeliveryCompanyService,
        private translateService: TranslateService,
    ) {
        this.form = fb.group({
            deliveryDistrictID: null,
            dDistrictSettingsID: null,
            name: ['', Validators.required],
            latitude: ['', [Validators.required, GeoValidators.latitude]],
            longitude: ['', [Validators.required, GeoValidators.longitude]],
            companies: [[]],
            settings: fb.group({
                deliveryFleet: ['', Validators.required],
                customFleetMinutesDelay: [0, Validators.required],
            })
        });

        this.multipleSelect = new MultipleSelect({
            items$: (multipleSelect) => {
                const debounceTimeMs: number = 600;
                return multipleSelect.searchText$
                    .pipe(
                        tap(() => multipleSelect.setLoading(true)),
                        debounceTime(debounceTimeMs),
                        switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                        tap(() => {
                            multipleSelect.setLoading(false);
                            this.cdRef.markForCheck();
                        }),
                    );
            },


            itemKeyId: 'deliveryCompanyID',
            itemKeyVisible: 'companyName',
            placeholder: this.translateService.instant('delivery.districts.companies_placeholder'),

            onSelections: (deliveryCompanies) => {
                const formControl = this.form.get('companies');
                formControl.setValue(deliveryCompanies);
                formControl.markAsDirty();
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isUpdateMode){
            this.enableSettingsGroup(changes.isUpdateMode.currentValue);
        }
    }

    private enableSettingsGroup(isUpdateMode){
        const settings = this.form.get('settings');
        isUpdateMode ? settings.enable() : settings.disable();
    }

    public reset(formState?: EditDistrictFormState, districtSettings?: DistrictSettings): void {

        this.form.reset({ ...formState, settings: {...districtSettings} } || this.blankState);

        this.multipleSelect.reset();
        if (formState && formState.companies) {
            (formState.companies || []).forEach((dc) => this.multipleSelect.selectItem(dc));
        }

        this.cdRef.markForCheck();
    }

    public submit(): void {
        this.ok.emit();
    }

    public autofocus(): void {
        this.nameFld.nativeElement.focus();
    }
}
