import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asKilometers',
})
export class AsKilometersPipe implements PipeTransform {
    public transform(value: number): string {
        return value == null
            ? '???'
            : (value / 1000).toFixed(1) + 'km';
    }
}
