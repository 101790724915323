import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDeliveryTimesItem } from '../../../../interfaces/delivery-times';

@Component({
    selector: 'delivery-times-range',
    template: require('./delivery-times-range.component.html'),
    styles: [require('./delivery-times-range.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryTimesRangeComponent {
    @Input()
    public item: IDeliveryTimesItem;

    @Input()
    public translations: {[key: string]: string} = {};

    get alertClass(): string {
        const ALERT_SUCCESS_THRESHOLD = 6000;
        const ALERT_WARNING_THRESHOLD = 5000;
        const ALERT_SUPER_WARNING_THRESHOLD = 4000;

        if (this.item.closedMeters >= ALERT_SUCCESS_THRESHOLD || this.item.closed === 0) {
            return 'alert-success';
        } else if (this.item.closedMeters >= ALERT_WARNING_THRESHOLD) {
            return 'alert-warning';
        } else if (this.item.closedMeters >= ALERT_SUPER_WARNING_THRESHOLD) {
            return 'alert-super-warning';
        } else {
            return 'alert-danger';
        }
    }

    get range(): string {
        if (this.item.closed === 0) {
            return this.translations.open;
        } else {
            return this.item.closedMeters + ' m';
        }
    }
}
