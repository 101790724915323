import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { RestaurantCheckout } from '../interfaces';
import { Observable } from 'rxjs';

export interface GetCheckoutsOptions {
    clientKey: string;
    with?: string[];
}

@Injectable()
export class RestaurantCheckoutService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }

    public findByMerchant(clientKey: string): Observable<RestaurantCheckout> {
        return this.get(`/restaurant/${clientKey}`);
    }

    public getCheckouts(options: GetCheckoutsOptions): Observable<RestaurantCheckout[]> {
        return this.get(`/restaurant-checkout`, {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public findCheckout(restaurantCheckoutID: number): Observable<RestaurantCheckout> {
        return this.get(`/restaurant-checkout/${restaurantCheckoutID}`);
    }

    public updateCheckout(
        restaurantCheckoutID: number, restaurantCheckout: Partial<RestaurantCheckout>): Observable<RestaurantCheckout> {
        return this.put(`/restaurant-checkout/${restaurantCheckoutID}`, restaurantCheckout);
    }
}
