import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompensationCalculatorDebitResult } from '../../../../interfaces';
import { Debtor } from '../../../../enums';
import { Observable } from 'rxjs';
import { CompensationUtilService } from '../../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompensationCalculatorState } from '../../compensation-calculator.state';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../base.component';

export interface ConfirmCompensationDebitEntry {
    debit: CompensationCalculatorDebitResult;
    form: FormGroup;
}

@Component({
    selector: 'confirm-compensation-debits',
    template: require('./confirm-compensation-debits.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmCompensationDebitsComponent extends BaseComponent implements OnChanges, OnInit {
    @Input()
    public debits: CompensationCalculatorDebitResult[];

    public entries: ConfirmCompensationDebitEntry[];

    public advancedView$: Observable<boolean>;

    constructor(
        protected compensationCalculatorState: CompensationCalculatorState,
        protected compensationUtilService: CompensationUtilService,
        protected formBuilder: FormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.advancedView$ = this.compensationCalculatorState.config$
            .pipe(
                map((config) => config.advancedView),
                takeUntil(this.ngUnsubscribe)
            );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['debits']) {
            this.initEntries();
        }
    }

    public isValid(): boolean {
        return ! this.entries.some((entry) => entry.form.invalid);
    }

    public getDebtorTitle(debtor: Debtor): Observable<string> {
        return this.compensationUtilService.getDebtorTitle(debtor);
    }

    public getDebits(): CompensationCalculatorDebitResult[] {
        return this.entries.map((entry) => {
            const formValue = entry.form.value;
            const amount = (formValue.isNegative ? -1 : 1) * formValue.amount;

            return {
                ...entry.debit,
                amount
            };
        });
    }

    protected initEntries(): void {
        this.entries = this.debits.map((debit) => {
            const form = this.formBuilder.group({
                amount: [Math.abs(debit.amount), Validators.required],
                isNegative: debit.amount < 0
            });

            return {
                debit,
                form
            };
        });
    }
}
