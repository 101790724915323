import { Notification } from './notification';
import { NotificationStorage } from './notification.storage';
import { NotificationType } from './notification-type';

export class NotificationInstance {

    public notification: Notification;

    public notificationInstanceID: string;

    protected notificationStorage: NotificationStorage;

    constructor(
        notificationStorage: NotificationStorage,
        notification: Notification
    ) {
        this.notificationStorage = notificationStorage;
        this.notification = notification;

        /**
         * @link https://gist.github.com/gordonbrander/2230317
         */
        const base = 36;
        const from = 2;
        const length = 9;
        this.notificationInstanceID = Math.random().toString(base).substr(from, length);
    }

    public info(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.notificationChange(NotificationType.Info, title, body, duration);
    }

    public success(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.notificationChange(NotificationType.Success, title, body, duration);
    }

    public warning(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.notificationChange(NotificationType.Warning, title, body, duration);
    }

    public danger(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.notificationChange(NotificationType.Danger, title, body, duration);
    }

    public dismiss(): NotificationStorage {
        return this.notificationStorage.dismiss(this);
    }

    public dismissAfter(afterMs?: number): this {
        if (afterMs) {
            setTimeout(() => {
                this.dismiss();
            }, afterMs);
        }

        return this;
    }

    protected notificationChange(notificationType: NotificationType, title: string = '',
                                 body: string = '', duration?: number): NotificationInstance {
        this.notification.type = notificationType;
        this.notification.title = title;
        this.notification.body = body;
        this.dismissAfter(duration);

        return this;
    }
}
