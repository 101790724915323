
import {takeUntil} from 'rxjs/operators';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocaleService } from '../locale.service';
import { Subject } from 'rxjs';

import { Language } from '../language';

@Directive({ selector: '[ifLanguage]' })
export class IfLanguageDirective implements OnInit, OnDestroy {
    @Input('ifLanguage')
    public language: Language;

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        protected viewContainer: ViewContainerRef,
        protected templateRef: TemplateRef<any>,
        protected localeService: LocaleService
    ) {
    }

    public ngOnInit(): void {
        this.localeService.language$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((language) => this.toggleElement(language));
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected toggleElement(language: Language): void {
        if (language === this.language) {
            this.display();
        } else {
            this.clear();
        }
    }

    protected display(): void {
        this.viewContainer.createEmbeddedView(
            this.templateRef
        );
    }

    protected clear(): void {
        this.viewContainer.clear();
    }
}
