
import {of as observableOf,  BehaviorSubject, Observable ,  Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CampaignGroup, Coupon, DiscountType } from '../../../interfaces';
import { CouponTemplate, CouponTemplateService } from '../template';
import { CampaignGroupService } from '../../../services/campaign-group.service';
import { TimeOption } from '@ro-ngx/common';
import { format } from 'date-fns';
import { CurrencySupport } from '@ro-ngx/core';
import { omit } from 'lodash';
import { CouponManagerConfig } from '../coupon-manager-config';
import {map, startWith, takeUntil} from "rxjs/operators";

export interface DayOption {
    key: string;
    text: string;
}

export interface DiscountTypeOption {
    id: number;
    text: string;
}

@Component({
    selector: 'coupon-form',
    template: require('./coupon-form.component.html'),
})
export class CouponFormComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public templateControl: FormControl;
    public days: Observable<DayOption[]>;
    public initialDays: DayOption[];
    public discountTypes: DiscountTypeOption[];
    public discountAmountSuffix: string;
    public midnightTimeOption: TimeOption;
    public currencySymbol: string;
    public campaignGroups$: Observable<CampaignGroup[]>;
    public saving$: BehaviorSubject<boolean>;
    public loading$: BehaviorSubject<boolean>;
    public deleting$: BehaviorSubject<boolean>;
    public DiscountType: typeof DiscountType = DiscountType;
    public isNew: boolean = true;
    public templates: CouponTemplate[];
    private ngUnsubscribe: Subject<void> = new Subject();

    get discountAmountLabel(): string {
        return this.form.get('discountType').value === DiscountType.Delivery
            ? this.translateService.instant('coupon.discount_amount_label_max_delivery')
            : this.translateService.instant('coupon.discount_amount_label');
    }

    constructor(
        protected campaignGroupService: CampaignGroupService,
        protected templateService: CouponTemplateService,
        protected translateService: TranslateService,
        protected currencySupport: CurrencySupport,
        protected formBuilder: FormBuilder,
        public config: CouponManagerConfig
    ) {
    }

    // tslint:disable no-empty
    public save(): void {}

    public remove(): void {}
    // tslint:enable no-empty

    public ngOnInit(): void {
        this.saving$ = new BehaviorSubject<boolean>(false);
        this.loading$ = new BehaviorSubject<boolean>(false);
        this.deleting$ = new BehaviorSubject<boolean>(false);

        this.initForm();
        this.initOptions();
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
    }

    public setCoupon(coupon: Coupon): void {
        const data: any = Object.assign({}, coupon);

        if (coupon.expiresLine === -1) {
            data.expiresLine = null;
            data.enableExpiresLine = false;
        } else {
            const msPerSecond = 1000;
            data.expiresLine = format(new Date(coupon.expiresLine * msPerSecond), 'YYYY-MM-DD');
            data.enableExpiresLine = true;
        }

        if (coupon.reqAmount === -1) {
            data.reqAmount = null;
            data.enableReqAmount = false;
        } else {
            data.enableReqAmount = true;
        }

        if (coupon.remainingQuantity === -1) {
            data.remainingQuantity = null;
            data.enableRemainingQuantity = false;
        } else {
            data.enableRemainingQuantity = true;
        }

        this.initialDays = this.generateDays().filter((day) => data[day.key]);
        this.form.reset(data);
    }

    public setDay(key: string, selected: boolean): void {
        this.form.get(key).setValue(selected);
    }

    protected initForm(): void {
        this.form = this.formBuilder.group({
            clientKey: null,
            title: null,
            code: null,
            discountType: DiscountType.Percentage,
            discountAmount: [null, Validators.required],
            expiresLine: null,
            discountAuto: false,
            remainingQuantity: 1,
            reqAmount: 0,
            validPickup: true,
            validDeliveryRun: true,
            validFood: true,
            validCatering: true,
            validEvent: true,
            validHungrig: true,
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: true,
            sun: true,
            validTimeFrom: '00:00:00',
            validTimeTo: '00:00:00',
            campaignGroupID: 0,
            numberOfCodes: 1,
            valid: true,
            enableExpiresLine: false,
            enableReqAmount: false,
            enableRemainingQuantity: true,
        });

        this.form.setValue(this.templateService.getTemplate(CouponTemplateService.DEFAULT_TEMPLATE_ID).form);
        this.templateControl = new FormControl(CouponTemplateService.DEFAULT_TEMPLATE_ID);

        this.templateControl.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((templateId) => {
                const template = this.templateService.getTemplate(templateId);
                this.form.reset(template.form);
            });

        this.currencySymbol = this.currencySupport.symbol();

        const discountTypeControl = this.form.get('discountType');
        discountTypeControl.valueChanges.pipe(
            startWith(discountTypeControl.value),
            map((type): string => {
                const discountAmount: AbstractControl = this.form.get('discountAmount');
                switch (type) {
                    case DiscountType.Amount:
                        discountAmount.reset();
                        return this.currencySymbol;
                    case DiscountType.Percentage:
                        discountAmount.reset();
                        return '%';
                    case DiscountType.Delivery:
                    default:
                        discountAmount.setValue(0);
                        return this.currencySymbol;
                }
            }),
            takeUntil(this.ngUnsubscribe)

        )

            .subscribe((suffix) => this.discountAmountSuffix = suffix);

        this.addControlDisableTrigger('reqAmount', 'enableReqAmount');
        this.addControlDisableTrigger('expiresLine', 'enableExpiresLine');
        this.addControlDisableTrigger('remainingQuantity', 'enableRemainingQuantity');
    }

    protected addControlDisableTrigger(controlName: string, triggerControlName: string): void {
        const triggerControl = this.form.get(triggerControlName);
        const control = this.form.get(controlName);
        triggerControl.valueChanges.pipe(
            startWith(triggerControl.value),
            takeUntil(this.ngUnsubscribe)
        )
            .subscribe((enabled) => enabled ? control.enable() : control.disable());
    }

    protected getValue(): Partial<Coupon> {
        const value = this.form.getRawValue();

        if (!value.enableReqAmount) {
            value.reqAmount = -1;
        }

        if (!value.enableExpiresLine) {
            value.expiresLine = -1;
        }

        if (!value.enableRemainingQuantity) {
            value.remainingQuantity = -1;
        }

        return omit(value, [
            'enableReqAmount', 'enableExpiresLine', 'enableRemainingQuantity'
        ]);
    }

    protected initOptions(): void {
        this.templates = this.templateService.getTemplates();

        const days: DayOption[] = this.generateDays();
        this.initialDays = days.filter((day) => this.form.get(day.key));
        this.days = observableOf(days);

        this.discountTypes = this.generateDiscountTypes();

        if (this.config.asAdmin) {
            this.campaignGroups$ = this.campaignGroupService.getGroups();
        }

        this.midnightTimeOption = {
            value: '00:00:00',
            text: this.translateService.instant('coupon.time_always_option'),
        };
    }

    protected generateDays(): DayOption[] {
        return [
            {
                key: 'mon',
                text: this.translateService.instant('date.days.monday_abbr'),
            },
            {
                key: 'tue',
                text: this.translateService.instant('date.days.tuesday_abbr'),
            },
            {
                key: 'wed',
                text: this.translateService.instant('date.days.wednesday_abbr'),
            },
            {
                key: 'thu',
                text: this.translateService.instant('date.days.thursday_abbr'),
            },
            {
                key: 'fri',
                text: this.translateService.instant('date.days.friday_abbr'),
            },
            {
                key: 'sat',
                text: this.translateService.instant('date.days.saturday_abbr'),
            },
            {
                key: 'sun',
                text: this.translateService.instant('date.days.sunday_abbr'),
            }
        ];
    }

    protected generateDiscountTypes(): DiscountTypeOption[] {
        return [
            {
                id: DiscountType.Amount,
                text: this.translateService.instant('coupon.discount_type_amount_label'),
            },
            {
                id: DiscountType.Percentage,
                text: this.translateService.instant('coupon.discount_type_percentage_label'),
            },
            {
                id: DiscountType.Delivery,
                text: this.translateService.instant('coupon.discount_type_delivery_label'),
            }
        ];
    }
}
