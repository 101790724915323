import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { times } from 'lodash';
import { format as dateFormat } from "date-fns";

export interface TimeOption {
    value: string;
    text: string;
}

@Component({
    selector: 'time-select',
    template: `
        <select [ngModel]="value" (ngModelChange)="onChange($event)" [disabled]="disabled"
                (blur)="onBlur()" class="form-control">
            <option *ngFor="let option of options;" [ngValue]="option.value">{{ option.text }}</option>
        </select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        // tslint:disable-next-line no-use-before-declare
        useExisting: forwardRef(() => TimeSelectComponent),
        multi: true
    }]
})
export class TimeSelectComponent implements OnInit, ControlValueAccessor {
    @Input() public minuteStep: number = 15;
    @Input() public overrideOptions: TimeOption | TimeOption[];
    public disabled: boolean;
    public value: string;
    public options: TimeOption[];
    protected onChangeCallback: (_: any) => void;
    protected onTouchedCallback: () => void;

    public ngOnInit(): void {
        this.options = this.generateOptions();
    }

    public onChange(value: string): void {
        if (this.value !== value) {
            this.value = value;
            if (this.onChangeCallback) {
                this.onChangeCallback(value);
            }
        }
    }

    public onBlur(): void {
        if (this.onTouchedCallback) {
            this.onTouchedCallback();
        }
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    protected generateOptions(): TimeOption[] {
        const hoursPerDay: number = 24;
        const minutesPerHour: number = 60;
        return times(hoursPerDay * minutesPerHour / this.minuteStep, (i: number) => {
            const hour: number = Math.floor(i * this.minuteStep / minutesPerHour);
            const minute: number = Math.floor(i * this.minuteStep % minutesPerHour);
            const option: TimeOption = {
                value: this.format(hour, minute, 0, 'HH:mm:ss'),
                text: this.format(hour, minute, 0, 'HH:mm')
            };
            const override: TimeOption = this.getOverrideOption(option.value);
            return override ? override : option;
        });
    }

    protected getOverrideOption(value: string): TimeOption {
        if (this.overrideOptions === undefined) {
            return undefined;
        } else if (! Array.isArray(this.overrideOptions) && this.overrideOptions.value === value) {
            return this.overrideOptions;
        } else if (Array.isArray(this.overrideOptions)) {
            return this.overrideOptions.find((option) => option.value === value);
        }
    }

    protected format(hour: number, minute: number, second: number, format: string): string {
        const date = new Date();
        date.setHours(hour);
        date.setMinutes(minute);
        date.setSeconds(second);
        return dateFormat(date, format);
    }
}
