import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySupport } from './currency-support';

@Pipe({
    name: 'currencyText'
})

export class CurrencyTextPipe implements PipeTransform {

    constructor(protected currencySupport: CurrencySupport) {}

    public transform(value: any, args: any): any {

        return this.currencySupport.text(value, args['currency'], args['decimalPoints']);
    }
}
