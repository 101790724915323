import {
    COLOR_ROUTE_ALT,
    COLOR_ROUTE_SELECTED_ORDER,
    LAYER_ALTERNATIVE_ROUTE,
    LAYER_ROUTES,
    SOURCE_DIRECTIONS,
} from './constants';

export const selectedOrderRouteStyles = [
    // Selected order routes (primary and alternate)
    {
        'id': LAYER_ALTERNATIVE_ROUTE,
        'type': 'line',
        'source': SOURCE_DIRECTIONS,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': COLOR_ROUTE_ALT,
            'line-width': 4,
            'line-opacity': 0.5,
        },
        'filter': [
            'all',
            ['in', '$type', 'LineString'],
            ['==', 'type', 'alternative'],
        ],
    }, {
        'id': LAYER_ROUTES,
        'type': 'line',
        'source': SOURCE_DIRECTIONS,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': COLOR_ROUTE_SELECTED_ORDER,
            'line-width': 5,
            'line-opacity': 0.9,
        },
        'filter': [
            'all',
            ['in', '$type', 'LineString'],
            ['==', 'type', 'primary'],
        ],
    }, {
        'id': 'directions-origin-point-circle-border',
        'type': 'circle',
        'source': SOURCE_DIRECTIONS,
        'paint': {
            'circle-radius': 12,
            'circle-color': COLOR_ROUTE_SELECTED_ORDER,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'origin'],
        ],
    }, {
        'id': 'directions-origin-point-circle',
        'type': 'circle',
        'source': SOURCE_DIRECTIONS,
        'paint': {
            'circle-radius': 10,
            'circle-color': COLOR_ROUTE_SELECTED_ORDER,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'origin'],
        ],
    }, {
        'id': 'directions-origin-point',
        'type': 'symbol',
        'source': SOURCE_DIRECTIONS,
        'layout': {
            'icon-image': 'origin',
            'icon-size': 1,
            'text-field': ['format',
                ['upcase', ['get', 'drawPickupTime']], { 'font-scale': .7 },
            ],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.2],
            'text-anchor': 'center',
        },
        'paint': {
            'text-color': 'white',
            'text-halo-color': COLOR_ROUTE_SELECTED_ORDER,
            'text-halo-width': 6,
            'text-halo-blur': 2,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'origin'],
        ],
    }, {
        'id': 'directions-destination-point-circle-border',
        'type': 'circle',
        'source': SOURCE_DIRECTIONS,
        'paint': {
            'circle-radius': 12,
            'circle-color': COLOR_ROUTE_SELECTED_ORDER,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'destination'],
        ],
    }, {
        'id': 'directions-destination-point-circle',
        'type': 'circle',
        'source': SOURCE_DIRECTIONS,
        'paint': {
            'circle-radius': 10,
            'circle-color': 'white',
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'destination'],
        ],
    }, {
        'id': 'directions-destination-point',
        'type': 'symbol',
        'source': SOURCE_DIRECTIONS,
        'layout': {
            'icon-image': 'destination',
            'icon-size': 0.9,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'destination'],
        ],
    },
];
