
import {map, debounceTime, finalize, switchMap, tap, first} from 'rxjs/operators';

import {of as observableOf, Observable, BehaviorSubject, forkJoin} from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompensationStep } from '../enums';
import { merge } from 'lodash';
import {
    CompensationCalculatorCreditResult, CompensationCalculatorDebitResult,
    CompensationCalculatorInput,
    DebtorOptions,
    RefundTypeOptions
} from '../../../interfaces';
import { CompensationCalculatorState } from '../compensation-calculator.state';
import { CompensationCalculatorService } from '../../../services';
import { Debtor } from '../../../enums';
import { ItemCallback, MultipleSelect } from '@ro-ngx/core';
import { DeliveryCompanyService } from '@ro-ngx/delivery';
import { DeliveryType, Order } from '@ro-ngx/orders';
import { CompensationCalculatorConfig } from '../compensation-calculator-config';
import { ConfigureCustomDebitComponent } from './configure-custom-debit';
import { ConfigureCustomCreditComponent } from './configure-custom-credit';

@Component({
    selector: 'configure-compensation',
    template: require('./configure-compensation.component.html')
})
export class ConfigureCompensationComponent implements OnInit {
    @Input()
    public order: Order;

    public form: FormGroup;
    public entries: any = [];
    public customCredits: CompensationCalculatorCreditResult[];
    public customDebits: CompensationCalculatorDebitResult[];

    public Debtor: typeof Debtor = Debtor;

    public isLoading$: BehaviorSubject<boolean>;

    public refundType = 'money_back';

    public config: CompensationCalculatorConfig;

    @ViewChildren(ConfigureCustomDebitComponent)
    protected customDebitComponents: QueryList<ConfigureCustomDebitComponent>;

    @ViewChildren(ConfigureCustomCreditComponent)
    protected customCreditComponents: QueryList<ConfigureCustomCreditComponent>;

    constructor(
        protected calculatorService: CompensationCalculatorService,
        protected deliveryCompanyService: DeliveryCompanyService,
        protected formBuilder: FormBuilder,
        protected state: CompensationCalculatorState,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.isLoading$ = new BehaviorSubject<boolean>(true);

            forkJoin(
                    this.getEntries(),
                    this.state.customCredits$.pipe(first()),
                    this.state.customDebits$.pipe(first()),
                    this.state.config$.pipe(first())

            ).pipe(
                finalize(() => this.isLoading$.next(false))
            )
            .subscribe(([entries, customCredits, customDebits, config]) => {
                this.entries = entries;
                this.customCredits = customCredits;
                this.customDebits = customDebits;
                this.config = config;
                this.changeDetectorRef.markForCheck();
            });
    }

    public nextStep(): void {
        const customCredits = [];

        for (const component of this.customCreditComponents.toArray()) {
            if (! component.isValid()) {
                return;
            }

            customCredits.push(component.getValue());
        }

        const customDebits = [];

        for (const component of this.customDebitComponents.toArray()) {
            if (! component.isValid()) {
                return;
            }

            customDebits.push(component.getValue());
        }

        const calculators = this.entries.map((entry) => merge(entry.calculator, {
            input: {
                ...entry.form.value
            }
        }));

        this.state.setCalculators(calculators);
        this.state.setCustomCredits(customCredits);
        this.state.setCustomDebits(customDebits);
        this.state.completeStep(CompensationStep.ConfigureCompensation);
        this.state.setStep(CompensationStep.ConfirmCompensation);
    }

    public close(): void {
        this.state.close();
    }

    protected createDeliveryCompanySelect(onSelection: ItemCallback): MultipleSelect {
        let initialValue;

        if (this.order.deliveryType !== DeliveryType.Pickup) {
            initialValue = this.order.deliveryorder.deliverycompany;
        }

        return new MultipleSelect({
            onSelection,
            initialValue,
            items$: (multipleSelect) => {
                const debounceTimeMs: number = 600;
                return multipleSelect.searchText$
                    .pipe(
                        tap(() => multipleSelect.setLoading(true)),
                        debounceTime(debounceTimeMs),
                        switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                        tap(() => {
                            multipleSelect.setLoading(false);
                            this.changeDetectorRef.markForCheck();
                        })
                    );
            },
            itemKeyVisible: 'companyName',
            itemKeyId: 'deliveryCompanyID',
            maxSelections: 1
        });
    }

    protected getEntries(): Observable<any> {
        return this.state.calculators$.pipe(first(),
            switchMap((calculatorInputs) => {
                    if (calculatorInputs.length === 0) {
                        return observableOf([]);
                    }

                    const entries$ = [];

                    for (const input of calculatorInputs) {
                        const entry$ = forkJoin(
                            this.calculatorService.getDebtorOptions(this.order.orderID, input),
                            this.calculatorService.getRefundTypeOptions(this.order.orderID, input)

                        ).pipe(
                            map(([debtorOptions, refundTypeOptions]) => {
                                return this.createEntry(input, debtorOptions, refundTypeOptions);
                            }));

                        entries$.push(entry$);
                    }

                    return forkJoin(entries$);
                }
            )
        )
    }

    protected createEntry(
        calculator: CompensationCalculatorInput,
        debtorOptions: DebtorOptions,
        refundTypeOptions: RefundTypeOptions
    ): any {
        const form = this.formBuilder.group({
            debtor: debtorOptions.default,
            debtorID: this.getDebtorID(debtorOptions.default),
            refundType: this.refundType
        });

        let deliveryCompanySelect;

        form.get('debtor').valueChanges.subscribe((debtor) => {
            if (debtor === Debtor.DELIVERY_COMPANY) {
                deliveryCompanySelect.selectItem(this.order.deliveryorder.deliverycompany);
            } else {
                form.patchValue({
                    debtorID: this.getDebtorID(debtor)
                });
            }
        });

        if (debtorOptions.options.indexOf(Debtor.DELIVERY_COMPANY) !== -1) {
            deliveryCompanySelect = this.createDeliveryCompanySelect(
                (deliveryCompany) => form.get('debtorID').setValue(deliveryCompany.deliveryCompanyID)
            );
        }

        return {
            calculator,
            debtorOptions,
            refundTypeOptions,
            form,
            deliveryCompanySelect
        };
    }

    protected getDebtorID(debtor: Debtor): string|number {
        switch (debtor) {
            case Debtor.DELIVERY_COMPANY:
                return this.order.deliveryorder.deliverycompany.deliveryCompanyID;
            case Debtor.DELIVERY_DRIVER:
                return this.order.deliveryorder.deliverydriver.deliveryDriverID;
            case Debtor.RESTAURANT:
                return this.order.clientKey;
            case Debtor.HUNGRIG:
            default:
                return null;
        }
    }
}
