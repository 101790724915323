import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'delivery-times-hurrier-delay',
    template: require('./delivery-times-hurrier-delay.component.html'),
    styles: [require('./delivery-times-hurrier-delay.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryTimesHurrierDelayComponent {
    @Input()
    public item: any;

    @Input()
    public translations: { [key: string]: string } = {};

    get alertClass(): string {
        const ALERT_SUCCESS_THRESHOLD = 5;
        const ALERT_WARNING_THRESHOLD = 6;
        const ALERT_SUPER_WARNING_THRESHOLD = 10;

        const customFleetMinutesDelay = this.item.settings.customFleetMinutesDelay;

        if (customFleetMinutesDelay <= ALERT_SUCCESS_THRESHOLD) {
            return 'alert-success';
        } else if (customFleetMinutesDelay <= ALERT_WARNING_THRESHOLD) {
            return 'alert-warning';
        } else if (customFleetMinutesDelay <= ALERT_SUPER_WARNING_THRESHOLD) {
            return 'alert-super-warning';
        } else {
            return 'alert-danger';
        }
    }

    get range(): string {
        const settings = this.item.settings;
        return settings.customFleetMinutesDelay + ' min';
    }
}
