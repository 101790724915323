import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DeliveryOrder, DeliveryOrderAction } from '../../../interfaces';
import { DeliveryOrderActionService } from '../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryOrderActionType } from '../../../enums';
import { format, parse } from 'date-fns';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface DeliveryOrderActionTypeOption {
    actionType: DeliveryOrderActionType;
    translateKey: string;
}

@Component({
    selector: 'create-action-modal',
    template: require('./create-action-modal.component.html')
})
export class CreateActionModalComponent implements OnInit {
    @Input()
    public deliveryOrder: DeliveryOrder;

    @Output()
    public onCreated: EventEmitter<DeliveryOrderAction> = new EventEmitter<DeliveryOrderAction>();

    @ViewChild('modal')
    public modal: ModalDirective;

    public actionTypeOptions: DeliveryOrderActionTypeOption[];

    public form: FormGroup;

    public dateTimeForm: FormGroup;

    public creating$: BehaviorSubject<boolean>;

    public myDpOptions: IMyDpOptions = {
        dateFormat: 'yyyy-mm-dd'
    };

    public selDate: string;

    constructor(
        protected deliveryOrderActionService: DeliveryOrderActionService,
        protected formBuilder: FormBuilder
    ) {
        this.actionTypeOptions = [
            {
                actionType: DeliveryOrderActionType.CheckIn,
                translateKey: 'delivery_order_action.type.check_in'
            },
            {
                actionType: DeliveryOrderActionType.Delivered,
                translateKey: 'delivery_order_action.type.delivered'
            },
            {
                actionType: DeliveryOrderActionType.Finished,
                translateKey: 'delivery_order_action.type.finished'
            },
            {
                actionType: DeliveryOrderActionType.Accepted,
                translateKey: 'delivery_order_action.type.accepted'
            }
        ];
    }

    public ngOnInit(): void {
        this.creating$ = new BehaviorSubject<boolean>(false);

        this.form = this.formBuilder.group({
            deliveryDriverID: this.deliveryOrder.deliveryDriverID,
            type: [null, Validators.required],
            timeLine: [null, Validators.required],
            latitude: 0,
            longitude: 0
        });

        this.selDate = format(new Date(), 'YYYY-MM-DD');

        this.dateTimeForm = this.formBuilder.group({
            date: [this.selDate, Validators.required],
            time: [format(new Date(), 'HH:mm'), Validators.required]
        });
    }

    public open(): void {
        this.modal.show();
    }

    public create(): void {
        this.setTimeLine();

        if (this.form.invalid) {
            return;
        }

        this.creating$.next(true);
        this.deliveryOrderActionService.createAction(this.deliveryOrder.deliveryOrderID, this.form.value)
            .pipe(finalize(() => this.creating$.next(false)))
            .subscribe((action) => {
                this.onCreated.emit(action);
                this.modal.hide();
            });
    }

    public onDateChanged(date: IMyDateModel): void {
        this.dateTimeForm.get('date').setValue(date.formatted);
    }

    protected setTimeLine(): void {
        if (this.dateTimeForm.invalid) {
            return;
        }

        const dateTime = this.dateTimeForm.value;
        const timeLine = parse(`${dateTime.date} ${dateTime.time}`);
        this.form.patchValue({ timeLine });
    }
}
