
import {map} from 'rxjs/operators';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';
import { CompensationStep } from './enums';
import { cloneDeep } from 'lodash';
import { CompensationCalculatorConfig } from './compensation-calculator-config';
import { CompensationCalculatorCreditResult, CompensationCalculatorDebitResult } from '../../interfaces';

export interface CompensationStepState {
    step: CompensationStep;
    translateKey: string;
    current: boolean;
    complete: boolean;
}

export const INITIAL_STEPS: CompensationStepState[] = [
    {
        translateKey: 'order_compensation.steps.configure_calculator',
        step: CompensationStep.ConfigureCalculator,
        current: true,
        complete: false
    },
    {
        translateKey: 'order_compensation.steps.configure_compensation',
        step: CompensationStep.ConfigureCompensation,
        current: false,
        complete: false
    },
    {
        translateKey: 'order_compensation.steps.confirm_compensation',
        step: CompensationStep.ConfirmCompensation,
        current: false,
        complete: false
    }
];

export const DEFAULT_CONFIG: CompensationCalculatorConfig = {
    advancedView: false,
    debitsOnly: false
};

export class CompensationCalculatorState {
    public steps$: Observable<CompensationStepState[]>;
    public calculators$: Observable<any>;
    public customCredits$: Observable<CompensationCalculatorCreditResult[]>;
    public customDebits$: Observable<CompensationCalculatorDebitResult[]>;
    public onClose$: Observable<void>;
    public onComplete$: Observable<void>;
    public config$: Observable<CompensationCalculatorConfig>;

    protected steps: BehaviorSubject<CompensationStepState[]>;
    protected calculators: BehaviorSubject<any>;
    protected customCredits: BehaviorSubject<CompensationCalculatorCreditResult[]>;
    protected customDebits: BehaviorSubject<CompensationCalculatorDebitResult[]>;
    protected onClose: Subject<void>;
    protected onComplete: Subject<void>;
    protected config: BehaviorSubject<CompensationCalculatorConfig>;

    constructor() {
        this.steps = new BehaviorSubject<CompensationStepState[]>(cloneDeep(INITIAL_STEPS));
        this.steps$ = this.steps.asObservable();

        this.calculators = new BehaviorSubject<any>([]);
        this.calculators$ = this.calculators.asObservable();

        this.customCredits = new BehaviorSubject<CompensationCalculatorCreditResult[]>([]);
        this.customCredits$ = this.customCredits.asObservable();

        this.customDebits = new BehaviorSubject<CompensationCalculatorDebitResult[]>([]);
        this.customDebits$ = this.customDebits.asObservable();

        this.onClose = new Subject<void>();
        this.onClose$ = this.onClose.asObservable();

        this.onComplete = new Subject<void>();
        this.onComplete$ = this.onComplete.asObservable();

        this.config = new BehaviorSubject<CompensationCalculatorConfig>(DEFAULT_CONFIG);
        this.config$ = this.config.asObservable();
    }

    public setCalculators(calculators: any): void {
        this.calculators.next(calculators);
    }

    public setCustomCredits(customCredits: CompensationCalculatorCreditResult[]): void {
        this.customCredits.next(customCredits);
    }

    public setCustomDebits(customDebits: CompensationCalculatorDebitResult[]): void {
        this.customDebits.next(customDebits);
    }

    public setConfig(config: CompensationCalculatorConfig): void {
        this.config.next(config);
    }

    public completeStep(step: CompensationStep): void {
        const states = this.steps.getValue();

        for (const state of states) {
            if (state.step === step) {
                state.complete = true;
            }
        }

        this.steps.next(states);
    }

    public setStep(step: CompensationStep): void {
        const states = this.steps.getValue();

        for (const state of states) {
            state.current = step === state.step;
        }

        this.steps.next(states);
    }

    public close(): void {
        this.onClose.next();
    }

    public complete(): void {
        this.onComplete.next();
    }

    public reset(): void {
        this.calculators.next([]);
        this.customDebits.next([]);
        this.customCredits.next([]);
        this.steps.next(cloneDeep(INITIAL_STEPS));
    }

    public get step$(): Observable<CompensationStepState> {
        return this.steps$.pipe(
            map((states) => states.find((state) => state.current)));
    }
}
