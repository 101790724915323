import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-overlay',
    template: `
        <div class="loading-overlay-wrapper" [ngClass]="{'loading': loadingIf}">
            <ng-content></ng-content>
            
            <div class="loading-overlay"></div>
            
            <div class="loading-wrapper">
                <loading-linear [loadingIf]="loadingIf"></loading-linear>
            </div>
        </div>
    `
})
export class LoadingOverlayComponent {

    @Input()
    public loadingIf: boolean = false;
}
