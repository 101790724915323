import { NgModule } from '@angular/core';

import { DeliveryDriverSummariesComponent } from './delivery-driver-summaries.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

const routes: Routes = [
    {
        path: '',
        component: DeliveryDriverSummariesComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RoNg2Module,
        BsDatepickerModule
    ],
    exports: [],
    declarations: [ DeliveryDriverSummariesComponent ],
    providers: []
})
export class DeliveryDriverSummariesModule {
}
