import { Order } from '@ro-ngx/orders';
import { Debtor } from '../../enums';

export const getDebtorID = (order: Order, debtor: Debtor): number | string => {
    switch (debtor) {
        case Debtor.DELIVERY_COMPANY:
            return order.deliveryorder.deliverycompany.deliveryCompanyID;
        case Debtor.DELIVERY_DRIVER:
            return order.deliveryorder.deliverydriver.deliveryDriverID;
        case Debtor.RESTAURANT:
            return order.clientKey;
        case Debtor.HUNGRIG:
        default:
            return null;
    }
};
