import { Injectable } from '@angular/core';
import { HungrigClient, BaseService } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { CampaignGroup } from '../interfaces';

@Injectable()
export class CampaignGroupService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroups(): Observable<CampaignGroup[]> {
        const url = `${this.hungrigClient.baseUrl}/campaigngroups`;
        return this.get(url);
    }

    public createGroup(group: CampaignGroup): Observable<CampaignGroup> {
        const url = `${this.hungrigClient.baseUrl}/campaigngroups`;
        return this.post(url, group);
    }

    public updateGroup(id: number, group: CampaignGroup): Observable<CampaignGroup> {
        const url = `${this.hungrigClient.baseUrl}/campaigngroups/${id}`;
        return this.put(url, group);
    }

    public deleteGroup(id: number): Observable<CampaignGroup> {
        const url = `${this.hungrigClient.baseUrl}/campaigngroups/${id}`;
        return this.delete(url);
    }
}
