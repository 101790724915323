import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    AuthenticationService,
    AuthorizedService,
    AuthorizationInterceptor,
    AuthAdminGuard,
    AuthGuestGuard
} from './services';
import { AuthenticationTokenStorage } from './services/authentication-token.storage';
import { AuthenticationConfig } from './authentication-config';
import { TranslateModule } from "@ngx-translate/core";
import { defaultTranslate } from "@ro-ngx/core";

@NgModule({
    imports: [
        TranslateModule.forChild(defaultTranslate)
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class AuthenticationModule {
    public static forRoot(config: AuthenticationConfig): ModuleWithProviders {
        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthenticationTokenStorage,
                AuthorizationInterceptor,
                AuthenticationService,
                AuthorizedService,
                AuthAdminGuard,
                AuthGuestGuard,
                {
                    provide: AuthenticationConfig,
                    useValue: config
                }
            ]
        };
    }
}
