import { UserConnection as UserConnectionContract, Role as RoleContract } from './interfaces';
import { PermissionName, RoleName, UserConnectionType } from './enums';
import { Role } from "./role";

export class UserConnection implements UserConnectionContract {

    public userConnectionID: number;
    public type: UserConnectionType;
    public userID: number;
    public adminID: number | null;
    public deliveryCompanyID: number | null;
    public deliveryDriverID: number | null;
    public clientKey: string | null;

    public roles: RoleContract[];

    constructor(data: Partial<UserConnectionContract>) {
        Object.assign(this, data, {
            roles: data.roles ? data.roles.map((role) => new Role(role)) : []
        });
    }

    public getId(): string | number {
        return this[UserConnection.getReferenceField(this)];
    }

    public static getReferenceField(userConnection: UserConnectionContract): string {
        switch (userConnection.type) {
            case UserConnectionType.ADMIN:
                return 'adminID';

            case UserConnectionType.DELIVERY_DRIVER:
                return 'deliveryDriverID';

            case UserConnectionType.RESTAURANT:
                return 'clientKey';

            default:
                return '';
        }
    }

    public hasPermissions(names: PermissionName[]): boolean {
        return ! names.find((name) => this.hasPermission(name) === false);
    }

    public hasPermission(name: PermissionName): boolean {
        return !! this.roles.find((role) => role.hasPermission(name));
    }

    public hasRole(name: RoleName): boolean {
        return !! this.roles.find(((role) => role.name === name));
    }
}
