import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';
import {NgModule, ApplicationRef, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { SeoService } from './global/seo/seo.service';
import { HttpModule } from '@angular/http';
import { RoNg2Module, DialogModule, CurrencyModule, defaultTranslate } from '@ro-ngx/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './components/home/home.component';
import { AppState } from './app.service';
import { Env } from './enviroment';
import { CapitilizePipe } from './global/pipes/capitalize.pipe';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HistoryState } from './global/state/HistoryState';
import { UserService } from './http/user/user.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AuthenticationModule } from '@ro-ngx/authentication';
import { UsersModule } from '@ro-ngx/users';
import { ProductsModule } from '@ro-ngx/products';
import { OrderTabCartModule } from '@ro-ngx/order-tab-cart';
import { CompensationsModule } from '@ro-ngx/compensations';
import { RestaurantsModule } from '@ro-ngx/restaurants';
import { PushNotificationConfigModule } from '@ro-ngx/push-notification';
import { CouponsModule, CouponManagerConfigModule } from '@ro-ngx/coupons';
import { registerLocaleData } from '@angular/common';
import localeSe from '@angular/common/locales/se';
import localeSeExtra from '@angular/common/locales/extra/se';

registerLocaleData(localeSe,'sv-SE',localeSeExtra);


@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        CapitilizePipe,
        AppComponent,
        HomeComponent,
        NavbarComponent,
        PageNotFoundComponent
    ],
    imports: [
        HttpModule,
        BrowserModule,
        RouterModule.forRoot(routes),
        TranslateModule.forRoot(defaultTranslate),
        ButtonsModule.forRoot(),
        RoNg2Module.forRoot({
            baseUrl: Env.API_URL,
            defaultTranslations: {
                brand: Env.BRAND_NAME
            }
        }),
        DialogModule.forRoot(),
        ModalModule.forRoot(),
        CurrencyModule.forRoot({
            currency: Env.CURRENCY,
            decimalPoints: Env.CURRENCY_DECIMAL_POINTS,
            multiplier: Env.CURRENCY_MULTIPLIER
        }),
        AuthenticationModule.forRoot({
            unauthorizedUrl: '/login',
            authorizedUrl: '/'
        }),
        OrderTabCartModule.forRoot({
            displayRestricted: false,
            displayTaxRates: Env.DISPLAY_TAX_RATES,
        }),
        CompensationsModule.forRoot({
            enabled: false,
            taxRates: []
        }),
        PushNotificationConfigModule.forRoot({
            canSelectOs: false
        }),
        RestaurantsModule.forRoot(),
        UsersModule.forRoot(),
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        ProductsModule.forRoot(),
        UpgradeModule,
        FormsModule,
        CouponsModule.forRoot(),
        CouponManagerConfigModule.forRoot({
            asAdmin: false
        }),
        ReactiveFormsModule
    ],
    providers: [
        AppState,
        SeoService,
        HistoryState,
        UserService,
        { provide: LOCALE_ID, useValue: `${Env.LANGUAGE_DEFAULT}-${Env.COUNTRY}` }
    ],
    entryComponents: [
        HomeComponent,
        NavbarComponent,
        PageNotFoundComponent
    ]
})
export class AppModule {
    constructor(
        public applicationRef: ApplicationRef,
        public appState: AppState
    ) {
    }

    public hmrOnInit(store: any): void {
        if (!store || !store.state) {
            return;
        }

        this.appState._state = store.state;
        if ('restoreInputValues' in store) {
            const restoreInputValues = store.restoreInputValues;
            setTimeout(restoreInputValues);
        }

        this.applicationRef.tick();
        delete store.state;
        delete store.restoreInputValues;
    }

    public hmrOnDestroy(store: any): void {
        const cmpLocation = this.applicationRef.components.map((cmp) => cmp.location.nativeElement);
        store.state = this.appState._state;
        store.disposeOldHosts = createNewHosts(cmpLocation);
        store.restoreInputValues  = createInputTransfer();
        removeNgStyles();
    }

    public hmrAfterDestroy(store: any): void {
        store.disposeOldHosts();
        delete store.disposeOldHosts;
    }
}
