export enum ActivityType {
    AUTHENTICATION = 'authentication',
    AUTHENTICATION_REFRESHED = 'authentication_refreshed',

    DELIVERY_ORDER_UPDATE = 'delivery_order_updated',
    DELIVERY_ORDER_CONFIRM = 'delivery_order_confirm',
    DELIVERY_ORDER_CHANGE_DELIVERY_COMPANY = 'delivery_order_change_delivery_company',

    COMPENSATION_CREATE = 'compensation_create',
    COMPENSATION_UPDATE = 'compensation_update',
    COMPENSATION_COMPLETE = 'compensation_complete',
    COMPENSATION_REFUND = 'compensation_refund',
    COMPENSATION_CANCEL = 'compensation_cancel',
    COMPENSATION_CREDIT_CREATE = 'compensation_credit_create',
    COMPENSATION_CREDIT_UPDATE = 'compensation_credit_update',
    COMPENSATION_CREDIT_DISCARD = 'compensation_credit_discard',
    COMPENSATION_DEBIT_CREATE = 'compensation_debit_create',
    COMPENSATION_DEBIT_UPDATE = 'compensation_debit_update',
    COMPENSATION_DEBIT_DISCARD = 'compensation_debit_discard',

    USER_UPDATE = 'user_update',

    RESTAURANT_CREATE = 'restaurant_create',
    RESTAURANT_UPDATE = 'restaurant_update',

    RESTAURANT_META_UPDATE = 'restaurant_meta_update',

    RESTAURANT_FINANCE_UPDATE = 'restaurant_finance_update',

    ORDER_CONTACT_CREATED = 'order_contact_create',
}
