import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from "@ro-ngx/core";
import { Observable } from "rxjs";
import { Restaurant } from "../interfaces";
import { AuthenticationToken } from "@ro-ngx/authentication";

@Injectable()
export class RestaurantService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getRestaurants(options: GetRestaurantsOptions = {}): Observable<Restaurant[]> {
        return this.get(`/restaurant`, {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public getRestaurant(clientKey: string, options: GetRestaurantOptions = {}): Observable<Restaurant> {
        return this.get(`/restaurant/${clientKey}`, {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public getTokenBearer(clientKey: string): Observable<AuthenticationToken> {
        return this.get(`/restaurant/${clientKey}/tokenBearer`);
    }
}

export interface GetRestaurantsOptions {
    clientKey?: string|string[];
    includes?: string[];
}

export interface GetRestaurantOptions {
    includes?: string[];
}
