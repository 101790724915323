import { NgModule } from '@angular/core';

import { RestaurantConnectionSelectComponent } from './restaurant-connection-select.component';
import { RoNg2Module } from '@ro-ngx/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        RoNg2Module
    ],
    exports: [ RestaurantConnectionSelectComponent ],
    declarations: [ RestaurantConnectionSelectComponent ],
    providers: [],
})
export class RestaurantConnectionSelectModule {
}
