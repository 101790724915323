import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CurrencySupport {

    protected translateService: TranslateService;

    protected decimalPoints: number;

    protected multiplier: number;

    protected currency: string;

    constructor(currency: string, decimalPoints: number, multiplier: number, translateService: TranslateService) {
        this.translateService = translateService;
        this.decimalPoints = decimalPoints;
        this.multiplier = multiplier;
        this.currency = currency;
    }

    public multiply(value: number): number {
        return value * this.multiplier;
    }

    public divide(value: number): number {
        return value / this.multiplier;
    }

    public symbol(currency?: string): string {
        currency = typeof currency === 'undefined' ? this.currency : currency;

        return this.translateService.instant(`currency.${this.currency}.symbol`);
    }

    public format(value: number, decimalPoints?: number): string {
        decimalPoints = typeof decimalPoints === 'undefined' ? this.decimalPoints : decimalPoints;
        return this.divide(value)
            .toFixed(decimalPoints)
            .toString()
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(,|$))/g, '$1 ');
    }

    public text(value: number, currency?: string, decimalPoints?: number): string {
        return `${this.format(value, decimalPoints)} ${this.symbol(currency)}`;
    }
}
