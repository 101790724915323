import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PushNotificationService } from './http/push-notification.service';
import { IMyDpOptions } from 'mydatepicker';
import { NotificationStorage } from '@ro-ngx/core';
import {forkJoin, Observable} from 'rxjs';
import { PushNotificationConfig } from './push-notification-config';
import {finalize, startWith} from "rxjs/operators";

@Component({
    selector: 'send-push-notification',
    template: require('./send-push-notification.component.html')
})

export class SendPushNotificationComponent implements OnInit {
    public config: PushNotificationConfig;
    public form: FormGroup;
    public osOptions: { title: string, value: string }[];
    public datepickerOptions: IMyDpOptions = {
        dateFormat: 'yyyy-mm-dd'
    };
    public loading: {[key: string]: boolean} = {
        send: false
    };

    constructor(
        protected pushNotificationConfig: PushNotificationConfig,
        protected notificationService: PushNotificationService,
        protected notificationStorage: NotificationStorage,
        protected translateService: TranslateService,
        protected formBuilder: FormBuilder
    ) {
        this.config = pushNotificationConfig;
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            os: ['all', Validators.required],
            message: ['', Validators.required],
            sendAt: null,
            sendAtTime: '00:00:00'
        });

        this.sendAt.valueChanges.pipe(
            startWith(null)
        ).subscribe((sendAt) => sendAt === null ? this.sendAtTime.disable() : this.sendAtTime.enable());

        this.osOptions = [
            {
                title: this.translateService.instant('push_notification.os_all_option'),
                value: 'all'
            },
            {
                title: this.translateService.instant('push_notification.os_android_option'),
                value: 'android'
            },
            {
                title: this.translateService.instant('push_notification.os_ios_option'),
                value: 'ios'
            }
        ];
    }

    public send(): void {
        this.markFormAsTouched();

        if (! this.form.valid) {
            return;
        }

        const value = { ...this.form.value };

        if (value.sendAt) {
            value.sendAt = `${value.sendAt.formatted} ${value.sendAtTime}`;
        }

        delete value.sendAtTime;

        let observable;
        if (value.os !== 'all') {
            observable = this.notificationService.send(value);
        } else {
            observable = forkJoin(
                    this.notificationService.send({
                        ...value,
                        os: 'android'
                    }),
                    this.notificationService.send({
                        ...value,
                        os: 'ios'
                    })

            );
        }

        this.loading['send'] = true;
        observable.pipe(
            finalize(() => this.loading['send'] = false)
        )
            .subscribe(() => {
                this.form.reset({
                    os: 'all',
                    message: '',
                    sendAt: null,
                    sendAtTime: '00:00:00'
                });
                this.notificationStorage.success(this.translateService.instant('general.success'));
            });
    }

    protected markFormAsTouched(): void {
        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        }
    }

    get sendAt(): AbstractControl {
        return this.form.get('sendAt');
    }

    get sendAtTime(): AbstractControl {
        return this.form.get('sendAtTime');
    }

    get os(): AbstractControl {
        return this.form.get('os');
    }

    get message(): AbstractControl {
        return this.form.get('message');
    }
}
