import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import {
    CompensationCalculator, CompensationCalculatorInput, CompensationCalculatorResult,
    DebtorOptions, RefundTypeOptions
} from '../interfaces';

export interface GetCalculationOptions {
    debitsOnly?: boolean;
}

@Injectable()
export class CompensationCalculatorService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCalculators(orderID: number): Observable<CompensationCalculator[]> {
        const search = ParamsUtil.toHttpParams({ orderID });
        return this.get(`${this.hungrigClient.baseUrl}/rocms/compensation-calculators`, { search });
    }

    public getDebtorOptions(orderID: number, input: CompensationCalculatorInput): Observable<DebtorOptions> {
        const search = ParamsUtil.toHttpParams({
            orderID
        });
        const url = `${this.hungrigClient.baseUrl}/rocms/compensation-calculators/debtor-options`;

        return this.post(url, input, { search });
    }

    public getRefundTypeOptions(orderID: number, input: CompensationCalculatorInput): Observable<RefundTypeOptions> {
        const search = ParamsUtil.toHttpParams({
            orderID
        });
        const url = `${this.hungrigClient.baseUrl}/rocms/compensation-calculators/refund-type-options`;

        return this.post(url, input, { search });
    }

    public getCalculation(
        orderID: number, calculators: CompensationCalculatorInput, options: GetCalculationOptions = {}
    ): Observable<CompensationCalculatorResult> {
        const search = ParamsUtil.toHttpParams(options);

        return this.post(`${this.hungrigClient.baseUrl}/rocms/compensation-calculators/calculate`, {
            orderID,
            calculators
        }, { search });
    }
}
