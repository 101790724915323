import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDeliveryTimesItem } from '../../../../interfaces/delivery-times';

@Component({
    selector: 'delivery-times-time',
    template: require('./delivery-times-time.component.html'),
    styles: [require('./delivery-times-time.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryTimesTimeComponent {
    @Input()
    public item: IDeliveryTimesItem;

    get displayTime(): string {
        return `${this.item.acceptTime} min`;
    }

    get alertClass(): string {
        const ALERT_WARNING_THRESHOLD = 25;
        const ALERT_SUPER_WARNING_THRESHOLD = 30;
        const ALERT_DANGER_THRESHOLD = 35;

        if (this.item.acceptTime <= ALERT_WARNING_THRESHOLD) {
            return 'alert-success';
        } else if (this.item.acceptTime <= ALERT_SUPER_WARNING_THRESHOLD) {
            return 'alert-warning';
        } else if (this.item.acceptTime <= ALERT_DANGER_THRESHOLD) {
            return 'alert-super-warning';
        } else {
            return 'alert-danger';
        }
    }
}
