import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import Velocity from 'velocity-animate';

@Component({
    selector: 'button-loadable',
    template: `
        <button class="button-loadable btn"
                [attr.type]="type" 
                [disabled]="loading || disabled" 
                [ngClass]="btnClass" 
                (click)="tryClick.emit($event)" 
                #button>
            <span class="loadable-spinner" #loadableSpinner>
                <i class="fa fa-cog fa-spin"></i>            
            </span>
            <span class="loadable-content" #content>
                <ng-content></ng-content>
            </span>
        </button>
    `
})
export class ButtonLoadableComponent {

    @Input()
    public type: string = 'button';

    @Input()
    public btnClass: string = 'btn-default';

    @Output()
    public tryClick: EventEmitter<any>;

    @Input()
    public disabled: boolean = false;

    @Input()
    public animateSize: boolean = true;

    @Input()
    public set loading(boolean: boolean) {
        this._loading = boolean;

        if (boolean) {
            this.startAnimation();
        }
        else {
            this.reverseAnimation();
        }
    }

    public get loading(): boolean {
        return this._loading;
    }

    @ViewChild('button',{static:true})
    protected button: ElementRef;

    @ViewChild('loadableSpinner',{static:true})
    protected loadableSpinner: ElementRef;

    @ViewChild('content',{static:true})
    protected content: ElementRef;

    protected _loading: boolean = false;

    constructor() {
        this.tryClick = new EventEmitter();
    }

    /**
     * Start animation
     */
    public startAnimation(): void {
        const fromState = {
            opacity: [0, 1]
        };

        if (this.animateSize) {
            fromState['width'] = '10px';
        }
        Velocity(this.content.nativeElement, fromState, {
            duration: 300,
            easing: 'ease-in-out'
        });

        Velocity(this.loadableSpinner.nativeElement, {
            opacity: [1, 0]
        }, {
            duration: 300,
            easing: 'ease-in-out'
        });
    }

    /**
     * Rervers animation
     */
    public reverseAnimation(): void {
        Velocity(this.content.nativeElement, 'reverse', {
            duration: 300,
            easing: 'ease-in-out'
        });

        Velocity(this.loadableSpinner.nativeElement, 'reverse', {
            duration: 300,
            easing: 'ease-in-out'
        });
    }
}
