import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from "@ro-ngx/core";
import { RestaurantTag } from "../interfaces/restaurant-tag";
import { Observable } from "rxjs";
import { RestaurantTagType } from "../enums/restaurant-tag-type";

@Injectable()
export class RestaurantTagService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getRestaurantTags(options: GetRestaurantTagsOptions = {}): Observable<RestaurantTag[]> {
        return this.get('/restaurant-tag', {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public getRestaurantTagExamples(options: GetRestaurantTagsOptions = {}): Observable<RestaurantTag[]> {
        return this.get('/restaurant-tag/examples', {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public createRestaurantTag(restaurantTag: Partial<RestaurantTag>): Observable<RestaurantTag> {
        return this.post('/restaurant-tag', restaurantTag);
    }

    public deleteRestaurantTag(restaurantTagID: number): Observable<boolean> {
        return this.delete(`/restaurant-tag/${restaurantTagID}`);
    }
}

export interface GetRestaurantTagsOptions {
    clientKey?: string | string[];
    type?: RestaurantTagType | RestaurantTagType[];
    text?: string;

    limit?: number;
    groupBy?: string | string[];
}
