import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxglCircle from 'mapbox-gl-circle';
import { Feature, Point } from 'geojson';
import { DeliveryDistrict } from '../../../interfaces/delivery-district';
import { DeliveryDistrictsModuleConfig } from '../delivery-districts-module-config';
import { COUNTRY_FIT_BOUNDS, RoFitBoundsControl } from '@ro-ngx/mapbox-support';

// tslint:disable:no-magic-numbers
const DISTRICT_MARKERS_LAYER = 'district-markers';
const DISTRICTS_SOURCE = 'districts';
const DISTRICT_RADIUS = 5000;

@Component({
    selector: 'district-map',
    template: require('./district-map.component.html'),
})
export class DistrictMapComponent implements OnInit, OnChanges {
    public map: any;
    @Input() public districts: DeliveryDistrict[] = [];
    @Output() public districtSelect: EventEmitter<string> = new EventEmitter();
    @Output() public countryFit: EventEmitter<void> = new EventEmitter();
    @Output() public featuresFit: EventEmitter<void> = new EventEmitter();

    private mapLoaded: boolean = false;

    constructor(
        protected config: DeliveryDistrictsModuleConfig,
        private ngZone: NgZone,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { districts } = changes;
        if (districts && this.mapLoaded) {
            this.updateMapSource(districts.currentValue || []);
        }
    }

    public ngOnInit(): void {
        this.initMap();
    }

    private initMap(): void {
        this.ngZone.runOutsideAngular(() => {
            Object.assign(mapboxgl, {
                accessToken: this.config.accessToken,
            });

            this.map = new mapboxgl.Map({
                container: 'district-map',
                style: 'mapbox://styles/mapbox/streets-v9',
            });

            this.map.addControl(new mapboxgl.NavigationControl());
            this.map.addControl(new RoFitBoundsControl({
                country: {
                    name: this.config.appCountry,
                    onClick: () => this.fitCountryBounds(),
                },
            }));

            this.map.on('load', () => {
                this.mapLoaded = true;
                this.setMapSource(this.districts || []);
                this.fitCountryBounds();
            });
        });
    }

    /**
     * Fits bounds to minimal area needed to show the country.
     */
    private fitCountryBounds(): void {
        const fitBoundsOptions = { padding: 100 };
        this.ngZone.runOutsideAngular(() => {
            const country = this.config.appCountry.toLowerCase();
            const bounds = COUNTRY_FIT_BOUNDS[country];

            if (!bounds) {
                throw new Error(`Environment: unknown country code: ${country}`);
            }

            this.map.fitBounds(bounds, fitBoundsOptions);
            this.countryFit.emit();
        });
    }

    private updateMapSource(data: DeliveryDistrict[]): void {
        this.map.getSource(DISTRICTS_SOURCE)
            .setData({
                'type': 'FeatureCollection',
                'features': this.dataToMapSource(data),
            });
    }

    private setMapSource(data: DeliveryDistrict[] = []): void {
        this.map.addSource(DISTRICTS_SOURCE, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': this.dataToMapSource(data),
            },
        });

        this.map.addLayer({
            'id': DISTRICT_MARKERS_LAYER,
            'type': 'circle',
            'source': DISTRICTS_SOURCE,
            'paint': {
                'circle-radius': 6,
                'circle-color': '#d11417',
                'circle-stroke-width': 5,
                'circle-stroke-color': 'rgba(255,255,255,0.5)',
            },
        });

        this.map.on('click', DISTRICT_MARKERS_LAYER, (e: mapboxgl.MapLayerMouseEvent) => {
            const properties = e.features[0].properties;
            this.districtSelect.emit(properties['deliveryDistrictID']);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        this.map.on('mouseenter', DISTRICT_MARKERS_LAYER, (e) => {
            e.target.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        this.map.on('mouseleave', DISTRICT_MARKERS_LAYER, (e) => {
            e.target.getCanvas().style.cursor = '';
        });
    }

    private dataToMapSource(data: DeliveryDistrict[] = []): Feature<Point>[] {
        const toFeature = ({ name, longitude, latitude, deliveryDistrictID }: DeliveryDistrict): Feature<Point> => {
            return {
                'type': 'Feature',
                'properties': {
                    name,
                    deliveryDistrictID,
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [longitude, latitude],
                },
            };
        };

        // Plot range circles
        data.forEach(({ longitude, latitude, deliveryDistrictID }) => {
            new MapboxglCircle({ lat: latitude, lng: longitude }, DISTRICT_RADIUS, {
                editable: false,
                fillColor: '#1d8aaf',
                properties: { deliveryDistrictID },
            }).addTo(this.map);
        });

        return data.map(toFeature);
    }
}
