import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { differenceInMinutes } from "date-fns";

const DRIVER_LOAD_NORMAL_CLASS = 'load-normal';
const DRIVER_LOAD_WARNING_CLASS = 'load-warning';

export const getDriverLoadClass = (count: number): string => {
    // GREEN: < 2 orders
    // ORANGE: >= 2 orders
    const MAX_EASY_ORDERS = 2;
    return count < MAX_EASY_ORDERS
        ? DRIVER_LOAD_NORMAL_CLASS
        : DRIVER_LOAD_WARNING_CLASS;
};

/*
    Colors a driver element in response to number of assigned orders.
*/
@Directive({
    selector: '[driverLoad]',
})
export class DriverLoadDirective {
    constructor(
        private readonly renderer: Renderer2,
        private readonly elRef: ElementRef,
    ) {
    }

    @Input('driverLoad')
    public set load(count: number) {
        const el = this.elRef.nativeElement;

        // Remove possible previous class
        [DRIVER_LOAD_NORMAL_CLASS, DRIVER_LOAD_WARNING_CLASS]
            .forEach((klass) => {
                this.renderer.removeClass(el, klass);
            });

        // Apply new class
        this.renderer.addClass(el, getDriverLoadClass(count));
    }
}
