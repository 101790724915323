import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { isOrderPositionable } from '../../helpers';
import { ManageOrderBase } from '../../models/manage-order-base.models';
import { ManageOrderStatus } from '../../models/manage-order-status.models'

@Component({
    selector: 'order-list-item',
    template: require('./order-list-item.component.html'),
    styles: [require('./order-list-item.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListItemComponent {
    @Input() public order: ManageOrderBase;

    @HostBinding('class.map-hovered')
    @Input() public mapHovered: boolean;

    @HostBinding('class.assigned')
    get isAssigned(): boolean {
        return this.order && this.order.status === ManageOrderStatus.Confirmed;
    }

    @HostBinding('class.unassigned')
    get isUnassigned(): boolean {
        return this.order && this.order.status === ManageOrderStatus.Unconfirmed;
    }

    get geoPositionable(): boolean {
        return isOrderPositionable(this.order);
    }

    @HostBinding('class.unpositionable')
    get geoUnpositionable(): boolean {
        return !this.geoPositionable;
    }
}
