import { Debtor, RefundType, Source, ValueType } from '../../enums';

export interface RefundTypeOption {
    refundType: RefundType;
    translateKey: string;
}

export const refundTypeOptions: RefundTypeOption[] = [
    {
        refundType: RefundType.COUPON,
        translateKey: 'order_compensation.refund_type.coupon'
    },
    {
        refundType: RefundType.MONEY_BACK,
        translateKey: 'order_compensation.refund_type.money_back'
    }
];

export interface DebtorOption {
    debtor: Debtor;
    translateKey: string;
}

export const debtorOptions: DebtorOption[] = [
    {
        debtor: Debtor.RESTAURANT,
        translateKey: 'order_compensation.debtor.restaurant'
    },
    {
        debtor: Debtor.DELIVERY_COMPANY,
        translateKey: 'order_compensation.debtor.delivery_company'
    },
    {
        debtor: Debtor.DELIVERY_DRIVER,
        translateKey: 'order_compensation.debtor.delivery_driver'
    },
    {
        debtor: Debtor.HUNGRIG,
        translateKey: 'general.brand'
    }
];

export interface ValueTypeOption {
    valueType: ValueType;
    translateKey: string;
}

export const valueTypeOptions: ValueTypeOption[] = [
    {
        valueType: ValueType.FLAT_AMOUNT,
        translateKey: 'order_compensation.value_type.flat_amount'
    },
    {
        valueType: ValueType.PERCENTAGE,
        translateKey: 'order_compensation.value_type.percentage'
    }
];

export interface SourceOption {
    source: Source;
    translateKey: string;
}

export const sourceOptions: SourceOption[] = [
    {
        source: Source.CUSTOM,
        translateKey: 'order_compensation.source.custom'
    },
    {
        source: Source.FOOD,
        translateKey: 'order_compensation.source.food'
    },
    {
        source: Source.ORDER,
        translateKey: 'order_compensation.source.order'
    },
    {
        source: Source.DELIVERY,
        translateKey: 'order_compensation.source.delivery'
    },
    {
        source: Source.PENALTY,
        translateKey: 'order_compensation.source.penalty'
    }
];
