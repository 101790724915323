import { NgModule } from '@angular/core';
import { TimeSelectComponent } from './time-select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,
        CommonModule
    ],
    exports: [TimeSelectComponent],
    declarations: [TimeSelectComponent],
    providers: [],
})
export class TimeSelectModule { }
