import {
    Component,
    NgZone,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import mbxClient from '@mapbox/mapbox-sdk';
import mbxDirections from '@mapbox/mapbox-sdk/services/directions';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { directionStyles } from './style';
import { TransportMode } from '../../interfaces';
import { DeliveryDirectionOptions } from './delivery-direction-options';
import { DeliveryDirectionsModuleConfig } from './delivery-directions-module-config';
import { calculateBounds } from '../../util';
import { Observable ,  of ,  from as fromPromise ,  forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const INITIAL_MAP_ZOOM = 10;

@Component({
    selector: 'delivery-directions',
    template: require('./delivery-directions.component.html'),
})
export class DeliveryDirectionsComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public options: DeliveryDirectionOptions;

    @ViewChild('map')
    public mapRef: ElementRef;

    protected map: any;
    protected directionsService: any;
    protected geocodingService: any;

    constructor(
        protected config: DeliveryDirectionsModuleConfig,
        protected ngZone: NgZone,
    ) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (!this.map) {
            return;
        }

        const { options } = changes;

        if (options && options.currentValue && !!this.directionsService) {
            this.getDirections();
        }
    }

    public ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            Object.assign(mapboxgl, {
                accessToken: this.config.accessToken,
            });

            const client = mbxClient({ accessToken: this.config.accessToken });
            this.directionsService = mbxDirections(client);
            this.geocodingService = mbxGeocoding(client);

            const mapOptions: any = {
                container: this.mapRef.nativeElement,
                style: 'mapbox://styles/mapbox/streets-v9',
                zoom: INITIAL_MAP_ZOOM,
            };

            if (this.options.mapCenter) {
                mapOptions.center = this.options.mapCenter;
            }

            this.map = new mapboxgl.Map(mapOptions);

            this.map.on('load', () => {
                this.loadImages(() => {
                    this.plotDriverMarker();
                    this.getDirections();
                });
            });
        });
    }

    public ngOnDestroy(): void {
        this.ngZone.runOutsideAngular(() => {
            this.map.remove();
        });
    }

    public plotDriverMarker() {
        const markers = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: this.options.markers.map((marker) => ({
                    type: 'Feature',
                    properties: {
                        icon: marker.icon,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: marker.coordinates,
                    },
                })),
            },
        };

        if (!this.map.getSource('markers')) {
            this.map.addSource('markers', markers);
        } else {
            this.map.getSource('markers').setData(markers.data);
        }
    }

    public getDirections(): void {
        const origin$ = this.asCoordinates(this.options.origin);
        const destination$ = this.asCoordinates(this.options.destination);

        forkJoin(origin$, destination$)
            .pipe(
                map(([origin, destination]) => {
                    return {
                        profile: this.options.transportMode === TransportMode.BIKE ? 'cycling' : 'driving',
                        waypoints: [
                            { coordinates: origin },
                            { coordinates: destination },
                        ],
                        geometries: 'geojson',
                        alternatives: true,
                        exclude: 'ferry',
                    };
                }),
                switchMap((options) => fromPromise(this.directionsService.getDirections(options).send())),
            )
            .subscribe((response: any) => {
                const origin = response.body.waypoints[0].location;
                const destination = response.body.waypoints[response.body.waypoints.length - 1].location;
                const routes = response.body.routes.sort((a, b) => a.distance - b.distance);
                const fastestRoute = routes.shift();
                this.setRoute(origin, destination, fastestRoute, routes);

                const allCoordinates = [fastestRoute.geometry.coordinates]
                    .concat(routes.map((route) => route.geometry.coordinates))
                    .reduce((a, b) => a.concat(b), []);

                const bounds = calculateBounds(allCoordinates);
                this.map.fitBounds(bounds, {
                    padding: 25,
                });

                this.plotDriverMarker();
            });
    }

    protected setRoute(origin: number[], destination: number[], primaryRoute: any, alternativeRoutes: any[]): void {
        const directions = {
            type: 'geojson',
            data: {

                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            type: 'primary',
                        },
                        geometry: primaryRoute.geometry,
                    },
                    {
                        type: 'Feature',
                        properties: {
                            id: 'origin',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: origin,
                        },
                    },
                    {
                        type: 'Feature',
                        properties: {
                            id: 'destination',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: destination,
                        },
                    },
                ].concat(alternativeRoutes.map((route) => {
                    return {
                        type: 'Feature',
                        properties: {
                            type: 'alternative',
                        },
                        geometry: route.geometry,
                    };
                })),
            },
        };

        if (!this.map.getSource('directions')) {
            this.map.addSource('directions', directions);
            directionStyles.forEach((style) => this.map.addLayer(style));
        } else {
            this.map.getSource('directions').setData(directions.data);
        }
    }

    protected asCoordinates(location: number[] | string): Observable<number[]> {
        return typeof location === 'string' ? this.geocode(location) : of(location);
    }

    protected geocode(location: string): Observable<number[]> {
        const options = {
            query: location,
            limit: 1,
        };

        return fromPromise(this.geocodingService.forwardGeocode(options).send())
            .pipe(
                map((response: any) => {
                    return response.body.features[0].geometry.coordinates;
                }),
            );
    }

    protected loadImages(onComplete: () => void): void {
        const prefix = '/assets/@ro-ngx/delivery';

        const images = [
            { id: 'origin', url: `${prefix}/icon-restaurant.png` },
            { id: 'destination', url: `${prefix}/icon-customer.png` },
            { id: 'bicycle', url: `${prefix}/icon-bicycle.png` },
            { id: 'car', url: `${prefix}/icon-car.png` },
        ];

        let loadedImages = 0;

        const onImageLoaded = () => {
            if (++loadedImages === images.length) {
                onComplete();
            }
        };

        for (const image of images) {
            this.loadImage(image.id, image.url, onImageLoaded);
        }
    }

    protected loadImage(id: string, url: string, onLoaded: () => void): void {
        this.map.loadImage(url, (error, image) => {
            if (error) {
                throw error;
            }

            this.map.addImage(id, image);
            onLoaded();
        });
    }
}
