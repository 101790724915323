import {
    COLOR_DEPENDS_ON_ORDER_SEVERITY,
    LAYER_DRIVER_ROUTES,
    SOURCE_DRIVERS_DIRECTIONS,
    WIDTH_DEPENDS_ON_STATE,
} from './constants';

const ENDPOINT_CIRCLE_RADIUS = 7;
const ENDPOINT_CIRCLE_STROKE_WIDTH = 2;

export const otherOrdersRoutesStyles: any[] = [

    // Orders on drivers - routes
    {
        'id': LAYER_DRIVER_ROUTES,
        'type': 'line',
        'source': SOURCE_DRIVERS_DIRECTIONS,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': COLOR_DEPENDS_ON_ORDER_SEVERITY,
            'line-width': WIDTH_DEPENDS_ON_STATE,
            'line-opacity': 0.8,
        },
        'filter': [
            'all',
            ['in', '$type', 'LineString'],
            ['==', 'type', 'primary'],
        ],
    },

    // Orders on drivers - route end-points
    {
        'id': 'drivers-directions-destination-point-circle',
        'type': 'circle',
        'source': SOURCE_DRIVERS_DIRECTIONS,
        'paint': {
            'circle-radius': ENDPOINT_CIRCLE_RADIUS,
            'circle-color': 'white',
            'circle-stroke-color': COLOR_DEPENDS_ON_ORDER_SEVERITY,
            'circle-stroke-width': ENDPOINT_CIRCLE_STROKE_WIDTH,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'destination'],
        ],
    }, {
        'id': 'drivers-directions-origin-point-circle',
        'type': 'circle',
        'source': SOURCE_DRIVERS_DIRECTIONS,
        'paint': {
            'circle-radius': ENDPOINT_CIRCLE_RADIUS,
            'circle-color': COLOR_DEPENDS_ON_ORDER_SEVERITY,
            'circle-stroke-color': 'white',
            'circle-stroke-width': ENDPOINT_CIRCLE_STROKE_WIDTH,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'origin'],
        ],
    }, {
        'id': 'drivers-directions-origin-point-pickup-time',
        'type': 'symbol',
        'source': SOURCE_DRIVERS_DIRECTIONS,
        'layout': {
            'text-field': ['format', ['get', 'drawPickupTime'], { 'font-scale': .7 }],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.2],
            'text-anchor': 'center',
            'text-allow-overlap': false,
            'symbol-z-order': 'source',
        },
        'paint': {
            'text-color': 'black',
            'text-halo-color': 'white',
            'text-halo-width': 6,
            'text-halo-blur': 2,
        },
        'filter': [
            'all',
            ['in', '$type', 'Point'],
            ['==', 'point-type', 'origin'],
        ],
    },
];
