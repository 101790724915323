import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValueType, Debtor } from '../../../enums';
import {
    refundTypeOptions, valueTypeOptions, RefundTypeOption, DebtorOption,
    ValueTypeOption, getDebtorOptions
} from '../../../common/options';
import { DeliveryType, Order } from '@ro-ngx/orders';
import {debounceTime, startWith, switchMap, tap} from 'rxjs/operators';
import { MultipleSelect } from '@ro-ngx/core';
import { DeliveryCompany, DeliveryCompanyService } from '@ro-ngx/delivery';
import { getDebtorID } from '../../../common/debtor';
import { CompensationsConfig, TaxRate } from '../../../compensations-config';

@Component({
    selector: 'configure-custom-compensation',
    template: require('./configure-custom-compensation.component.html')
})
export class ConfigureCustomCompensationComponent implements OnInit {
    @Input()
    public customCompensation: any;

    @Input()
    public order: Order;

    public form: FormGroup;

    public refundTypeOptions: RefundTypeOption[] = refundTypeOptions;

    public debtorOptions: DebtorOption[];

    public valueTypeOptions: ValueTypeOption[] = valueTypeOptions;

    public ValueType: typeof ValueType = ValueType;

    public Debtor: typeof Debtor = Debtor;

    public deliveryCompanySelect: MultipleSelect;

    public taxRates: TaxRate[];

    constructor(
        protected compensationsConfig: CompensationsConfig,
        protected deliveryCompanyService: DeliveryCompanyService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected formBuilder: FormBuilder
    ) {
        this.taxRates = compensationsConfig.taxRates;
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            debit: this.formBuilder.group({
                reason: [null, Validators.required],
                summaryReason: [null, Validators.required],
                debtor: [null, Validators.required],
                taxRate: [this.compensationsConfig.taxRates[0].value, Validators.required],
                debtorID: null,
                amount: [null, Validators.required]
            }),
            credit: this.formBuilder.group({
                reason: [null, Validators.required],
                value: [null, Validators.required],
                valueType: [null, Validators.required],
                refundType: [null, Validators.required],
            })
        });

        this.form.get('debit.debtor').valueChanges.subscribe((debtor) => {
            if (debtor === Debtor.DELIVERY_COMPANY) {
                this.deliveryCompanySelect.selectItem(this.order.deliveryorder.deliverycompany);
            } else if (debtor !== null) {
                this.form.get('debit.debtorID').setValue(getDebtorID(this.order, debtor));
            }
        });

        this.form.patchValue(this.customCompensation);

        const valueTypeCtrl = this.form.get('credit.valueType');
        const valueCtrl = this.form.get('credit.value');

        valueTypeCtrl.valueChanges.pipe(
            startWith(valueTypeCtrl.value)
        )
            .subscribe((valueType) => {
                if (valueType === null) {
                    valueCtrl.disable();
                } else if (valueCtrl.disabled) {
                    valueCtrl.enable();
                }
            });

        this.debtorOptions = getDebtorOptions(this.order);

        if (this.order.deliveryType !== DeliveryType.Pickup) {
            this.deliveryCompanySelect = new MultipleSelect({
                onSelection: (deliveryCompany: DeliveryCompany) => {
                    this.form.get('debit.debtorID').setValue(deliveryCompany.deliveryCompanyID);
                },
                initialValue: this.order.deliveryorder.deliverycompany,
                items$: (multipleSelect) => {
                    const debounceTimeMs: number = 600;
                    return multipleSelect.searchText$
                        .pipe(
                            tap(() => multipleSelect.setLoading(true)),
                            debounceTime(debounceTimeMs),
                            switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                            tap(() => {
                                multipleSelect.setLoading(false);
                                this.changeDetectorRef.markForCheck();
                            })
                        );
                },
                itemKeyVisible: 'companyName',
                itemKeyId: 'deliveryCompanyID',
                maxSelections: 1
            });
        }
    }

    get valueTypeCtrl(): AbstractControl {
        return this.form.get('credit.valueType');
    }

    public isValid(): boolean {
        return this.form.valid;
    }

    public getValue(): any {
        return this.form.value;
    }
}
