import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    BaseService, ErrorFactory,
    FileResponse,
    FileResponseFactory,
    HungrigClient,
    Paginate,
    PaginateFactory,
    ParamsUtil
} from '@ro-ngx/core';
import { Compensation, CompensationCredit, CompensationDebit } from '../interfaces';
import { CompensationsSearch } from './compensations-search';
import { ResponseContentType } from '@angular/http';
import {catchError, map} from 'rxjs/operators';

export interface GetCompensationOptions {
    include: string[];
}

@Injectable()
export class CompensationService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCompensations(compensationSearch: CompensationsSearch): Observable<Paginate<Compensation[]>> {
        const search = ParamsUtil.toHttpParams(compensationSearch);

        return this.hungrigClient.get(`${this.hungrigClient.baseUrl}/rocms/compensations`, { search }).pipe(
            map(PaginateFactory.from),
            catchError(ErrorFactory.throwObservable));
    }

    public getCompensationExport(format: string, compensationSearch: CompensationsSearch): Observable<FileResponse> {
        const search = ParamsUtil.toHttpParams({
            format,
            ...compensationSearch
        });

        return this.hungrigClient.get(`${this.hungrigClient.baseUrl}/rocms/compensations/export`, {
                search,
                responseType: ResponseContentType.Blob
            })
            .pipe(map(FileResponseFactory.fromResponse));
    }

    public getCompensation(compensationID: number, options: GetCompensationOptions): Observable<Compensation> {
        const search = ParamsUtil.toHttpParams(options);
        return this.get(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}`, { search });
    }

    public getCompensationHelpdeskMessage(compensationID: number): Observable<string> {
        return this.get(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/helpdesk-message`);
    }

    public completeCompensation(compensationID: number): Observable<Compensation> {
        return this.put(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/complete`, {});
    }



    public refundCompensation(compensationID: number): Observable<Compensation> {
        return this.put(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/refund`, {});
    }

    public cancelCompensation(compensationID: number): Observable<Compensation> {
        return this.put(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/complete`, {});
    }

    public createCompensation(compensation: Compensation): Observable<Compensation> {
        return this.post(`${this.hungrigClient.baseUrl}/rocms/compensations`, compensation);
    }

    public updateCompensation(
        compensationID: number,
        compensation: Partial<Compensation>
    ): Observable<Compensation> {
        return this.put(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}`, compensation);
    }

    public createCompensationCredit(
        compensationID: number,
        credit: Partial<CompensationCredit>
    ): Observable<CompensationCredit> {
        return this.post(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/credits`, credit);
    }

    public updateCompensationCredit(
        compensationID: number,
        creditID: number,
        credit: Partial<CompensationCredit>
    ): Observable<CompensationCredit> {
        return this.put(
            `${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/credits/${creditID}`,
            credit
        );
    }

    public discardCompensationCredit(
        compensationID: number,
        creditID: number
    ): Observable<CompensationCredit> {
        return this.put(
            `${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/credits/${creditID}/discard`,
            { }
        );
    }

    public updateCompensationDebit(
        compensationID: number,
        debitID: number,
        debit: Partial<CompensationDebit>
    ): Observable<CompensationDebit> {
        return this.put(
            `${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/debits/${debitID}`,
            debit
        );
    }

    public createCompensationDebit(
        compensationID: number,
        debit: Partial<CompensationDebit>
    ): Observable<CompensationDebit> {
        return this.post(`${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/debits`, debit);
    }

    public discardCompensationDebit(
        compensationID: number,
        debitID: number
    ): Observable<CompensationCredit> {
        return this.put(
            `${this.hungrigClient.baseUrl}/rocms/compensations/${compensationID}/debits/${debitID}/discard`,
            { }
        );
    }
}
