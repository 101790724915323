import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderRow } from '@ro-ngx/orders';
import { range } from 'lodash';
import { CompensationsConfig, TaxRate } from '../../../../compensations-config';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CompensationCalculatorState } from '../../compensation-calculator.state';
import { BaseComponent } from '../../../../base.component';

@Component({
    selector: 'cart-row-input',
    template: require('./cart-row-input.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [ require('./cart-row-input.component.less') ]
})
export class CartRowInputComponent extends BaseComponent implements OnInit {
    @Input()
    public form: FormGroup;

    @Input()
    public orderRow: OrderRow;

    public quantityRange: number[];

    public taxRates: TaxRate[] = [];

    public canEditPrice$: Observable<boolean>;

    constructor(
        protected compensationCalculatorState: CompensationCalculatorState,
        protected config: CompensationsConfig
    ) {
        super();
        this.taxRates = config.taxRates;
    }

    public ngOnInit(): void {
        if (this.orderRow) {
            this.quantityRange = range(this.orderRow.quantity + 1);
        }

        this.canEditPrice$ = this.compensationCalculatorState.config$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((config) => config.advancedView),
                map((advancedView) => this.orderRow === null || advancedView || this.orderRow.price === 0)
            );
    }
}
