
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HungrigClient, HungrigResponse } from '@ro-ngx/core';
import { Env } from '../../enviroment';
import { URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BaseService<T> {

    public apiUrl: string = Env.API_URL + '/';

    public resourceUrl: string = '';

    public primaryKeyName: string = '';

    constructor(public hungrigClient: HungrigClient) {
    }

    public url(url: string = '', includeResourceUrl: boolean = true): string {
        return this.apiUrl + ( includeResourceUrl ? this.resourceUrl : '' ) + url;
    }

    public params(object: { [key: string]: any }): URLSearchParams {
        const params = new URLSearchParams();

        Object.keys(object).forEach((key) => {
            params.set(key, object[key]);
        });

        return params;
    }

    public find(itemID: number, params?: {[key: string]: any} | URLSearchParams | string): Observable<T> {
        return this.hungrigClient.get(this.url(`/${itemID}`), {
                params: params ? params : null
            }).pipe(
            map(HungrigResponse.fromHungrigRequest),
            map((response) => response.data),);
    }

    public create(item: T): Observable<T> {
        return this.hungrigClient.post(this.url(''), item).pipe(
            map(HungrigResponse.fromHungrigRequest),
            map((response) => response.data),);
    }

    public update(itemID: number, item: T): Observable<T> {
        return this.hungrigClient.put(this.url(`/${itemID}`), item).pipe(
            map(HungrigResponse.fromHungrigRequest),
            map((response) => response.data),);
    }

    public updateMultiple(items: T[]): Observable<T[]> {
        return this.hungrigClient.patch(this.url(''), items).pipe(
            map(HungrigResponse.fromHungrigRequest),
            map((response) => response.data),
           catchError(HungrigResponse.fromHungrigRequestToError));
    }

    public delete(itemID: number): Observable<number> {
        return this.hungrigClient.delete(this.url(`/${itemID}`)).pipe(
            map(HungrigResponse.fromHungrigRequest),
            map((response) => response.data),
           catchError(HungrigResponse.fromHungrigRequestToError));
    }
}
