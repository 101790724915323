import { BehaviorSubject, Observable } from 'rxjs';

export class ItemStorage<T> {

    /**
     * @type {BehaviorSubject}
     */
    public subject: BehaviorSubject<T>;

    /**
     * @type {Observable}
     */
    public $: Observable<T>;

    constructor() {
        this.subject = new BehaviorSubject(undefined);
        this.$ = this.subject.asObservable();
    }

    /**
     * Replace current value with value
     *
     * @param value
     */
    public nextSubject(value: T): void {
        return this.subject.next(value);
    }

    /**
     * Current value in param and emits returned value
     *
     * @param callback
     */
    public changeValue(callback: (value: T) => T): void {
        const value = this.subject.getValue();

        this.nextSubject(callback(value));
    }
}
