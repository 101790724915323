import './polyfills';
import 'iframe-resizer/js/iframeResizer.contentWindow.js';

import { bootloader } from "@angularclass/hmr";
import { Env } from './enviroment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { enableProdMode } from '@angular/core';

if (Env.ENV === 'production') {
    enableProdMode();
}

bootloader(() =>
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((error) => console.error(error))
);

