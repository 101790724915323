import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CouponTemplate } from './coupon-template';
import { DiscountType } from '../../../interfaces';

@Injectable()
export class CouponTemplateService {
    public static readonly DEFAULT_TEMPLATE_ID: string = 'default';

    protected templates: CouponTemplate[] = [];

    constructor(translateService: TranslateService) {
        this.templates.push({
            id: CouponTemplateService.DEFAULT_TEMPLATE_ID,
            name: translateService.instant('coupon.template.default_name'),
            form: {
                clientKey: null,
                title: translateService.instant('coupon.template.default_title'),
                code: null,
                discountType: DiscountType.Amount,
                discountAmount: null,
                expiresLine: null,
                discountAuto: false,
                remainingQuantity: 1,
                reqAmount: 0,
                validPickup: true,
                validDeliveryRun: true,
                validFood: true,
                validCatering: true,
                validEvent: true,
                validHungrig: true,
                mon: true,
                tue: true,
                wed: true,
                thu: true,
                fri: true,
                sat: true,
                sun: true,
                validTimeFrom: '00:00:00',
                validTimeTo: '00:00:00',
                campaignGroupID: 0,
                numberOfCodes: 1,
                valid: true,
                enableExpiresLine: false,
                enableReqAmount: false,
                enableRemainingQuantity: true
            }
        });

        this.templates.push({
            id: 'compensation_10_percent',
            name: translateService.instant('coupon.template.compensation_10_percent_name'),
            form: {
                clientKey: null,
                title: translateService.instant('coupon.template.compensation_10_percent_title'),
                code: '########',
                discountType: DiscountType.Percentage,
                discountAmount: 10,
                expiresLine: null,
                discountAuto: false,
                remainingQuantity: 1,
                reqAmount: 0,
                validPickup: true,
                validDeliveryRun: true,
                validFood: true,
                validCatering: true,
                validEvent: true,
                validHungrig: true,
                mon: true,
                tue: true,
                wed: true,
                thu: true,
                fri: true,
                sat: true,
                sun: true,
                validTimeFrom: '00:00:00',
                validTimeTo: '00:00:00',
                campaignGroupID: 0,
                numberOfCodes: 1,
                valid: true,
                enableExpiresLine: false,
                enableReqAmount: false,
                enableRemainingQuantity: true
            }
        });

        this.templates.push({
            id: 'compensation_20_percent',
            name: translateService.instant('coupon.template.compensation_20_percent_name'),
            form: {
                clientKey: null,
                title: translateService.instant('coupon.template.compensation_20_percent_title'),
                code: '########',
                discountType: DiscountType.Percentage,
                discountAmount: 20,
                expiresLine: null,
                discountAuto: false,
                remainingQuantity: 1,
                reqAmount: 0,
                validPickup: true,
                validDeliveryRun: true,
                validFood: true,
                validCatering: true,
                validEvent: true,
                validHungrig: true,
                mon: true,
                tue: true,
                wed: true,
                thu: true,
                fri: true,
                sat: true,
                sun: true,
                validTimeFrom: '00:00:00',
                validTimeTo: '00:00:00',
                campaignGroupID: 0,
                numberOfCodes: 1,
                valid: true,
                enableExpiresLine: false,
                enableReqAmount: false,
                enableRemainingQuantity: true
            }
        });
    }

    public getTemplates(): CouponTemplate[] {
        return this.templates;
    }

    public getTemplate(id: string): CouponTemplate {
        return this.templates.find((template) => template.id === id);
    }
}
