import { selectedOrderRouteStyles } from './selected-order-route.styles';
import { otherOrdersRoutesStyles } from './other-orders-routes.styles';
import { driversMarkersStyles } from './drivers-markers.styles';

// Caution: layer order matters!
const allStyles = [
    ...otherOrdersRoutesStyles,
    ...selectedOrderRouteStyles,
    ...driversMarkersStyles,
];

export { allStyles };
