import { NgModule, ModuleWithProviders } from '@angular/core';
import { NotificationComponent } from './notification.component';
import { CommonModule } from '@angular/common';
import { NotificationStorage } from './notification.storage';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [NotificationComponent],
    declarations: [NotificationComponent],
    providers: []
})
export class NotificationModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: NotificationModule,
            providers: [
                NotificationStorage
            ]
        };
    }
}
