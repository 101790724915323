
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    BaseService,
    ErrorFactory,
    FileResponse, FileResponseFactory,
    HungrigClient,
    Paginate,
    PaginateFactory,
    ParamsUtil, ResponseFactory
} from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { RestaurantSummary } from "../interfaces/restaurant-summary";
import {ResponseContentType} from "@angular/http";

@Injectable()
export class RestaurantSummaryService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }


    public getRestaurantSummaryFile(url:string): Observable<FileResponse> {

        url = url.replace(this.hungrigClient.baseUrl, "");

        return this.hungrigClient.get(this.url(url), {responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }


    public getRestaurantSummaries(
        options: GetRestaurantSummariesOptions = {}): Observable<RestaurantSummary[]> {

        return this.get('/restaurant-summary', {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public getRestaurantSummariesPaginated(
        options: GetRestaurantSummariesPaginatedOptions = {}): Observable<Paginate<RestaurantSummary[]>> {

        const search = ParamsUtil.toHttpParams(options);
        return this.hungrigClient.get(`${this.apiUrl}/restaurant-summary/paginate`, {search})
            .pipe(
                map(PaginateFactory.from),
                catchError(ErrorFactory.throwObservable)
            );
    }
}

export interface GetRestaurantSummariesOptions {
    clientKey?: string|string[];
}

export interface GetRestaurantSummariesPaginatedOptions extends GetRestaurantSummariesOptions {
    perPage?: number;
    page?: number;
}
