import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectMultipleComponent } from './multiple/multiple.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        SelectMultipleComponent
    ],
    declarations: [
        SelectMultipleComponent
    ]
})
export class SelectModule {
}
