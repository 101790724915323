import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageOrderBase } from '../../models/manage-order-base.models';
import { ManageOrderDriver } from '../../models/manage-order-driver.models';
import { FormControl } from '@angular/forms';
import { ManageOrderAssignOrderEvent } from '../../models/request.models';

@Component({
    selector: 'order-assign',
    template: require('./order-assign.component.html'),
    styles: [require('./order-assign.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderAssignComponent {
    @Input() public order: ManageOrderBase;
    @Input() public driver: ManageOrderDriver;
    @Input() public error: any;
    @Input() public loading: boolean = false;
    @Output() public ok: EventEmitter<ManageOrderAssignOrderEvent> = new EventEmitter();
    @Output() public cancel: EventEmitter<void> = new EventEmitter();

    public confirmOrderChk: FormControl = new FormControl(false);

    public get hint(): string {
        if (!this.driver && !this.order) {
            return 'delivery.manage.assign.select_hint';
        } else if (!this.driver) {
            return 'delivery.manage.assign.select_driver_hint';
        } else if (!this.order) {
            return 'delivery.manage.assign.select_order_hint';
        }
    }

    public doAssign(): void {
        this.ok.emit({ order: this.order, driver: this.driver, confirmOrder: this.confirmOrderChk.value });
        this.confirmOrderChk.setValue(false);
    }
}
