import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User, UserConnectionType } from '@ro-ngx/users';
import { AuthenticationConfig } from "../authentication-config";
import { AuthorizedService } from "./authorized.service";

@Injectable()
export class AuthGuestGuard implements CanActivate {

    protected user: User;

    public constructor(protected authenticationConfig: AuthenticationConfig,
                       protected authorizedService: AuthorizedService,
                       protected router: Router) {

        this.authorizedService.user$
            .subscribe((user) => this.user = user);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authorizedService.findMyself()
            .pipe(
                map((user) => {
                    if (user.hasUserConnection(UserConnectionType.ADMIN, '*')) {
                        this.router.navigate([this.authenticationConfig.authorizedUrl]).then();
                        return false;
                    }

                    return true;
                }),
                catchError(() => of(true))
            );
    }
}
