import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
    GetRestaurantSummariesPaginatedOptions,
    RestaurantSummaryService
} from "../../services/restaurant-summary.service";
import { Observable ,  BehaviorSubject } from "rxjs";
import { Paginate, DialogService } from "@ro-ngx/core";
import { RestaurantSummary } from "../../interfaces/restaurant-summary";
import { share, flatMap, tap, finalize, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'restaurant-summary-list',
    template: require('./restaurant-summary-list.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestaurantSummaryListComponent implements OnInit {

    @Input() public clientKey: string;
    public perPage: number = 15;
    public dates: string[] = [];

    public options$: BehaviorSubject<GetRestaurantSummariesPaginatedOptions>;
    public original$: Observable<Paginate<RestaurantSummary[]>>;
    public isLoading$: BehaviorSubject<boolean>;


    constructor(
        protected restaurantSummaryService: RestaurantSummaryService,
        protected translateService: TranslateService,
        protected dialogService: DialogService
    ) {
        this.options$ = new BehaviorSubject({});
        this.isLoading$ = new BehaviorSubject(true);
    }

    public ngOnInit(): void {
        this.original$ = this.options$.pipe(
            map((options) => ({ clientKey: this.clientKey, perPage: this.perPage, ...options })),
            tap(() => this.isLoading$.next(true)),
            flatMap((options) => this.restaurantSummaryService.getRestaurantSummariesPaginated(options).pipe(
                finalize(() => this.isLoading$.next(false)),
            )),
            share()
        );
    }

   public getSummaryFile(datePeriod,fileURL):void {


       this.restaurantSummaryService.getRestaurantSummaryFile(fileURL)
           .subscribe(
               (file) => {
                   const fileName =  datePeriod + '.pdf';
                   FileSaver.saveAs(file.blob, fileName)
               },
               (err) => {
                   const notFound = 404;
                   if (err.status === notFound) {
                       this.dialogService.notify({
                           title: this.translateService.instant('general.error'),
                           body: this.translateService.instant('restaurant.summary.error_file_does_not_exist')
                       });
                   }
               }
           );
   }


    public setPage(event: Event, page: number): void {
        event.preventDefault();

        this.options$.next(
            Object.assign({}, this.options$.getValue(), {
                page: page
            })
        );
    }
}
