import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserActivity } from '../../interfaces';

@Component({
    selector: 'user-activity-list',
    template: require('./user-activity-list.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [ require('./user-activity-list.less') ]
})
export class UserActivityListComponent {
    @Input()
    public activities: UserActivity[];
}
