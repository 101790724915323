
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Error } from './error';
import { ResponseFactory } from '../response-factory';
import { Response } from '@angular/http';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

export class ErrorFactory {

    /**
     * Make Error.
     *
     * @returns {Error}
     */
    public static make<T>(): Error<T> {
        return new Error();
    }

    /**
     * Make Error from clean response.
     *
     * @returns {Error<T>}
     */
    public static from<T>(ngxResponse: Response): Error<T> {
        if (ngxResponse.status >= 500) {
            return ErrorFactory.make()
                .setStatusCode(ngxResponse.status)
                .setErrorMessage(ngxResponse.statusText);
        }

        const response = ResponseFactory.fromResponse(ngxResponse);

        return ErrorFactory.make()
            .setStatusCode(ngxResponse.status)
            .setErrorMessage(response.errorMessage)
            .setErrorCode(response.errorCode)
            .setErrors(response.errors);
    }

    public static throwObservable(ngxResponse: Response): ErrorObservable<any> {
        return observableThrowError(ErrorFactory.from(ngxResponse));
    }
}
