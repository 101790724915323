/*tslint:disable:max-line-length*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription ,  timer } from 'rxjs';
import { ENV } from '../../../../../../../apps/admin/src/app/enviroment';
import { DeliveryDistrict } from '../../../../interfaces/delivery-district';
import {
    IAcceptEditRangeEvent,
    IAcceptEditTimeEvent,
    IAcceptHurrierDelayEvent,
    IDeliveryTimesItem,
} from '../../../../interfaces/delivery-times';
import { DeliveryDistrictsService, DeliveryTimesService } from '../../../../services';
import { ModalDeliveryTimesHurrierDelayEditComponent } from '../../modals/modal-delivery-times-hurrier-delay-edit/modal-delivery-times-hurrier-delay-edit.component';
import { ModalDeliveryTimesRangeEditComponent } from '../../modals/modal-delivery-times-range-edit/modal-delivery-times-range-edit.component';
import { ModalDeliveryTimesTimeEditComponent } from '../../modals/modal-delivery-times-time-edit/modal-delivery-times-time-edit.component';

@Component({
    selector: 'delivery-times',
    template: require('./delivery-times.component.html'),
    styles: [require('./delivery-times.component.less')],
})
export class DeliveryTimesComponent implements OnInit, OnDestroy {
    public hasAcceptTimeTitle$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public hasClosedTitle$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public districtSettings$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public translations: any;
    public autoRefresh: Subscription;
    public autoRefreshInterval: number = 20000;
    public autoRefresh$: Observable<number>;
    public routeSettings: string[];
    private expandedSections: { time: boolean, distance: boolean, hurrier?: boolean };

    constructor(
        private deliveryTimesService: DeliveryTimesService,
        private deliveryDistrictsService: DeliveryDistrictsService,
        translate: TranslateService,
        private route: ActivatedRoute,
    ) {
        this.translations = {
            open: translate.instant('delivery.times.open'),
            viewSolo: translate.instant('delivery.times.link_text_view_solo'),
            viewAll: translate.instant('delivery.times.link_text_view_all'),
            closedMetersHeader: translate.instant('delivery.times.closed_meters_section_header'),
            acceptTimesHeader: translate.instant('delivery.times.accept_time_section_header'),
        };

        const { show } = route.snapshot.data;
        this.routeSettings = show;

        // #75 hide hurrier delay for glodny
        const isGlodny = ENV.APP_BRAND === 'Glodny';
        this.expandedSections = isGlodny
            ? { time: true, distance: true }
            : { time: false, distance: true, hurrier: true }
    }

    public get isSoloView(): boolean {
        return this.routeSettings.length === 1;
    }

    public isShowing(mode) {
        return this.routeSettings.indexOf(mode) > -1;
    }

    public ngOnInit(): void {
        this.autoRefresh$ = timer(0, this.autoRefreshInterval);
        this.autoRefresh = this.autoRefresh$.subscribe(() => {
            this.loadAll();
        });
    }

    public ngOnDestroy(): void {
        this.autoRefresh.unsubscribe();
    }

    public toggleSectionExpanded(section: string): void {
        if (!this.isSoloView) {
            this.expandedSections[section] = !this.expandedSections[section];
        }
    }

    public isSectionExpanded(section: string): boolean {
        return !!this.expandedSections[section];
    }

    public acceptTimeEdit(
        { item, formState }: IAcceptEditTimeEvent, modal: ModalDeliveryTimesTimeEditComponent,
    ): void {
        this.deliveryTimesService.updateAcceptTime(item.deliveryCompanyID, formState).subscribe(() => {
            this.loadAcceptTimeTitleCompanies();
            modal.close();
        });
    }

    public acceptRangeEdit(
        { item, formState }: IAcceptEditRangeEvent, modal: ModalDeliveryTimesRangeEditComponent,
    ): void {
        const { open, ...rest } = formState;
        const payload: Partial<IDeliveryTimesItem> = { closed: Number(!open), ...rest };

        this.deliveryTimesService.updateClosedMeters(item.deliveryCompanyID, payload).subscribe(() => {
            this.loadClosedTitleCompanies();
            modal.close();
        });
    }

    public acceptHurrierDelayEdit(
        { item, formState }: IAcceptHurrierDelayEvent, modal: ModalDeliveryTimesHurrierDelayEditComponent,
    ): void {
        const { customFleetMinutesDelay } = formState;
        this.deliveryDistrictsService.updateDistrictSettings(item.settings.dDistrictSettingsID, { customFleetMinutesDelay: +customFleetMinutesDelay })
            .subscribe(() => {
                this.loadDistrictSettings();
                modal.close();
            });
    }

    public deliveryCompanyTrackByFn(index: number, item: IDeliveryTimesItem): number {
        return item.deliveryCompanyID;
    }

    public deliveryDistrictTrackByFn(index: number, item: DeliveryDistrict): string {
        return item.deliveryDistrictID;
    }

    private loadAll(): void {
        this.loadAcceptTimeTitleCompanies();
        this.loadClosedTitleCompanies();
        this.loadDistrictSettings();
    }

    private loadAcceptTimeTitleCompanies(): void {
        const sorterFn = (a: IDeliveryTimesItem, b: IDeliveryTimesItem) => {
            return a.acceptTimeTitle.toLocaleLowerCase() > b.acceptTimeTitle.toLocaleLowerCase() ? 1 : -1;
        };

        this.deliveryTimesService.getAcceptTimeTitleCompanies().subscribe((response) => {
            this.hasAcceptTimeTitle$.next(response.sort(sorterFn));
        });
    }

    private loadClosedTitleCompanies(): void {
        const sorterFn = (a: IDeliveryTimesItem, b: IDeliveryTimesItem) => {
            return a.closedTitle.toLocaleLowerCase() > b.closedTitle.toLocaleLowerCase() ? 1 : -1;
        };

        this.deliveryTimesService.getClosedTitleCompanies().subscribe((response) => {
            this.hasClosedTitle$.next(response.sort(sorterFn));
        });
    }

    private loadDistrictSettings(): void {
        const sorterFn = (a: DeliveryDistrict, b: DeliveryDistrict) => {
            return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1;
        };

        this.deliveryDistrictsService.getDistricts({
            orderBy: 'name',
            sortOrder: 'asc',
            includes: ['settings'],
        }).subscribe((response) => {
            this.districtSettings$.next(response.sort(sorterFn));
        });
    }
}

/*tslint:enable:max-line-length*/
