import { Injectable } from '@angular/core';

export type Defaults = { [key: string]: string };

@Injectable()
export class DefaultTranslations {

    public defaults: Defaults = {};

    constructor(defaults: Defaults) {
        this.defaults = defaults;
    }
}
