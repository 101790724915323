import { Subscription } from 'rxjs';

export class SubscriptionCollection {

    /**
     * @type {Object}
     */
    protected subscriptions: { [key: string]: Subscription } = {};

    /**
     * Reset and set subscribers in collection
     *
     * @param subscriptions
     * @returns {SubscriptionCollection}
     */
    public setAll(subscriptions: { [key: string]: Subscription }): this {
        this.unsubscribeAll().subscriptions = subscriptions;

        return this;
    }

    /**
     * Unsubscribes using key and set subscription with key
     *
     * @param key
     * @param subscription
     * @returns {SubscriptionCollection}
     */
    public set(key: string, subscription: Subscription): this {
        this.unsubscribe(key)
            .setSubscription(key, subscription);

        return this;
    }

    /**
     * Unsubscribe all subscriptions
     *
     * @returns {SubscriptionCollection}
     */
    public unsubscribeAll(): this {
        Object.keys(this.subscriptions).forEach((key) => {
            this.unsubscribe(key);
        });

        return this;
    }

    /**
     * Unsubscribe one using key
     *
     * @param key
     * @returns {SubscriptionCollection}
     */
    public unsubscribe(key: string): this {
        if (this.subscriptions[key]) {
            this.subscriptions[key].unsubscribe();
        }

        return this;
    }

    /**
     * Set subscription in collection
     *
     * @param key
     * @param subscription
     * @returns {SubscriptionCollection}
     */
    protected setSubscription(key: string, subscription: Subscription): this {
        this.subscriptions[key] = subscription;

        return this;
    }
}
