/**
 * Calculate a bounding box in the format of [west, south, east, north]
 * in which each of the supplied coordinates is guaranteed to fit.
 * @param coordinates
 */
export const calculateBounds = (coordinates: number[][]): number[] => {
    let west = 180;
    let south = 90;
    let east = -180;
    let north = -90;

    for (const coordinate of coordinates) {
        const [lng, lat] = coordinate;

        if (lat < south) {
            south = lat;
        }

        if (lat > north) {
            north = lat;
        }

        if (lng < west) {
            west = lng;
        }

        if (lng > east) {
            east = lng;
        }
    }

    return [west, south, east, north];
};
