import { Injectable } from '@angular/core';
import { BaseService, FileResponse, FileResponseFactory, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryCompany } from '../interfaces';
import { ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';

export interface GetCompaniesOptions {
    perPage?: number;
    page?: number;
    search?: string;
}

export interface GetDriverSummariesOptions {
    from: string;
    to: string;
}

@Injectable()
export class DeliveryCompanyService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCompanies(options: GetCompaniesOptions): Observable<DeliveryCompany[]> {
        const search = ParamsUtil.toHttpParams(options);
        return this.get(`${this.hungrigClient.baseUrl}/delivery/companies/paginate`, { search });
    }

    public getDriverSummaries(deliveryCompanyID: number, options: GetDriverSummariesOptions): Observable<FileResponse> {
        const search = ParamsUtil.toHttpParams(options);

        const url = `${this.hungrigClient.baseUrl}/delivery/companies/${deliveryCompanyID}/driver-summaries`;

        return this.hungrigClient.get(url, {
            search,
            responseType: ResponseContentType.Blob,
        })
            .pipe(map(FileResponseFactory.fromResponse));
    }

    /**
     * Connects a company deliveryCompanyID from deliveryDistrictID.
     * @param deliveryCompanyID
     * @param deliveryDistrictID
     */
    public connectToDistrict(deliveryCompanyID: number, deliveryDistrictID: string): Observable<DeliveryCompany> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies/${deliveryCompanyID}`;

        return this.put(url, { deliveryDistrictID });
    }

    /**
     * Disconnects a company from any districts.
     * This assumes a company can only be connected to a single district at a time.
     * @param deliveryCompanyID
     */
    public disconnectFromDistrict(deliveryCompanyID: number): Observable<DeliveryCompany> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies/${deliveryCompanyID}`;

        return this.put(url, { deliveryDistrictID: null });
    }

    /**
     * Gets all companies connected to a given district.
     * @param deliveryDistrictID
     */
    public getConnectedToDistrict(deliveryDistrictID: string): Observable<DeliveryCompany[]> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies`;
        const search = ParamsUtil.toHttpParams({ deliveryDistrictID });

        return this.get(url, { search });
    }
}
