
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, ErrorFactory, HungrigClient, Paginate, PaginateFactory, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { PushNotification } from './push-notification';

@Injectable()
export class PushNotificationService extends BaseService {
    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = this.hungrigClient.baseUrl;
    }

    public getNotifications(perPage: number = 15, page: number = 1): Observable<Paginate<PushNotification[]>> {
        const params = ParamsUtil.toHttpParams({ perPage, page });
        return this.hungrigClient.get(this.url('/rocms/pushnotifications'), { search: params }).pipe(
            map(PaginateFactory.from),
            catchError(ErrorFactory.throwObservable));
    }

    public send(notification: { os: string, message: string }): Observable<boolean> {
        return this.post('/rocms/pushnotifications', notification);
    }
}
