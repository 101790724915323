import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageOrderBase } from '../../models/manage-order-base.models';

@Component({
    selector: 'order-list',
    template: require('./order-list.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListComponent {
    @Input() public orders: ManageOrderBase[] = [];
    @Input() public selectedOrderID: number;
    @Input() public hoveredOrderIDs: number[] = [];
    @Input() public loading: boolean;
    @Output() public selectOrder: EventEmitter<ManageOrderBase> = new EventEmitter();
    @Output() public hoverOrder: EventEmitter<ManageOrderBase> = new EventEmitter();

    public isHovered(order: ManageOrderBase): boolean {
        return this.hoveredOrderIDs.indexOf(order.deliveryOrderID) > -1
    }
}
