import { Component, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogComponent } from '../dialog.component';
import { NotifyDialog } from './notify-dialog';

@Component({
    selector: 'notify-dialog',
    template: `
        <div bsModal [config]="{ backdrop: 'static', keyboard: false }" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">{{ dialog.options.title }}</h4>
                    </div>
                    <div *ngIf="dialog.options.body" [innerHTML]="dialog.options.body" class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button (click)="ok()" class="btn btn-success">{{ dialog.options.ok || ('general.ok' | translate) }}</button>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class NotifyDialogComponent implements AfterViewInit, DialogComponent {
    @Input() public dialog: NotifyDialog;

    @ViewChild(ModalDirective) public modal: ModalDirective;

    public ok(): void {
        this.dialog.complete();
    }

    public ngAfterViewInit(): void {
        this.modal.show();
    }

}
