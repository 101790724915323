import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageOrderDistrict } from '../../models/district.models';
import { ManageOrderDriver } from '../../models/manage-order-driver.models';

@Component({
    selector: 'driver-list',
    template: require('./driver-list.component.html'),
    styles: [require('./driver-list.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverListComponent {
    @Input() public drivers: ManageOrderDriver[] = [];
    @Input() public district: ManageOrderDistrict;
    @Input() public selectedID: number;
    @Input() public loading: boolean;
    @Input() public hoveredOrderIDs: number[] = [];
    @Output() public selectDriver: EventEmitter<ManageOrderDriver> = new EventEmitter();
    @Output() public hoverDriver: EventEmitter<ManageOrderDriver> = new EventEmitter();
    @Output() public hoverOrder: EventEmitter<number> = new EventEmitter();

    public driverTrackFn(index: number, driver: ManageOrderDriver): number {
        return driver.deliveryDriverID;
    }

    public handleSelectDriver(driver: ManageOrderDriver): void {
        this.selectDriver.emit(driver);
    }

    public handleHoverDriver(driver: ManageOrderDriver): void {
        this.hoverDriver.emit(driver);
    }

    public handleHoverOrder(deliveryOrderID: number): void {
        this.hoverOrder.emit(deliveryOrderID);
    }
}
