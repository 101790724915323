import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Order } from './interfaces';
import { DeliveryType } from './enums';
import { OrderSummary } from './order-summary';
import { OrderTabCartConfig } from './order-tab-cart-config';

export interface CustomCartRow {
    pcs?: number;
    pricePerPcs?: number;
    title: string;
    totalWithoutDeliveryAdd?: number;
    deliveryAdd?: number;
    totalWithDeliveryAdd?: number;
    total?: number;
    isSummaryRow?: boolean;
}

@Component({
    selector: 'order-tab-cart',
    template: require('./order-tab-cart.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTabCartComponent implements OnInit {
    @Input() public order: Order;
    @Input() public customRows: CustomCartRow[] = [];
    @Input() public displayDelivery: boolean;
    public cartSummaryRowTotal: number = 0;

    public deliveryType: typeof DeliveryType = DeliveryType;
    public settings: OrderTabCartConfig;
    public summary: OrderSummary;
    public deliveryTypeTranslations: { [key: string]: string };

    constructor(protected orderTabCartConfig: OrderTabCartConfig) {
        this.settings = orderTabCartConfig;
        this.deliveryTypeTranslations = {
            [DeliveryType.PICKUP]: 'order.delivery_type.pickup',
            [DeliveryType.DELIVERY]: 'order.delivery_type.delivery',
            [DeliveryType.DELIVERY_PREMIUM]: 'order.delivery_type.delivery_premium',
        };
    }

    public ngOnInit(): void {
        this.summary = OrderSummary.fromOrder(this.order);
        this.cartSummaryRowTotal = this.getCartSummaryRowTotal();
    }

    protected getCartSummaryRowTotal(): number {
        if (this.settings.displayRestricted) {
            return this.summary.totalPrice;
        }

        if (this.displayDelivery) {
            return this.order.totalWorth - this.order.couponValue;
        }

        return this.order.totalFood - this.order.couponValue;
    }
}
