import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CompensationCalculatorService, CompensationService, CompensationSummaryService,
    CompensationUtilService
} from './services';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyModule, RoNg2Module } from '@ro-ngx/core';
import {
    CompensationCreditTableComponent,
    CompensationDebitTableComponent,
    CompensationCreditFormComponent,
    CompensationDebitFormComponent
} from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { CompensationsConfig } from './compensations-config';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeliveryModule } from '@ro-ngx/delivery';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        CurrencyModule,
        ReactiveFormsModule,
        BsDropdownModule,
        DeliveryModule,
        RoNg2Module
    ],
    exports: [
        CompensationCreditTableComponent,
        CompensationDebitTableComponent,
        CompensationCreditFormComponent,
        CompensationDebitFormComponent
    ],
    declarations: [
        CompensationCreditTableComponent,
        CompensationDebitTableComponent,
        CompensationCreditFormComponent,
        CompensationDebitFormComponent
    ],
    providers: [],
})
export class CompensationsModule {
    public static forRoot(config: CompensationsConfig): ModuleWithProviders {
        return {
            ngModule: CompensationsModule,
            providers: [
                CompensationService,
                CompensationSummaryService,
                CompensationUtilService,
                CompensationCalculatorService,
                { provide: CompensationsConfig, useValue: config }
            ]
        };
    }
}
