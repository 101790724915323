
import {filter, takeUntil, tap} from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MultipleSelect } from './mutliple-select';
import {fromEvent, Observable, Subject} from 'rxjs';
import { Key } from 'ts-keycode-enum';

@Component({
    selector: 'multiple-select',
    template: `
        <div [ngClass]="wrapperClass + ' ' + additionalWrapperClass">
            <div class="selected-items-container" *ngIf="multipleSelect.canSelectMultiple()">
                <a href class="btn btn-default btn-secondary btn-xs"
                   *ngFor="let item of selectedItems; trackBy: trackItem"
                   (click)="removeItem($event, item)">
                    {{ item[multipleSelect.options.itemKeyVisible] }}&nbsp;&nbsp;×
                </a>
            </div>
            <input type="text" #searchText class="keep-dropdown-open">
            <span class="multiple-select-loading" *ngIf="multipleSelect.loading">
                <i class="fa fa-cog fa-spin" aria-hidden="true"></i>
            </span>
            <div class="dropdown keep-dropdown-open open"
                 [class.open]="multipleSelect.showDropdown && dropdownItems.length > 0 && ! multipleSelect.loading">
                <div class="dropdown-menu keep-dropdown-open">
                    <li *ngFor="let item of dropdownItems; trackBy: trackItem; let i = index;" 
                        class="keep-dropdown-open"
                        [class.dropdown-item-focus]="focusCursor === i">
                        <a href class="keep-dropdown-open"
                            (click)="selectItem($event, item)">
                            {{ item[multipleSelect.options.itemKeyVisible] }}
                        </a>
                    </li>
                </div>
            </div>
        </div>
    `
})
export class MultipleSelectComponent implements OnInit, OnDestroy {

    /**
     * @type {ElementRef}
     */
    @ViewChild('searchText',{static:true})
    public searchText: ElementRef;

    /**
     * @type {MultipleSelect}
     */
    @Input()
    public multipleSelect: MultipleSelect;

    /**
     * @type {Array}
     */
    public dropdownItems: any[] = [];

    /**
     * @type {*}
     */
    public focusCursor: number;

    /**
     * @type {string}
     */
    @Input()
    public wrapperClass: string = 'form-control ro-select multiple';

    /**
     * @type {string}
     */
    @Input()
    public additionalWrapperClass: string = '';

    /**
     * @type {Array}
     */
    public selectedItems: any[] = [];

    /**
     * Record destruction off component.
     *
     * @type {Subject<void>}
     */
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        if ( ! this.multipleSelect) {
            throw new Error('MultipleSelectComponent requires "multipleSelect" as input.');
        }

        this.multipleSelect.selectedItems$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((items) => this.selectedItems = items);

        if (this.multipleSelect.canSelectMultiple() && this.multipleSelect.options.placeholder) {
            this.searchText.nativeElement.placeholder = this.multipleSelect.options.placeholder;
        }

        if ( ! this.multipleSelect.canSelectMultiple()) {
            this.multipleSelect.selectedItems$.pipe(
                takeUntil(this.ngUnsubscribe),
                filter((items) => typeof items !== 'undefined' && items.length > 0),)
                .subscribe((items) => {
                    this.searchText.nativeElement.value = items[0][this.multipleSelect.options.itemKeyVisible];
                    this.searchText.nativeElement.placeholder = items[0][this.multipleSelect.options.itemKeyVisible];
                });
        }
        else {
            this.multipleSelect.selectedItems$.pipe(
                takeUntil(this.ngUnsubscribe),
                filter((items) => typeof items !== 'undefined' && items.length > 0),)
                .subscribe(() => this.searchText.nativeElement.value = '');
        }

        this.subscribeToItems()
            .subscribeToUserActions();
    }

    /**
     * NgOnDestroy
     */
    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * Add item to selection.
     *
     * @param event
     * @param item
     */
    public selectItem(event, item) {
        event.preventDefault();
        this.multipleSelect.selectItem(item);
    }

    /**
     * Remove item from selection.
     *
     * @param event
     * @param item
     */
    public removeItem(event, item) {
        event.preventDefault();
        this.multipleSelect.removeItem(item);
    }

    public reset(items: any[] = []) {
        this.multipleSelect.reset(items);
    }

    public focusNext(): void {
        if (typeof this.focusCursor === 'undefined') {
            this.focusCursor = 0;
        }
        else if (this.dropdownItems.length > this.focusCursor) {
            this.focusCursor++;
        }

        this.multipleSelect.openDropdown();
    }

    public focusPrevious(): void {
        if (typeof this.focusCursor === 'undefined') {
            this.focusCursor = 0;
        }
        else if (this.focusCursor > 0) {
            this.focusCursor--;
        }
        else if (this.focusCursor === 0) {
            this.focusCursor = undefined;
            this.multipleSelect.closeDropdown();
        }
    }

    /**
     * Help angular track item with id.
     *
     * @param index
     * @param item
     * @returns {number}
     */
    public trackItem(index: number, item: any): any {
        return item && this.multipleSelect ? item[this.multipleSelect.options.itemKeyId] : undefined;
    }

    /**
     * Subscribe to new items from multiple select instance.
     *
     * @returns {MultipleSelectComponent}
     */
    protected subscribeToItems(): this {
        this.multipleSelect.dropdownItems$.pipe(
            takeUntil(this.ngUnsubscribe),
            tap(() => this.focusCursor = undefined))
            .subscribe((items) => this.dropdownItems = items);

        return this;
    }

    /**
     * Subscribe to user inputs.
     *
     * @returns {MultipleSelectComponent}
     */
    protected subscribeToUserActions(): this {
            fromEvent(this.searchText.nativeElement, 'keyup').pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((keyboardEvent: KeyboardEvent) => {
                keyboardEvent.preventDefault();

                switch (keyboardEvent.keyCode) {
                    case Key.UpArrow:
                        this.focusPrevious();
                        break;
                    case Key.DownArrow:
                        this.focusNext();
                        break;
                    case Key.Enter:
                        if (typeof this.focusCursor !== 'undefined') {
                            this.multipleSelect.selectItem(this.dropdownItems[this.focusCursor]);
                        }
                        break;
                    case Key.Escape:
                        this.multipleSelect.closeDropdown();
                        break;
                    default:
                        this.multipleSelect.search(this.searchText.nativeElement.value);
                }
            });

            fromEvent(this.searchText.nativeElement, 'focus').pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => {
                if (! this.multipleSelect.canSelectMultiple()) {
                    this.searchText.nativeElement.value = '';
                }
                this.multipleSelect.openDropdown();
            });
            ;

        return this;
    }
}
