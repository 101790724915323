import { DeliveryType, Order } from '@ro-ngx/orders';
import { Debtor } from '../../enums';
import { DebtorOption } from './options';

export const getDebtorOptions = (order: Order): DebtorOption[] => {
    if (order.deliveryType === DeliveryType.Pickup) {
        return [
            {
                debtor: Debtor.RESTAURANT,
                translateKey: 'order_compensation.debtor.restaurant'
            },
            {
                debtor: Debtor.HUNGRIG,
                translateKey: 'general.creatives'
            }
        ];
    } else {
        const options = [
            {
                debtor: Debtor.RESTAURANT,
                translateKey: 'order_compensation.debtor.restaurant'
            },
            {
                debtor: Debtor.DELIVERY_COMPANY,
                translateKey: 'order_compensation.debtor.delivery_company'
            },
            {
                debtor: Debtor.HUNGRIG,
                translateKey: 'general.brand'
            }
        ];

        if (order.deliveryorder.deliverydriver) {
            const index = 2;
            options.splice(index, 0, {
                debtor: Debtor.DELIVERY_DRIVER,
                translateKey: 'order_compensation.debtor.delivery_driver'
            });
        }

        return options;
    }
};
