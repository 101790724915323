import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { User as UserContract } from '../interfaces/user';
import { map } from 'rxjs/operators';
import { User } from '../user';

export interface FindMyselfOptions {
    includes?: string[];
}

export interface FindUserOptions {
    includes?: string[];
}

export interface UpdateUserOptions extends User {
    password: string;
}

@Injectable()
export class UserService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }

    public findMyself(options: FindMyselfOptions = {}): Observable<UserContract> {
        return this.get(`/user/myself`, {
            search: ParamsUtil.toHttpParams(options)
        }).pipe(map((user) => new User(user)));
    }

    public findUser(userID: number, options: FindUserOptions = {}): Observable<UserContract> {
        return this.get(`/user/${userID}`, {
            search: ParamsUtil.toHttpParams(options)
        });
    }

    public updateUser(userID: number, options: Partial<UpdateUserOptions>): Observable<UserContract> {
        return this.post(`/user/${userID}`, options);
    }
}
