import { ModuleWithProviders, NgModule } from '@angular/core';

import { DeliveryDirectionsComponent } from './delivery-directions.component';
import { CommonModule } from '@angular/common';
import { DeliveryDirectionsModuleConfig } from './delivery-directions-module-config';

@NgModule({
    imports: [ CommonModule ],
    exports: [ DeliveryDirectionsComponent ],
    declarations: [ DeliveryDirectionsComponent ],
    providers: [],
    entryComponents: [ DeliveryDirectionsComponent ]
})
export class DeliveryDirectionsModule {
    public static forRoot(config: DeliveryDirectionsModuleConfig): ModuleWithProviders {
        return {
            ngModule: DeliveryDirectionsModule,
            providers: [
                { provide: DeliveryDirectionsModuleConfig, useValue: config }
            ]
        };
    }
}
