import { Observable } from 'rxjs';

export interface HungrigResponseArgs {
    data: any | any[];
    meta: any | any[];
    error: boolean;
    errors: any[];
    // tslint:disable
    error_code: number;
    // tslint:disable
    error_message: string;
}

/**
 * @deprecated Use Response from '@ro-ng2' instead.
 */
export class HungrigResponse {

    public data: any | any[] = {};

    public meta: any | any[] = {};

    public error: boolean = false;

    public errors: any[] = null;

    // tslint:disable
    public error_code: number = 0;

    // tslint:disable
    public error_message: string = '';

    constructor(hungrigResponse: HungrigResponseArgs) {
        this.data = hungrigResponse.data;
        this.meta = hungrigResponse.meta;
        this.error = hungrigResponse.error;
        this.errors = hungrigResponse.errors ? hungrigResponse.errors : null;
        this.error_code = hungrigResponse.error_code;
        this.error_message = hungrigResponse.error_message;
    }

    public static fromHungrigRequest(response): HungrigResponse {
        return HungrigResponse.fromResponse(response);
    }

    public static fromResponse(response): HungrigResponse {
        let body = response.json();

        return new HungrigResponse(body);
    }

    public static fromHungrigRequestToError(response): any {
        let hungrigResponse = HungrigResponse.fromHungrigRequest(response);

        return Observable.throw(hungrigResponse);
    }
}
