import { URLSearchParams } from '@angular/http';
import { QueryEncoder } from './query-encoder';

export class ParamsUtil {
    public static query(params: {[key: string]: any}): string {
        return Object.keys(params)
            .map((key) => {
                if (Array.isArray(params[key])) {
                    return params[key]
                        .map((value) => key + '[]=' + value)
                        .join('&');
                }

                return key + '=' + params[key];
            })
            .join('&');
    }

    public static toHttpParams(
        params: {[key: string]: any},
        queryEncoder: QueryEncoder = new QueryEncoder()
    ): URLSearchParams {
        const httpParams: URLSearchParams = new URLSearchParams(undefined, queryEncoder);
        Object.keys(params)
            .filter((key) => params.hasOwnProperty(key))
            .forEach((key) => {
                if (Array.isArray(params[key])) {
                    params[key].map((value) => this.stringValueOf(value))
                        .forEach((value) => httpParams.append(`${key}[]`, value));
                } else {
                    httpParams.set(key, this.stringValueOf(params[key]));
                }
            });
        return httpParams;
    }

    public static stringValueOf(value: any): string {
        switch (typeof value) {
            case 'number':
                return value.toString();
            case 'boolean':
                return value ? '1' : '0';
            case 'string':
                return value;
            default:
                return undefined;
        }
    }
}
