
import {map, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HungrigClient, Translator } from '@ro-ngx/core';
import { ActivatedRoute, RouteConfigLoadEnd, Router } from '@angular/router';
import { Env } from './enviroment';
import { Observable } from 'rxjs';
import { get } from 'lodash';
import { AuthenticationTokenStorage, AuthorizationInterceptor } from '@ro-ngx/authentication';
import { UserStorage } from '@ro-ngx/users';

@Component({
    selector: 'app',
    template: require('./app.component.html')
})
export class AppComponent implements OnInit {

    /**
     * @type {boolean}
     */
    public displayNavbar$: Observable<boolean>;

    constructor(
                protected authenticationTokenStorage: AuthenticationTokenStorage,
                protected authorizationInterceptor: AuthorizationInterceptor,
                protected userStorage: UserStorage,
                protected hungrigClient: HungrigClient,
                protected translate: TranslateService,
                protected route: ActivatedRoute,
                protected router: Router) {

        const translator = new Translator();

        translator.fromWebpackContext(require['context']('../resources/i18n', true, /^\.\/.*\.json$/));
        translator.fromWebpackContext(require['context']('@ro-ngx/core/src/i18n', true, /^\.\/.*\.json$/));

        Object.keys(translator.translations).forEach((key) => {
            translate.setTranslation(key, translator.translations[key]);
        });
        translate.addLangs(['en','sv'])
        translate.setDefaultLang('en');
        translate.use(localStorage.getItem('language'))
    }

    public ngOnInit(): void {
        this.authenticationTokenStorage.authorizationToken$
            .subscribe((token) => {
                if (token) {
                    const header = this.authenticationTokenStorage.toHeader(token);
                    this.hungrigClient.requestOptions.headers.set(header.key, header.value);
                }
            });

        this.hungrigClient.addResponseHook(
            (response) => this.authorizationInterceptor.handleResponse(response)
        );

        this.displayNavbar$ = this.router.events.pipe(
            filter((event) => event instanceof RouteConfigLoadEnd),
            map((config: RouteConfigLoadEnd) => get(config, 'route.data.displayNavbar', true)),
            map((displayNavbar: boolean) => Env.IFRAMED
                || window.location.search.includes('preventNavbar')
                ? false : displayNavbar),);

    }
}
