
import {first, map,  finalize } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CompensationStep } from '../enums';
import {Observable, BehaviorSubject, forkJoin} from 'rxjs';
import { Order, OrderRow } from '@ro-ngx/orders';
import { InputType } from '../../../enums';
import { CompensationCalculatorState } from '../compensation-calculator.state';
import { CompensationCalculator } from '../../../interfaces';
import { CompensationCalculatorService } from '../../../services';
import { flatten } from 'lodash';
import { CartRowForm } from '../cart-input';


const CUSTOM_CREDIT_OPTION: CompensationCalculator = {
    id: '_custom_credit',
    title: 'Anpassad kompensering',
    inputs: [
        { type: 'text', label: 'Orsak', id: 'reason' }
    ]
};

const CUSTOM_DEBIT_OPTION: CompensationCalculator = {
    id: '_custom_debit',
    title: 'Anpassad debitering',
    inputs: [
        { type: 'text', label: 'Orsak', id: 'reason' }
    ]
};

@Component({
    selector: 'configure-calculator',
    template: require('./configure-calculator.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigureCalculatorComponent implements OnInit {
    public calculatorOptions: CompensationCalculator[];

    public entries: {
        calculator: CompensationCalculator,
        calculatorControl: FormControl,
        form: FormGroup
    }[] = [];

    @Input()
    public order: Order;

    public InputType: typeof InputType = InputType;

    public isLoading$: BehaviorSubject<boolean>;

    constructor(
        protected calculatorService: CompensationCalculatorService,
        protected state: CompensationCalculatorState,
        protected formBuilder: FormBuilder,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.isLoading$ = new BehaviorSubject<boolean>(true);

        forkJoin(this.getCalculatorOptions(), this.state.calculators$.pipe(first()))
            .pipe(finalize(() => this.isLoading$.next(false)))
            .subscribe(([options, calculators]) => {
                this.calculatorOptions = options;

                if (calculators.length === 0) {
                    this.addCalculator();
                    this.changeDetectorRef.markForCheck();
                    return;
                }

                for (const calculatorInput of calculators) {
                    const calculator = options.find((option) => option.id === calculatorInput.id);

                    const form = this.formBuilder.group({
                        ...Object.keys(calculatorInput.input).reduce((group, key) => {
                            group[key] = new FormControl(calculatorInput.input[key]);
                            return group;
                        }, {})
                    });

                    this.addCalculator(calculator, form);
                }

                this.changeDetectorRef.markForCheck();
            });
    }

    public nextStep(): void {
        const calculators = [];
        const customCredits = [];
        const customDebits = [];

        for (const entry of this.entries) {
            if (entry.calculator.id === '_custom_credit') {
                const reason = entry.form.get('reason').value;

                customCredits.push({
                    reason,
                    value: null,
                    valueType: null,
                    refundType: null
                });
            } else if (entry.calculator.id === '_custom_debit') {
                const reason = entry.form.get('reason').value;

                customDebits.push({
                    reason,
                    summaryReason: reason,
                    amount: null,
                    debtor: null,
                    debtorID: null,
                    orderRows: []
                });
            } else {
                calculators.push({
                    id: entry.calculator.id,
                    title: entry.calculator.title,
                    input: entry.form.value
                });
            }
        }

        this.state.setCustomCredits(customCredits);
        this.state.setCustomDebits(customDebits);
        this.state.setCalculators(calculators);
        this.state.completeStep(CompensationStep.ConfigureCalculator);
        this.state.setStep(CompensationStep.ConfigureCompensation);
    }

    public close(): void {
        this.state.close();
    }

    protected getCalculatorOptions(): Observable<CompensationCalculator[]> {
        return this.calculatorService.getCalculators(this.order.orderID).pipe(
            map((calculatorOptions) => {
                calculatorOptions.push(CUSTOM_CREDIT_OPTION);
                calculatorOptions.push(CUSTOM_DEBIT_OPTION);
                return calculatorOptions;
            }));
    }

    protected addCalculator(
        calculator?: CompensationCalculator,
        form?: FormGroup
    ): void {
        const calculatorControl = new FormControl(calculator);

        const entry = {
            calculatorControl,
            calculator: calculator || null,
            form: form || null
        };

        calculatorControl.valueChanges.subscribe((selectedCalculator) => {
            entry.calculator = selectedCalculator;
            entry.form = this.formBuilder.group({
                ...selectedCalculator.inputs.reduce((group, input) => {
                    group[input.id] = new FormControl(this.getCalculatorInputValue(input.type, input.default));
                    return group;
                }, {})
            });
        });

        this.entries.push(entry);
    }

    protected getCalculatorInputValue(inputType: InputType, defaultValue?: any): any {
        if (typeof defaultValue !== 'undefined') {
            return defaultValue;
        }

        switch (inputType) {
            case InputType.CART:
                return this.getCalculatorCartInput();
            default:
                return null;
        }
    }

    protected getOrderRowFormData(orderRow: OrderRow): CartRowForm {
        return {
            rowID: orderRow.rowID,
            quantity: 0,
            price: orderRow.price,
            amount: orderRow.price + orderRow.deliveryPriceAdd,
            deliveryPriceAdd: orderRow.deliveryPriceAdd,
            taxRate: orderRow.taxRate,
            title: orderRow.title
        };
    }

    protected getCalculatorCartInput(): CartRowForm[] {
        const orderRows = this.order.orderrows
            .map((orderRow) => {
                if (orderRow.extras.length > 0) {
                    return [
                        this.getOrderRowFormData(orderRow),
                        ...orderRow.extras.map((extra) => this.getOrderRowFormData(extra))
                    ];
                }

                return this.getOrderRowFormData(orderRow);
            });

        return flatten(orderRows);
    }
}
