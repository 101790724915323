import { NgModule } from '@angular/core';
import { CompensationCalculatorComponent } from './compensation-calculator.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigureCalculatorComponent } from './configure-calculator';
import { ConfigureCompensationComponent } from './configure-compensation';
import { ConfigureCustomCompensationComponent } from './configure-custom-compensation';
import { ConfirmCompensationComponent } from './confirm-compensation';
import { CurrencyModule, RoNg2Module } from '@ro-ngx/core';
import { CartInputComponent } from './cart-input';
import { CartRowInputComponent } from './cart-input/cart-row-input';
import { ConfirmCompensationDebitsComponent } from './confirm-compensation/confirm-compensation-debits';
import { ConfirmCompensationCreditsComponent } from './confirm-compensation/confirm-compensation-credits';
import { ConfigureCustomCreditComponent } from './configure-compensation/configure-custom-credit';
import { ConfigureCustomDebitComponent } from './configure-compensation/configure-custom-debit';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        RoNg2Module,
        CurrencyModule
    ],
    exports: [
        CompensationCalculatorComponent
    ],
    declarations: [
        CompensationCalculatorComponent,
        ConfigureCalculatorComponent,
        ConfigureCompensationComponent,
        ConfigureCustomCreditComponent,
        ConfigureCustomDebitComponent,
        ConfigureCustomCompensationComponent,
        ConfirmCompensationComponent,
        ConfirmCompensationDebitsComponent,
        ConfirmCompensationCreditsComponent,
        CartInputComponent,
        CartRowInputComponent
    ],
    providers: [],
})
export class CompensationCalculatorModule {
}
