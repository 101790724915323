import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  of } from 'rxjs';
import { FindMyselfOptions, UserService } from './user.service';
import { map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { User } from '../interfaces';
import { Restaurant, RestaurantService } from '@ro-ngx/restaurants';
import { UserConnectionType } from '../enums';

@Injectable()
export class UserStorage {

    public myself$: Observable<User>;
    protected myself: BehaviorSubject<User>;
    protected _restaurantConnections$: Observable<Restaurant[]>;

    constructor(
        protected userService: UserService,
        protected restaurantService: RestaurantService
    ) {
        this.myself = new BehaviorSubject(null);
        this.myself$ = this.myself.asObservable();
    }

    public findMyself(options?: FindMyselfOptions): Observable<User> {
        return this.userService.findMyself(options)
            .pipe(
                share(),
                tap((resource) => this.myself.next(resource))
            );
    }

    public clearMyself(): void {
        this.myself.next(null);
    }

    public get restaurantConnections$(): Observable<Restaurant[]> {
        if (! this._restaurantConnections$) {
            this._restaurantConnections$ = this.myself$
                .pipe(
                    map((user) => user === null ? [] : user.getUserConnectionType(UserConnectionType.RESTAURANT)),
                    map((connections) => connections.map((connection) => connection.clientKey)),
                    switchMap((clientKeys) => {
                        if (clientKeys.length === 0) {
                            return of([]);
                        }

                        return this.restaurantService.getRestaurants({
                            clientKey: clientKeys,
                            includes: [ 'financeSettings' ]
                        });
                    }),
                    shareReplay(1)
                );
        }

        return this._restaurantConnections$;
    }
}
