import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
    IAcceptEditRangeEvent,
    IDeliveryTimesItem,
    IDeliveryTimesRangeFormState
} from "../../../../interfaces/delivery-times";
import { FormBuilder, FormGroup } from "@angular/forms";
import { generateRange } from "../../utils";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: 'modal-delivery-times-range-edit',
    template: require('./modal-delivery-times-range-edit.component.html'),
    styles: [require('./modal-delivery-times-range-edit.component.less')],
})
export class ModalDeliveryTimesRangeEditComponent implements OnInit, OnDestroy {
    @ViewChild('modal')
    public modal: ModalDirective;

    @Output()
    public ok: EventEmitter<IAcceptEditRangeEvent> = new EventEmitter();

    public item: IDeliveryTimesItem;
    public form: FormGroup;

    public meterRanges: number[];
    public translations: {[key: string]: string};
    private subscription: Subscription;

    constructor(
        fb: FormBuilder,
        translate: TranslateService,
    ) {
        this.form = fb.group({
            open: false,
            closedMeters: null,
            closedMessage: '',
        });

        this.meterRanges = (() => {
            const STARTAT = 0;
            const ENDAT = 15000;
            const STEP = 500;
            return generateRange(STARTAT, ENDAT, STEP);
        })();

        this.translations = {
            defaultMessage: translate.instant('delivery.times.closed_meters_modal.closed_message_default'),
            open: translate.instant('delivery.times.open'),
        };
    }

    public ngOnInit(): void {
        this.subscription = this.form.get('open').valueChanges.subscribe(
            (checked) => {
                const method = checked ? 'disable' : 'enable';
                this.form.get('closedMeters')[method]();
                this.form.get('closedMessage')[method]();
            }
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public open(item: IDeliveryTimesItem): void {
        this.item = item;

        const {closedMeters, closedMessage, closed} = item;
        this.form.setValue({
            open: !closed,
            closedMeters,
            closedMessage: closedMessage || this.translations.defaultMessage,
        });
        this.modal.show();
    }

    public close(): void {
        this.modal.hide();
    }

    public complete(): void {
        if (this.form.valid) {
            this.ok.emit({formState: this.form.value as IDeliveryTimesRangeFormState, item: this.item});
        }
    }

    public displayRange(range: number): string {
        return `${range} m`;
    }
}
