import { AuthenticationToken } from '../interfaces';
import { BehaviorSubject ,  Observable ,  fromEvent } from 'rxjs';
import { Injectable } from "@angular/core";
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthenticationTokenStorage {

    public authorizationToken$: Observable<AuthenticationToken>;

    protected set authorizationToken(token: AuthenticationToken) {
        if (token) {
            localStorage.setItem(this.storageKey, JSON.stringify(token));
        }
        else {
            localStorage.removeItem(this.storageKey);
        }
    }

    protected get authorizationToken(): AuthenticationToken {
        const token = localStorage.getItem(this.storageKey);

        return token ? JSON.parse(token) : undefined;
    }

    protected authorizationTokenSubject: BehaviorSubject<AuthenticationToken>;

    protected storageKey: string;

    constructor() {
        this.storageKey = 'authentication.token';

        this.authorizationTokenSubject = new BehaviorSubject<AuthenticationToken>(this.getAuthorizationToken());
        this.authorizationToken$ = this.authorizationTokenSubject.asObservable();

        fromEvent(window, 'storage')
            .pipe(filter((storageEvent: StorageEvent) => storageEvent.key === this.storageKey || ! storageEvent.key))
            .subscribe(() => this.authorizationTokenSubject.next(this.authorizationToken));
    }

    public getAuthorizationToken(): AuthenticationToken {
        return this.authorizationToken;
    }

    public setAuthorizationToken(token: AuthenticationToken): void {
        this.authorizationToken = token;
        this.authorizationTokenSubject.next(token);
    }

    public hasAuthorizationToken(): boolean {
        return !! this.getAuthorizationToken();
    }

    public toHeader(token: AuthenticationToken): { key: string, value: string } {
        return {
            key: 'Authorization',
            value: `${token.token_type} ${token.access_token}`
        };
    }
}
