import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryDistrict, DistrictSettings } from '../interfaces/delivery-district';
import { ManageOrderDistrictParams } from '../modules/delivery-manage/models/request.models';

@Injectable()
export class DeliveryDistrictsService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = `${hungrigClient.baseUrl}/delivery/`;
    }

    public getDistricts({ orderBy, sortOrder, includes }: ManageOrderDistrictParams): Observable<DeliveryDistrict[]> {
        const search = ParamsUtil.toHttpParams({
            orderBy, sortOrder, includes,
        });

        return this.get('district', { search });
    }

    public getDistrict(deliveryDistrictID: string): Observable<DeliveryDistrict> {
        return this.get(`district/${deliveryDistrictID}`);
    }

    public createDistrict(district: DeliveryDistrict): Observable<any> {
        return this.post('district', district);
    }

    public deleteDistrict(id: string): Observable<any> {
        return this.delete(`district/${id}`);
    }

    public updateDistrict(id: string, district: DeliveryDistrict): Observable<any> {
        return this.put(`district/${id}`, district);
    }

    public getDistrictSettings(id: number): Observable<DistrictSettings> {
        return this.get(`district/${id}/settings`);
    }

    public updateDistrictSettings(id: number, settings: Partial<DistrictSettings>): Observable<any> {
        return this.put(`district/settings/${id}`, settings);
    }
}
