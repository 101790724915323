import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ManageOrderStatus } from '../../models/manage-order-status.models';
import {ManageOrderWithCart} from '../../models/ManageOrderWithCart.models'

@Component({
    selector: 'order-detail',
    template: require('./order-detail.component.html'),
    styles: [require('./order-detail.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailComponent {
    @Input() public order: ManageOrderWithCart;

    @HostBinding('class.assigned')
    get isAssigned(): boolean {
        return this.order && this.order.status === ManageOrderStatus.Confirmed;
    }
}
