import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from './notification';
import { NotificationType } from './notification-type';
import { NotificationInstance } from './notification-instance';

@Injectable()
export class NotificationStorage {

    public loadingTitle: string = 'Loading...';

    public loadingBody: string = 'New information...';

    public notifications$: Observable<NotificationInstance[]>;

    protected notifications: BehaviorSubject<NotificationInstance[]>;

    constructor() {
        this.notifications = new BehaviorSubject<NotificationInstance[]>([]);
        this.notifications$ = this.notifications.asObservable();
    }

    public addNotification(notificationType: NotificationType,
                           title: string, body: string, expire?: number): NotificationInstance {
        const notificationInstance = (new NotificationInstance(this, new Notification(notificationType, title, body)))
            .dismissAfter(expire);

        this.modifyValue((notifications) => {
            notifications.push(notificationInstance);
            return notifications;
        });

        return notificationInstance;
    }

    public dismiss(instance: NotificationInstance): this {
        this.modifyValue((notifications) => {
            return notifications.filter((inst) => inst.notificationInstanceID !== instance.notificationInstanceID);
        });

        return this;
    }

    public loading(title?: string, body?: string): NotificationInstance {
        if (!title && !body) {
            title = this.loadingTitle;
            body = this.loadingBody;
        }

        return this.addNotification(NotificationType.Loading, title, body);
    }

    public info(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.addNotification(NotificationType.Info, title, body, duration);
    }

    public success(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.addNotification(NotificationType.Success, title, body, duration);
    }

    public warning(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.addNotification(NotificationType.Warning, title, body, duration);
    }

    public danger(title: string, body?: string, duration: number = 4000): NotificationInstance {
        return this.addNotification(NotificationType.Danger, title, body, duration);
    }

    protected modifyValue(callback: (instances: NotificationInstance[]) => NotificationInstance[]): void {
        const notifications = this.notifications.getValue();
        this.notifications.next(callback(notifications));
    }
}
