import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { CompensationsSummary } from '../interfaces/compensations-summary';
import { CompensationsSearch } from './compensations-search';

@Injectable()
export class CompensationSummaryService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCompensationsSummary(compensationSearch: CompensationsSearch): Observable<CompensationsSummary> {
        const search = ParamsUtil.toHttpParams(compensationSearch);
        return this.get(`${this.hungrigClient.baseUrl}/compensations-summary`, { search });
    }
}
