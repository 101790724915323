import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, UserStorage } from '@ro-ngx/users';

@Injectable()
export class AuthorizedService {

    public user$: Observable<User>;

    constructor(protected userStorage: UserStorage) {
        this.user$ = this.userStorage.myself$;
    }

    public findMyself(): Observable<User> {
        return this.userStorage.findMyself({
            includes: ['userConnections.roles.permissions']
        });
    }
}
