import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@ro-ngx/boilerplate';
import { NotificationStorage } from '@ro-ngx/core';
import { sortBy } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject ,  forkJoin ,  merge ,  of } from 'rxjs';
import {
    catchError,
    debounceTime,
    finalize,
    map,
    share,
    shareReplay,
    switchMap,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
// @ts-ignore
import { DistrictService } from '../../../../../../apps/admin/src/app/http/delivery/district/delivery-district.service';
// @ts-ignore
import { DeliveryFleet } from '../../../../../../apps/admin/src/app/http/delivery/district/delivery-fleet';
import { DeliveryCompany } from '../../../interfaces';
import { DeliveryDistrict, DistrictSettings } from '../../../interfaces/delivery-district';
import { DeliveryCompanyService, DeliveryDistrictsService } from '../../../services';
import { DistrictFormComponent } from '../district-form/district-form.component';
import { DistrictMapComponent } from '../district-map/district-map.component';

export interface DistrictSortingOption {
    by: string;
    asc: boolean;
    name: string;
}

export interface EditedDistrict extends DeliveryDistrict {
    companies: DeliveryCompany[];
    dDistrictSettingsID: number;
}

export interface ConnectSet {
    connect: number[];
    disconnect?: number[];
}

/*
 * While doing CRUD, you make extra requests to connect/disconnect company to a district. One call per company.
 * When opening a district to edit, it is necessary to keep track of the initial state of connected companies,
 * and disconnect those that have possibly been removed.
 * Error handling suffers from that.
 */

@Component({
    selector: 'districts-page',
    template: require('./districts-page.component.html'),
})
export class DistrictsPageComponent extends BaseComponent implements OnInit {
    public districts$: Observable<DeliveryDistrict[]>;
    public companies$: Observable<DeliveryCompany[]>;
    public fleets$: Observable<DeliveryFleet[]>;

    public loading: { [key: string]: boolean } = {
        page: false,
        create: false,
        save: false,
        delete: false,
    };
    public sortingOptions: DistrictSortingOption[][];
    public currentSortingOption$: BehaviorSubject<DistrictSortingOption>;
    public currentSortingOptionName$: Observable<string>;
    public selectedDistrict: DeliveryDistrict = undefined;

    @ViewChild('map',{ static: true }) public map: DistrictMapComponent;
    @ViewChild('createDistrictForm',  { static: true }) public districtForm: DistrictFormComponent;
    @ViewChild('createDistrictModal',  { static: true }) public districtModal: ModalDirective;

    private dataChanged$: Subject<void> = new Subject();
    private _districts: DeliveryDistrict[];
    private editedDistrict: EditedDistrict = undefined;
    private editedDistrictSettings: DistrictSettings = undefined;

    constructor(
        private deliveryDistrictsService: DeliveryDistrictsService,
        private districtsService: DistrictService,
        private deliveryCompanyService: DeliveryCompanyService,
        private translateService: TranslateService,
        protected notificationStorage: NotificationStorage,
        private appRef: ApplicationRef,
    ) {
        super();

        this.sortingOptions = [[
            { name: 'az_option_asc', by: 'name', asc: true },
            { name: 'az_option_desc', by: 'name', asc: false },
        ], [
            { name: 'ns_option_asc', by: 'latitude', asc: false },
        ]];

        this.currentSortingOption$ = new BehaviorSubject(this.sortingOptions[1][0]);
    }

    public get modalIsUpdateMode(): boolean {
        return !!this.editedDistrict && typeof this.editedDistrict.deliveryDistrictID !== 'undefined';
    }

    public get modalTitle(): string {
        const titleStringID = this.modalIsUpdateMode
            ? 'general.edit'
            : 'delivery.districts.create_district_modal_title';

        return this.translateService.instant(titleStringID);
    }

    public trackFn = (index: number, district: DeliveryDistrict): string => {
        return district ? district.deliveryDistrictID : undefined;
    };

    public errorHandler = (): void => {
        this.notificationStorage.danger(this.translateService.instant('general.error'));
    };

    public ngOnInit(): void {

        const trigger$ = merge(
            of('init'),
            this.dataChanged$,
            this.currentSortingOption$,
        );

        this.districts$ = trigger$.pipe(
            withLatestFrom(
                this.currentSortingOption$,
            ),
            map(([, { by, asc }]) => ({
                orderBy: by,
                sortOrder: asc ? 'ASC' : 'DESC',
            })),
            debounceTime(400),
            switchMap((params) => {
                this.loading['page'] = true;
                return this.deliveryDistrictsService.getDistricts(params)
                    .pipe(
                        finalize(() => this.loading['page'] = false),
                    );
            }),
            share(),
        );

        this.districts$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((districts: DeliveryDistrict[]) => {
                this._districts = districts;
            });

        this.currentSortingOptionName$ = this.currentSortingOption$.pipe(
            map(({ name }) => name),
        );

        this.companies$ = this.deliveryCompanyService.getCompanies({}).pipe(
            map((companies) => sortBy(companies, (company) => company.companyName)),
            shareReplay(1),
        );

        this.fleets$ = this.districtsService.getFleets().pipe(
            shareReplay(1),
        );
    }

    public startAddingNew(): void {
        this.editedDistrict = undefined;
        this.districtForm.reset();
        this.districtModal.show();
    }

    public createDistrict(): void {
        const { companies, settings, ...district } = this.districtForm.form.value;
        const connect = companies.map((dc) => dc.deliveryCompanyID);

        this.loading['create'] = true;
        this.deliveryDistrictsService.createDistrict(district)
            .pipe(
                switchMap(({ deliveryDistrictID }) => {
                    const ids = { connect };
                    return this.connectCompanies(deliveryDistrictID, ids);
                }),
                finalize(() => this.loading['create'] = false),
            )
            .subscribe({
                complete: () => {
                    this.dataChanged$.next();
                    this.districtModal.hide();
                    this.notificationStorage.success(this.translateService.instant('general.created'));
                },
                error: this.errorHandler,
            });
    }

    public editDistrict({ deliveryDistrictID }: DeliveryDistrict): void {
        this.districtForm.reset();

        forkJoin(
            this.deliveryDistrictsService.getDistrict(deliveryDistrictID),
            this.deliveryDistrictsService.getDistrictSettings(+deliveryDistrictID),
            this.deliveryCompanyService.getConnectedToDistrict(deliveryDistrictID),
        )
            .subscribe({
                error: this.errorHandler,
                next: ([district, settings, companies]) => {
                    this.editedDistrict = { ...district, companies, dDistrictSettingsID: settings.dDistrictSettingsID };
                    this.editedDistrictSettings = settings;
                    this.districtForm.reset(this.editedDistrict, this.editedDistrictSettings);
                    this.districtModal.show();
                    this.appRef.tick();
                }
            });
    }

    public updateDistrict(): void {

        const { deliveryDistrictID, dDistrictSettingsID, companies, settings, ...district } = this.districtForm.form.value;


        // Some companies might have been removed by the user - need to compare with the initial state of edited district
        // and collect the removed IDs and send a disconnecting request (connect with a null payload)

        const selectedDeliveryCompanyIDs = companies.map((dc) => dc.deliveryCompanyID);

        const disconnectingIDs = this.editedDistrict.companies
            .filter((dc) => selectedDeliveryCompanyIDs.indexOf(dc.deliveryCompanyID) === -1)
            .map((dc) => dc.deliveryCompanyID);

        this.loading['save'] = true;

        this.deliveryDistrictsService.updateDistrict(this.editedDistrict.deliveryDistrictID, district)
            .pipe(
                switchMap(() => {
                    const ids = {
                        connect: selectedDeliveryCompanyIDs,
                        disconnect: disconnectingIDs,
                    };
                    return this.connectCompanies(deliveryDistrictID, ids);
                }),
                switchMap(() => {
                    return this.deliveryDistrictsService.updateDistrictSettings(dDistrictSettingsID, settings)
                }),
                finalize(() => this.loading['save'] = false),
            )
            .subscribe({
                error: this.errorHandler,
                complete: () => {
                    this.districtModal.hide();
                    this.dataChanged$.next();
                    this.notificationStorage.success(this.translateService.instant('general.saved'));
                },
            });
    }

    public deleteDistrict(): void {
        this.loading['delete'] = true;
        this.deliveryDistrictsService.deleteDistrict(this.editedDistrict.deliveryDistrictID)
            .pipe(
                finalize(() => this.loading['delete'] = false),
            )
            .subscribe({
                error: this.errorHandler,
                complete: () => {
                    this.districtModal.hide();
                    this.dataChanged$.next();
                    this.notificationStorage.success(this.translateService.instant('general.success'));
                },
            });
    }

    public selectDistrict(district: DeliveryDistrict): void {
        this.selectedDistrict = district;
    }

    public selectDistrictByID(id: string): void {
        const district = this._districts.find((d) => d.deliveryDistrictID === id);

        if (!district) {
            this.notificationStorage.danger(this.translateService.instant('general.error'));
            return;
        }

        this.editDistrict(district);
    }

    public submit(): void {
        if (this.districtForm.form.invalid) {
            return;
        }

        if (this.modalIsUpdateMode) {
            this.updateDistrict();
        } else {
            this.createDistrict();
        }
    }

    public setSorting(item: DistrictSortingOption): void {
        this.currentSortingOption$.next(item);
    }

    /**
     * Creates connecting and disconnecting requests.
     * @param deliveryDistrictID
     * @param connect
     * @param disconnect
     */
    private connectCompanies(
        deliveryDistrictID: string, { connect = [], disconnect = [] }: ConnectSet
    ): Observable<any> {

        const connectingRequests = connect.map((deliveryCompanyID) => {
            return this.deliveryCompanyService.connectToDistrict(deliveryCompanyID, deliveryDistrictID).pipe(
                catchError(() => {
                    this.notificationStorage.danger(
                        this.translateService.instant('delivery.district.connect_failed_message', { deliveryCompanyID }),
                    );
                    return of(null);
                }),
            );
        });

        const disconnectingRequests = disconnect.map((deliveryCompanyID) => {
            return this.deliveryCompanyService.disconnectFromDistrict(deliveryCompanyID).pipe(
                catchError(() => {
                    this.notificationStorage.danger(
                        this.translateService.instant('delivery.district.disconnect_failed_message', { deliveryCompanyID }),
                    );
                    return of(null);
                }),
            );
        });

        const allRequests = [...connectingRequests, ...disconnectingRequests];

        return allRequests.length
            ? forkJoin(allRequests) : of(null);
    }
}
