import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CouponService } from '../../../../services';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { CouponGroupState } from './coupon-group.state';
import { CouponGroup } from '../../../../interfaces';
import { Observable } from 'rxjs';

@Component({
    selector: 'coupon-group',
    template: require('./coupon-group.component.html'),
    styles: ['.nav-tabs { margin-bottom: 15px; }'],
    providers: [CouponGroupState]
})
export class CouponGroupComponent implements OnInit {
    public couponGroup$: Observable<CouponGroup>;

    constructor(
        protected route: ActivatedRoute,
        protected couponService: CouponService,
        protected couponGroupState: CouponGroupState
    ) {
    }

    public ngOnInit(): void {
        this.couponGroup$ = this.route.paramMap
            .pipe(
                map((params) => +params.get('groupId')),
                distinctUntilChanged(),
                switchMap((groupId) => this.couponService.getGroup(groupId)),
                tap((group) => this.couponGroupState.setCouponGroup(group))
            );
    }
}
