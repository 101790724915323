
import {combineLatest as observableCombineLatest, fromEvent, Observable} from 'rxjs';

import {filter, map, startWith} from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CollectionStorage } from '../../../http/resource/collection.storage';
import { find } from 'lodash';
import { SubscriptionCollection } from '../../../http/resource/subscription-collection';

@Component({
    selector: 'ro-select-multiple',
    template: `
        <div class="form-control ro-select multiple">
            <div class="selected-items-container">
                <a href class="btn btn-default btn-secondary btn-xs" 
                   *ngFor="let item of selectedItems$ | async; trackBy: trackItem" 
                   (click)="removeItem($event, item)">
                    {{ item[itemKeyVisible] }}&nbsp;&nbsp;×
                </a>
            </div>
            <input type="text" #searchText class="keep-dropdown-open" placeholder="{{ placeholder }}">
            <div class="dropdown keep-dropdown-open" [class.open]="showDropdown">
                <div class="dropdown-menu keep-dropdown-open">
                    <li *ngFor="let item of dropdownItems$ | async; trackBy: trackItem" class="keep-dropdown-open">
                        <a href (click)="selectItem($event, item)" class="keep-dropdown-open">
                            {{ item[itemKeyVisible] }}
                        </a>
                    </li>
                </div>
            </div>
        </div>
    `
})
export class SelectMultipleComponent implements OnInit {

    @ViewChild('searchText',{static:true})
    public searchText: ElementRef;

    @Input()
    public itemKeyId: string;

    @Input()
    public itemKeyVisible: string;

    @Input()
    public items$: Observable<any[]>;

    @Input()
    set selections(selections: any[]) {
        this.selectedItems.nextSubject(selections);
    }

    @Input()
    public allowCustom: boolean = false;

    @Input()
    public placeholder: string = '';

    @Output()
    public onSelections: EventEmitter<any>;

    @Output()
    public onSelection: EventEmitter<any>;

    @Output()
    public onDeselection: EventEmitter<any>;

    public dropdownItems$: Observable<any[]>;

    public selectedItems: CollectionStorage<any>;

    public selectedItems$: Observable<any[]>;

    public showDropdown: boolean = false;

    protected subscriptions: SubscriptionCollection;

    constructor() {
        this.subscriptions = new SubscriptionCollection();
        this.selectedItems = new CollectionStorage();
        this.selectedItems$ = this.selectedItems.$;
        this.onSelections = new EventEmitter();
        this.onSelection = new EventEmitter();
        this.onDeselection = new EventEmitter();
    }

    /**
     * NgOnInit.
     */
    public ngOnInit(): void {
        let test = fromEvent(this.searchText.nativeElement, 'keyup').pipe(
            map((keyboardEvent: KeyboardEvent) => this.searchText.nativeElement.value));

        fromEvent(this.searchText.nativeElement, 'focus')
            .subscribe(() => this.showDropdown = true);


        this.selectedItems$
            .subscribe((selectedItems: any[]) => this.onSelections.emit(selectedItems));

        this.dropdownItems$ = observableCombineLatest(
            this.items$,
            this.selectedItems$,
            test.pipe(startWith('')),
            (items: any[], selectedItems: any[], searchKey: string) => {
                const availableItems: any[] = items.slice();

                if (this.allowCustom) {
                    const customItem = {};
                    customItem[this.itemKeyId] = customItem[this.itemKeyVisible] = searchKey;
                    availableItems.push(customItem);
                }

                return availableItems.filter((item) => {
                    if (this.itemKeyId) {
                        if (find(selectedItems, { [this.itemKeyId]: item[this.itemKeyId] })) {
                            return false;
                        }
                        return item[this.itemKeyVisible].toLowerCase().includes(searchKey.toLowerCase());
                    }
                });
            }
        );
    }

    public selectItem(event: Event, item: any): void {
        event.preventDefault();

        this.showDropdown = false;
        this.onSelection.emit(item);
        this.selectedItems.singleUnionSubject(item, this.itemKeyId);
    }

    public removeItem(event: Event, item: any): void {
        event.preventDefault();

        this.onDeselection.emit(item);
        this.selectedItems.spliceSubject(item[this.itemKeyId], this.itemKeyId);
    }

    /**
     * Track item.
     *
     * @param index
     * @param item
     * @returns {*}
     */
    public trackItem(index: number, item: any): any {
        return item ? item[this.itemKeyId] : undefined;
    }
}
