import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import {
    IDeliveryTimesItem,
    IDeliveryTimesRangeFormState,
    IDeliveryTimesTimeFormState
} from '../interfaces/delivery-times';

@Injectable()
export class DeliveryTimesService extends BaseService {

    constructor(public readonly hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getClosedTitleCompanies(): Observable<IDeliveryTimesItem[]> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies`;
        const params = ParamsUtil.toHttpParams({hasClosedTitle: 1});
        return this.get(url, {params});
    }

    public getAcceptTimeTitleCompanies(): Observable<IDeliveryTimesItem[]> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies`;
        const params = ParamsUtil.toHttpParams({hasAcceptTimeTitle: 1});
        return this.get(url, {params});
    }

    public updateAcceptTime(deliveryCompanyID: number, payload: Partial<IDeliveryTimesItem>): Observable<IDeliveryTimesItem> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies/${deliveryCompanyID}`;
        return this.put(url, payload);
    }

    public updateClosedMeters(deliveryCompanyID: number, payload: Partial<IDeliveryTimesItem>): Observable<IDeliveryTimesItem> {
        const url = `${this.hungrigClient.baseUrl}/delivery/companies/${deliveryCompanyID}`;
        return this.put(url, payload);
    }
}
