import { Country } from './country';
import { Language } from './language';

export class LocaleConfig {
    constructor(
        public readonly country: Country,
        public readonly language: Language
    ) {
    }
}
