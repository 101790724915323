import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes, startOfMinute } from 'date-fns';

/**
 * The pipe counts minutes elapsed between time of its creation and the supplied time.
 * It will return negative time if the value is after the reference time,
 * meaning the we have no minutes left, we are behind!
 *
 * Note: this is NOT a live-updating pipe. If live is required, consider ngx-timeago.
 */
@Pipe({
    name: 'asTimeLeft',
    pure: false,
})
export class AsTimeLeftPipe implements PipeTransform {
    private readonly initialNow: Date;

    constructor() {
        this.initialNow = new Date();
    }

    public transform(value: string): string {
        const diff = differenceInMinutes(
            startOfMinute(new Date(value)),
            startOfMinute(this.initialNow.toUTCString()),
        );

        const sign = diff < 0 ? '-' : '';

        const timeRelative = Math.abs(diff) > 60
            ? `${sign}1h+`
            : `${diff}min`;


        return `${timeRelative}`;
    }
}
