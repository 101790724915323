import { ModuleWithProviders, NgModule } from '@angular/core';
import { CouponManagerConfig } from './coupon-manager-config';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class CouponManagerConfigModule {
    public static forRoot(config: CouponManagerConfig): ModuleWithProviders {
        return {
            ngModule: CouponManagerConfigModule,
            providers: [
                { provide: CouponManagerConfig, useValue: config }
            ]
        };
    }
}
