import { Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DialogContract } from '../dialog.contract';

export interface ConfirmDialogOptions {
    readonly title: string;
    readonly body?: string;
    readonly ok?: string;
    readonly cancel?: string;
    readonly accent?: 'warning' | 'danger' | 'success' | 'primary' | 'default';
}

export class ConfirmDialog implements DialogContract<boolean> {
    public result$: Observable<boolean>;
    protected result: Subject<boolean>;

    public constructor(
        public readonly component: Type<any>,
        public options: ConfirmDialogOptions
    ) {
        this.result = new Subject();
        this.result$ = this.result.asObservable();
    }

    public complete(result: boolean): void {
        this.result.next(result);
        this.result.complete();
    }
}
