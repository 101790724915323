import { NgModule } from '@angular/core';

import { OrderPaymentInfoComponent } from './order-payment-info.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalOrderPaymentRefundInfoComponent } from './modal-order-payment-refund-info';

@NgModule({
    imports: [
        CommonModule,
        RoNg2Module,
        TranslateModule.forChild(),
        ModalModule
    ],
    exports: [ OrderPaymentInfoComponent ],
    declarations: [
        OrderPaymentInfoComponent,
        ModalOrderPaymentRefundInfoComponent
    ],
    providers: [],
})
export class OrderPaymentInfoModule {
}
