import { NgModule } from '@angular/core';

import { UserActivityListComponent } from './user-activity-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    exports: [ UserActivityListComponent ],
    declarations: [ UserActivityListComponent ],
    providers: [],
    entryComponents: [ UserActivityListComponent ]
})
export class UserActivityListModule {
}
