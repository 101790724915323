import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CurrencySupport, DialogService, NotificationStorage } from '@ro-ngx/core';
import { CouponTemplateService } from '../template';
import { CouponFormComponent } from './coupon-form.component';
import { CouponService } from '../../../services';
import { CampaignGroupService } from '../../../services/campaign-group.service';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { CouponGroupState } from '../groups/group';
import { CouponManagerConfig } from '../coupon-manager-config';

@Component({
    selector: 'generate-coupon',
    template: require('./coupon-form.component.html')
})
export class GenerateCouponComponent extends CouponFormComponent {
    constructor(
        protected couponService: CouponService,
        protected templateService: CouponTemplateService,
        protected campaignGroupService: CampaignGroupService,
        protected translateService: TranslateService,
        protected notificationStorage: NotificationStorage,
        protected currencySupport: CurrencySupport,
        protected formBuilder: FormBuilder,
        protected dialogService: DialogService,
        protected couponGroupState: CouponGroupState,
        public config: CouponManagerConfig
    ) {
        super(campaignGroupService, templateService, translateService, currencySupport, formBuilder, config);
    }

    public save(): void {
        if (! this.form.valid) {
            this.markFormAsTouched();
            return;
        }

        this.saving$.next(true);

        this.couponGroupState.couponGroup$.pipe(
            first(),
            map((group) => {
                return Object.assign({}, this.getValue(), {
                    couponGroupID: group.couponGroupID,
                    clientKey: group.clientKey,
                    hungrig: this.config.asAdmin
                });
            }),
            switchMap((request) => this.couponService.createCoupon(request)),
            finalize(() => this.saving$.next(false))
        ).subscribe(
            () => this.notificationStorage.success(this.translateService.instant('general.success')),
            (error) => {
                const alreadyExists: number = 409;
                if (error.statusCode === alreadyExists) {
                    this.dialogService.notify(this.translateService.instant('coupon.coupon_already_exists_dialog'))
                        .subscribe();
                }
            }
        );
    }

    protected markFormAsTouched(): void {
        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        }
    }
}
