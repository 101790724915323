import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { DeliveryCompanyService, DeliveryDriverService } from '../../../services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountType, DeliveryCompany, DeliveryDriver, TransportMode } from '../../../interfaces';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, first, switchMap, tap } from 'rxjs/operators';
import { isUndefined } from 'lodash';
import { DialogService, Error, MultipleSelect, NotificationStorage } from '@ro-ngx/core';
import { AuthorizedService } from '@ro-ngx/authentication';
import { PermissionName } from '@ro-ngx/users';
import { TranslateService } from '@ngx-translate/core';

export interface TransportModeOption {
    transportMode: TransportMode;
    translateKey: string;
}

export interface AccountTypeOption {
    accountType: AccountType;
    translateKey: string;
}

const DEFAULT_DRIVER_MAX_CONCURRENT_ORDERS = 2;

@Component({
    selector: 'create-driver',
    template: require('./driver-form.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverFormComponent implements OnInit {

    protected static SALARY: number = 50;

    public form: FormGroup;

    public transportModeOptions: TransportModeOption[];

    public accountTypeOptions: AccountTypeOption[];

    @Output()
    public onSave: EventEmitter<DeliveryDriver> = new EventEmitter<DeliveryDriver>();

    @Input()
    public deliveryCompany: DeliveryCompany;

    @Input()
    public deliveryDriver: DeliveryDriver;

    public isNew: boolean;

    public saving$: BehaviorSubject<boolean>;

    public deliveryCompanySelect: MultipleSelect;

    public maxConcurrentOrdersOptions: any[];

    protected error$: BehaviorSubject<boolean | string>;

    constructor(
        protected deliveryDriverService: DeliveryDriverService,
        protected deliveryCompanyService: DeliveryCompanyService,
        protected notificationStorage: NotificationStorage,
        protected authorizedService: AuthorizedService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected translateService: TranslateService,
        protected dialogService: DialogService,
        protected formBuilder: FormBuilder
    ) {
    }

    public ngOnInit(): void {
        this.saving$ = new BehaviorSubject<boolean>(false);
        this.error$ = new BehaviorSubject(false);

        this.transportModeOptions = [
            {
                transportMode: TransportMode.BIKE,
                translateKey: 'delivery.driver.mode_bike'
            },
            {
                transportMode: TransportMode.CAR,
                translateKey: 'delivery.driver.mode_car'
            }
        ];

        this.accountTypeOptions = [
            {
                accountType: AccountType.DRIVER,
                translateKey: 'delivery.driver.type_driver'
            },
            {
                accountType: AccountType.TERMINAL,
                translateKey: 'delivery.driver.type_terminal'
            }
        ];

        this.maxConcurrentOrdersOptions = ((max: number) => {
            return Array.from({ length: max }).map((v, ix) => ix);
        })(11);

        this.authorizedService.user$.pipe(
                filter((user) => Boolean(user)),
                first()
            )
            .subscribe((user) => {
                this.form = this.createFormGroup(
                    user.hasPermission(PermissionName.DELIVERY_DRIVERS_READ_EMPLOYEE_INFORMATION) &&
                    this.deliveryCompany.financesettings.paySalary
                );

                if (! isUndefined(this.deliveryDriver)) {
                    this.form.reset(this.deliveryDriver);
                    this.isNew = false;
                    this.initDeliveryCompanySelect();
                }
                else {
                    this.isNew = true;
                }
            });
    }

    public save(): void {
        this.error$.next(false);
        if (this.isNew) {
            this.create();
        } else {
            this.update();
        }
    }

    public isInvalid(formControlName: string): boolean {
        const control = this.form.get(formControlName);
        return control.invalid && (control.touched || control.dirty);
    }

    public isRequired(formControlName: string): boolean {
        return this.isInvalid(formControlName) && this.form.get(formControlName).hasError('required');
    }

    protected initDeliveryCompanySelect(): void {
        this.deliveryCompanySelect = new MultipleSelect({
            onSelection: (deliveryCompany: DeliveryCompany) => {
                const formControl = this.form.get('deliveryCompanyID');
                formControl.markAsDirty();
                formControl.setValue(deliveryCompany.deliveryCompanyID);
            },
            items$: (multipleSelect) => {
                const debounceTimeMs: number = 600;
                return multipleSelect.searchText$
                    .pipe(
                        tap(() => multipleSelect.setLoading(true)),
                        debounceTime(debounceTimeMs),
                        switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                        tap(() => {
                            multipleSelect.setLoading(false);
                            this.changeDetectorRef.markForCheck();
                        })
                    );
            },
            itemKeyVisible: 'companyName',
            itemKeyId: 'deliveryCompanyID',
            maxSelections: 1
        });
    }

    protected create(): void {
        if (this.form.invalid) {
            this.markFormAsTouched();
            return;
        }

        this.saving$.next(true);

        const createDriverRequest = {
            ...this.form.value,
            maxConcurrentOrders: this.form.value.maxConcurrentOrders || null,
            active: true,
        };

        this.deliveryDriverService.createDriver(createDriverRequest)
            .subscribe(
                (driver) => {
                    this.saving$.next(false);
                    this.onSave.emit(driver);
                    this.notificationStorage.success(
                        this.translateService.instant('general.created') + '!',
                        driver.driverName + ' ' + this.translateService.instant('general.created').toLowerCase() + '.'
                    );
                },
                (error) => {
                    this.saving$.next(false);
                    this.setError(error);
                });
    }

    protected update(): void {
        const form = this.getDirtyControls();

        if (form.invalid) {
            return;
        }

        const payload = {
            ...form.value,
            maxConcurrentOrders: form.value.maxConcurrentOrders || null,
        };

        this.saving$.next(true);
        this.deliveryDriverService.updateDriver(this.deliveryDriver.deliveryDriverID, payload)
            .subscribe(
                (driver) => {
                    this.saving$.next(false);
                    this.onSave.emit(driver);
                    this.notificationStorage.success(
                        this.translateService.instant('general.saved') + '!',
                        driver.driverName + ' ' + this.translateService.instant('general.saved').toLowerCase() + '.'
                    );
                },
                (error) => {
                    this.saving$.next(false);
                    this.setError(error);
                }
            );
    }

    protected setError(error: Error<any>): void {
        const duplicateEntity = 422;
        if (error.statusCode === duplicateEntity) {
            this.error$.next('A driver with specified name already exists.');
        }

        this.notificationStorage.danger(
            this.translateService.instant('general.error') + '!',
            error.errorMessage
        );
    }

    protected getDirtyControls(): FormGroup {
        const group = {};

        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control.dirty) {
                group[name] = control;
            }
        }

        return this.formBuilder.group(group);
    }

    protected markFormAsTouched(): void {
        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        }
    }

    protected createFormGroup(includeEmployeeInformation: boolean): FormGroup {
        const controlsConfig = {
            deliveryCompanyID: this.deliveryCompany.deliveryCompanyID,
            invoicePayment: false,
            driverName: [null, Validators.required],
            password: [null, Validators.required],
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            transportMode: [this.deliveryCompany.transportMode, Validators.required],
            maxConcurrentOrders: DEFAULT_DRIVER_MAX_CONCURRENT_ORDERS,
            phone: [null, Validators.required],
            salary: [DriverFormComponent.SALARY, Validators.required],
            accountType: [AccountType.DRIVER, Validators.required],
            comment: null
        };

        if (includeEmployeeInformation) {
            Object.assign(controlsConfig, {
                employeeNo: [null, Validators.required],
                address: [null, Validators.required],
                zip: [null, Validators.required],
                city: [null, Validators.required],
                email: [null, Validators.required],
                socialSecurityNo: [null, Validators.required],
                taxTable: [null, Validators.required],
                bankName: [null, Validators.required],
                clearingNo: [null, Validators.required],
                accountNo: [null, Validators.required],
                closestRelativeName: [null, Validators.required],
                closestRelativePhone: [null, Validators.required]
            });
        }

        return this.formBuilder.group(controlsConfig);
    }
}
