import { AbstractControl, ValidationErrors } from '@angular/forms';

const isEmptyInputValue = (value: any): boolean => {
    // tslint:disable-next-line:triple-equals
    return value == null || value.length === 0;
};

const MIN_LAT = -90;
const MAX_LET = 90;
const MIN_LNG = -180;
const MAX_LNG = 180;

export class GeoValidators {
    public static latitude(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = parseFloat(control.value);
        return !isNaN(value) && (value < MIN_LAT || value > MAX_LET) ? { 'geoLat': control.value } : null;
    }

    public static longitude(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }

        const value = parseFloat(control.value);
        return !isNaN(value) && (value < MIN_LNG || value > MAX_LNG) ? { 'geoLng': control.value } : null;
    }
}
