import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from "@ro-ngx/core";
import { ProductRestaurantCatalog } from "../interfaces/product-restaurant-catalog";
import { Observable } from "rxjs";

@Injectable()
export class ProductRestaurantCatalogService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }

    public getProductRestaurantCatalogs(
        options: GetProductRestaurantCatalogsOptions = {}
    ): Observable<ProductRestaurantCatalog[]> {
        return this.get('/product-restaurant-catalog', {
            search: ParamsUtil.toHttpParams(options)
        });
    }
}

export interface GetProductRestaurantCatalogsOptions {
    includes?: string[];
    clientKey?: string|string[];
    isEditable?: boolean;
}
