export enum PermissionName {
    ARTICLES_READ_ALL = 'articles read all',
    ALERTS_READ_ALL = 'alerts read all',
    ALERT_VIEWERS_READ_ALL = 'alert viewers read all',
    ANNOUNCEMENTS_EDIT = 'announcements edit',
    CAMPAIGNS_EDIT = 'campaigns edit',
    CAMPAIGN_GROUPS_READ_ALL = 'campaign groups read all',
    COMPENSATIONS_READ_ALL = 'compensations read all',
    COMPENSATIONS_COMPLETE = 'compensations complete',
    COMPENSATIONS_REFUND = 'compensations refund',
    COMPENSATION_DEBITS_EDIT = 'compensation debits edit',
    COUPON_CODES_READ_ALL = 'coupon codes read all',
    CUSTOM_PLACES_EDIT = 'customer places edit',
    DELIVERY_COMPANIES_READ = 'delivery companies read',
    DELIVERY_COMPANIES_READ_ALL = 'delivery companies read all',
    DELIVERY_COMPANY_FINANCES_READ_ALL = 'delivery company finances read all',
    DELIVERY_DRIVERS_READ_ALL = 'delivery drivers read all',
    DELIVERY_DRIVERS_READ_EMPLOYEE_INFORMATION = 'delivery drivers read employee information',
    DELIVERY_DRIVERS_READ = 'delivery drivers read',
    DELIVERY_DRIVER_FINANCES_READ_ALL = 'delivery driver finances read all',
    DELIVERY_DRIVER_POSITIONS_READ_ALL = 'delivery driver positions read all',
    DELIVERY_ORDERS_READ = 'delivery orders read',
    DELIVERY_ORDERS_READ_ALL = 'delivery orders read all',
    DELIVERY_ORDERS_READ_PRICE = 'delivery orders read price',
    DELIVERY_ORDERS_READ_CREATED_BEFORE_7_DAYS = 'delivery orders read more than 7 days',
    DELIVERY_ORDERS_READ_CREATED_BEYOND_40_MINUTES = 'delivery orders read created beyond 40 minutes',
    DELIVERY_ORDERS_UPDATE = 'delivery orders update',
    DELIVERY_ORDERS_UPDATE_ALL = 'delivery orders update all',
    DELIVERY_ORDERS_UPDATE_DISTANCE = 'delivery orders update distance',
    DELIVERY_ORDERS_UPDATE_TIMES = 'delivery orders update times',
    ORDERS_READ_ALL = 'orders read all',
    ORDER_BREAKDOWN_READ_TOTAL = 'order breakdown total',
    ORDER_CONTACTS_CREATE = 'order contacts create',
    ORDER_CUSTOMERS_READ_ALL = 'order customers read all',
    ORDER_CUSTOMERS_READ_EMAIL = 'order customers read email',
    ORDER_CUSTOMERS_READ_DETAILS_BEFORE_24_HOURS = 'order customers read details before 24 hours',
    ORDER_CUSTOMER_EXPORTS_ALL = 'order customer exports all',
    ORDER_TESTS_READ_ALL = 'order tests read all',
    ORDER_UNVOID = 'unvoid order',
    PAGE_SETTINGS = 'page settings edit',
    PUSH_NOTIFICATIONS_READ_ALL = 'push notifications read all',
    RESTAURANTS_READ_ALL = 'restaurants read all',
    RESTAURANT_FINANCES_READ_ALL = 'restaurant finances read all',
    RESTAURANT_FINANCE_SETTINGS_READ_ALL = 'restaurant finance settings read all',
    RESTAURANT_FINANCE_SETTINGS_UPDATE_REMOVE_DELIVERY = 'restaurant finance settings update remove delivery',
    RESTAURANT_STATUS_READ_ALL = 'restaurant status read all',
    RESTAURANT_SUMMARIES_READ_ALL = 'restaurant summaries read all',
    SEND_GRID_EVENTS_READ_ALL = 'send grid events read all',
    TOTAL_SUMMARIES_READ = 'total summaries read',
    USERS_UPDATE_ALL = 'users update all'
}
