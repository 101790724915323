import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from "@ro-ngx/core";
import { Observable } from "rxjs";
import { ProductCategory } from "../interfaces/product-category";

@Injectable()
export class ProductCategoryService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }

    public getProductCategories(options: GetProductCategories): Observable<ProductCategory[]> {
        return this.get('/product-category', {
            search: ParamsUtil.toHttpParams(options)
        });
    }
}

export interface GetProductCategories {
    productCatalogID?: number;
    isActive?: boolean;
    seoID?: string;
}
