import {
    COLOR_DEPENDS_ON_ORDER_COUNT,
    COLOR_SELECTED,
    LAYER_DRIVER_MARKER_STATES,
    LAYER_DRIVER_MARKERS,
    SOURCE_DRIVERS,
} from './constants';

export const driversMarkersStyles = [
    // Drivers markers
    {
        'id': LAYER_DRIVER_MARKER_STATES,
        'type': 'circle',
        'source': SOURCE_DRIVERS,
        'interactive': true,
        'paint': {
            'circle-radius': 16,
            'circle-color': COLOR_DEPENDS_ON_ORDER_COUNT,
            'circle-stroke-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                COLOR_SELECTED,
                '#999',
                ],
            'circle-stroke-width': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                3,
                1,
            ],
        },
    }, {
        'id': LAYER_DRIVER_MARKERS,
        'type': 'symbol',
        'source': SOURCE_DRIVERS,
        'interactive': true,
        'layout': {
            'icon-allow-overlap': true,
            'icon-image': '{icon}',
            'icon-size': 0.75,
            'text-field': ['format',
                ['upcase', ['get', 'driverName']], { 'font-scale': .7 },
            ],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.2],
            'text-anchor': 'center',
            'text-allow-overlap': false,
            'symbol-z-order': 'source',
        },
        'paint': {
            'text-color': 'black',
            'text-halo-color': COLOR_DEPENDS_ON_ORDER_COUNT,
            'text-halo-width': 4,
            'text-halo-blur': 2,
        },
        'filter': ['in', '$type', 'Point'],
    },
];
