
import {of as observableOf,  Observable ,  BehaviorSubject ,  forkJoin } from 'rxjs';

import {switchMap, finalize, first} from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Order } from '@ro-ngx/orders';
import {
    Compensation,
    CompensationCalculatorCreditResult,
    CompensationCalculatorDebitResult,
    CompensationCalculatorResult
} from '../../../interfaces';
import { CompensationCalculatorService, CompensationService, CompensationUtilService } from '../../../services';
import { Debtor, RefundType, Source, ValueType } from '../../../enums';
import { CompensationCalculatorState } from '../compensation-calculator.state';
import { ValueTypeOption, valueTypeOptions } from '../../../common/options';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmCompensationDebitsComponent } from './confirm-compensation-debits';
import { ConfirmCompensationCreditsComponent } from './confirm-compensation-credits';
import { isNil } from 'lodash';

@Component({
    selector: 'confirm-compensation',
    template: require('./confirm-compensation.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        '.compensation-result > tbody > tr > td { vertical-align: middle; }'
    ]
})
export class ConfirmCompensationComponent implements OnInit {
    @Input()
    public order: Order;

    @Input()
    public compensation: Compensation;

    public form: FormGroup;

    public result: CompensationCalculatorResult;

    public ValueType: typeof ValueType = ValueType;

    public valueTypeOptions: ValueTypeOption[] = valueTypeOptions;

    public extraCoupon: FormControl;

    public extraCouponForm: FormGroup;

    public isSaving$: BehaviorSubject<boolean>;

    public isLoading$: BehaviorSubject<boolean>;

    public errorMessage: string;

    public warningMessages: string[];

    @ViewChild(ConfirmCompensationDebitsComponent)
    protected confirmDebitsComponent: ConfirmCompensationDebitsComponent;

    @ViewChild(ConfirmCompensationCreditsComponent)
    protected confirmCreditsComponent: ConfirmCompensationCreditsComponent;

    constructor(
        protected compensationService: CompensationService,
        protected calculatorService: CompensationCalculatorService,
        protected state: CompensationCalculatorState,
        protected compensationUtilService: CompensationUtilService,
        protected formBuilder: FormBuilder,
        protected translateService: TranslateService,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        const formControls = {};

        if (! this.compensation) {
            Object.assign(formControls, {
                zendeskID: ['', [Validators.pattern(/^[0-9]*$/),Validators.required]],
                comment: [''],
            });
        }

        this.form = this.formBuilder.group(formControls);

        this.extraCoupon = new FormControl(false);
        this.extraCouponForm = this.formBuilder.group({
            reason: this.translateService.instant('order_compensation.spice_up_compensation_reason'),
            refundType: RefundType.COUPON,
            valueType: ValueType.PERCENTAGE,
            value: 0,
            source: Source.CUSTOM
        });

        this.isSaving$ = new BehaviorSubject<boolean>(false);
        this.isLoading$ = new BehaviorSubject<boolean>(true);

        this.getResult().pipe(
            finalize(() => this.isLoading$.next(false))
        )
            .subscribe((result) => {
                this.setResult(result);
                this.changeDetectorRef.markForCheck();
            });
    }

    public confirm(): void {
        if (this.isSaving$.getValue()) {
            return;
        }

        if (this.form.invalid || ! this.confirmCreditsComponent.isValid() || ! this.confirmDebitsComponent.isValid()) {
            this.markFormAsTouched();
            return;
        }

        this.isSaving$.next(true);

        const credits = this.confirmCreditsComponent.getCredits();
        const debits = this.confirmDebitsComponent.getDebits();

        if (this.extraCoupon.value) {
            credits.push(this.extraCouponForm.value);
        }

        if (isNil(this.compensation)) {
            this.createCompensation(credits, debits);
        } else {
            this.appendToCompensation(credits, debits);
        }
    }

    public close(): void {
        this.state.close();
    }

    protected createCompensation(
        credits: CompensationCalculatorCreditResult[],
        debits: CompensationCalculatorDebitResult[]
    ): void {
        const compensationRequest = {
            debits,
            credits,
            orderID: this.order.orderID,
            ...this.form.value
        };

        this.compensationService.createCompensation(compensationRequest)
            .pipe(
                finalize(() => this.isSaving$.next(false))
            )
            .subscribe(
                () => this.state.complete(),
                (error) => this.errorMessage = error.errorMessage
            );
    }

    protected appendToCompensation(
        credits: CompensationCalculatorCreditResult[],
        debits: CompensationCalculatorDebitResult[]
    ): void {
        const creditRequests = credits.map((credit) =>
                this.compensationService.createCompensationCredit(this.compensation.compensationID, credit));

        const debitRequests = debits.map((debit) =>
                this.compensationService.createCompensationDebit(this.compensation.compensationID, debit));

        forkJoin(
                creditRequests.length === 0 ? observableOf([]) : forkJoin(creditRequests),
                debitRequests.length === 0 ? observableOf([]) : forkJoin(debitRequests)
            )
            .pipe(
                finalize(() => this.isSaving$.next(false))
            )
            .subscribe(
                () => this.state.complete()
            );
    }

    protected getCalculation(): Observable<CompensationCalculatorResult> {
        return forkJoin(
                this.state.calculators$.pipe(first()),
                this.state.config$.pipe(first())
            ).pipe(
            switchMap(([calculators, config]) =>
                this.calculatorService.getCalculation(this.order.orderID, calculators, {
                    debitsOnly: config.debitsOnly
                })
            ));
    }

    protected getResult(): Observable<CompensationCalculatorResult> {
        return forkJoin(
            this.getCalculation(),
            this.state.customCredits$.pipe(first()),
            this.state.customDebits$.pipe(first()),
            (result, customCredits, customDebits) => {
                result.credits = result.credits.concat(customCredits);
                result.debits = result.debits.concat(customDebits);
                return result;
            }
        );
    }

    protected setResult(result: CompensationCalculatorResult): void {
        this.warningMessages = [];

        const amountPerDebtor = {
            [Debtor.RESTAURANT]: 0,
            [Debtor.DELIVERY_COMPANY]: 0,
            [Debtor.DELIVERY_DRIVER]: 0,
            [Debtor.HUNGRIG]: 0
        };

        for (const debit of result.debits) {
            amountPerDebtor[debit.debtor] += debit.amount;
        }

        for (const debtor in amountPerDebtor) {
            if (amountPerDebtor[debtor] > this.order.amount) {
                this.warningMessages.push(
                    this.translateService.instant(`order_compensation.debtor_debit_value_too_high.${debtor}`)
                );
            }
        }

        this.result = result;
    }

    protected markFormAsTouched(): void {
        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        }
    }
}
