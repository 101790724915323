import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'button-active',
    template: `
        <button class="button-active btn btn-default"
                type="button"
                [class.active]="active"
                [class.white]="white"
                (click)="action.emit($event)" 
                #button>
            <span class="button-active-content" #content>
                <i class="fa fa-check-square-o" aria-hidden="true" *ngIf="active"></i>
                <i class="fa fa-square-o" aria-hidden="true" *ngIf="! active"></i>
                
                <ng-content></ng-content>
            </span>
        </button>
    `
})
export class ButtonActiveComponent {

    @Input()
    public active: boolean = false;
    @Input()
    public white: boolean = false;

    @Output()
    public action: EventEmitter<any>;

    constructor() {
        this.action = new EventEmitter();
    }
}
