import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { DefaultTranslations } from './default-translations';

export class HungrigMissingTranslationHandler implements MissingTranslationHandler {

    constructor(protected defaultTranslations: DefaultTranslations) {}

    public handle(params: MissingTranslationHandlerParams) {
        if (typeof this.defaultTranslations.defaults[params.key] !== 'undefined') {
            return this.defaultTranslations.defaults[params.key];
        }

        return '*';
    }
}
