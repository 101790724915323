import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryOrder, DeliveryOrderAction } from '../../interfaces';
import { DeliveryOrderActionType } from '../../enums';
import { DialogService } from '@ro-ngx/core';
import { filter, switchMap } from 'rxjs/operators';
import { DeliveryOrderActionService } from '../../services';

@Component({
    selector: 'delivery-order-actions',
    template: require('./delivery-order-actions.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        '.delete-order-action { cursor: pointer; }',
        '.dl-order-actions { margin-bottom: 10px; }'
    ]
})
export class DeliveryOrderActionsComponent {

    @Input()
    public deliveryOrder: DeliveryOrder;

    @Output()
    public onUpdate: EventEmitter<DeliveryOrderAction[]> = new EventEmitter<DeliveryOrderAction[]>();

    public actionTypeTranslateKeys: {[type: number]: string} = {
        [DeliveryOrderActionType.CheckIn]: 'delivery_order_action.type.check_in',
        [DeliveryOrderActionType.Delivered]: 'delivery_order_action.type.delivered',
        [DeliveryOrderActionType.Finished]: 'delivery_order_action.type.finished',
        [DeliveryOrderActionType.Accepted]: 'delivery_order_action.type.accepted'
    };

    constructor(
        protected deliveryOrderActionService: DeliveryOrderActionService,
        protected translateService: TranslateService,
        protected dialogService: DialogService,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public getActionTypeTitle(type: DeliveryOrderActionType): string {
        return this.translateService.instant(this.actionTypeTranslateKeys[type]);
    }

    public getActionGoogleMapsUrl(action: DeliveryOrderAction): string {
        return `https://maps.google.com/maps?q=loc:${action.latitude},${action.longitude}`;
    }

    public deleteAction(orderAction: DeliveryOrderAction): void {
        this.dialogService.confirm({
                title: this.translateService.instant('delivery_order_action.confirm_delete.title'),
                body: this.translateService.instant('delivery_order_action.confirm_delete.body'),
                accent: 'danger'
            })
            .pipe(
                filter((ok) => ok),
                switchMap(
                    () => this.deliveryOrderActionService.deleteAction(
                        orderAction.deliveryOrderID, orderAction.dOrderActionID
                    )
                )
            )
            .subscribe(() => {
                const index = this.deliveryOrder.deliveryorderactions.findIndex(
                    (existingAction) => existingAction.dOrderActionID === orderAction.dOrderActionID
                );

                if (index !== -1) {
                    this.deliveryOrder.deliveryorderactions.splice(index, 1);
                    this.onUpdate.emit(this.deliveryOrder.deliveryorderactions);
                    this.changeDetectorRef.markForCheck();
                }
            });
    }

    public onActionCreated(orderAction: DeliveryOrderAction): void {
        this.deliveryOrder.deliveryorderactions.push(orderAction);
        this.onUpdate.emit(this.deliveryOrder.deliveryorderactions);
        this.changeDetectorRef.markForCheck();
    }
}
