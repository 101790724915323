import { Routes } from '@angular/router';
import { ManagePageComponent } from './containers/manage-page/manage-page.component';

export const deliveryManageRoutes: Routes = [
    {
        path: '',
        component: ManagePageComponent,
    },
    {
        path: ':deliveryDistrictID/:deliveryOrderID',
        component: ManagePageComponent,
    },
    {
        path: ':deliveryDistrictID',
        component: ManagePageComponent,
    },
];
