import { Paginate } from './paginate';
import { ResponseFactory } from '../response-factory';
import { Response as NgxResponse } from '@angular/http';

export class PaginateFactory<T> {
    public static from<T>(ngxResponse: NgxResponse): Paginate<T> {
        let response = ResponseFactory.fromResponse<T>(ngxResponse);

        let paginateResponse = new Paginate<T>();

        paginateResponse.meta = {
            currentPage: response.getMeta('currentPage'),
            from: response.getMeta('from'),
            lastPage: response.getMeta('lastPage'),
            perPage: response.getMeta('perPage'),
            to: response.getMeta('to'),
            total: response.getMeta('total')
        };

        paginateResponse.data = response.toData() as T;

        return paginateResponse;
    }
}
