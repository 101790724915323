import { differenceInMinutes, format } from 'date-fns';
import { ManageOrderDriverDelivery } from './models/manage-order-driver-delivery.models';
import { ManageOrderBase } from './models/manage-order-base.models';

export const formatPickupTimeAbsolute = (date: Date | string): string => {
    return format(date, 'HH:mm');
}

export const formatPickupTimeRelative = (date: Date, refDate: Date): string => {
    const value = differenceInMinutes(refDate, date);
    return `${value}min`;
}

export const isOrderPositionable = (order: ManageOrderBase | ManageOrderDriverDelivery): boolean => {
    // if all geo cords are non-zeros
    const { destLat, destLon, originLat, originLon } = order;
    return !!destLat && !!destLon && !!originLat && !!originLon;
}
