import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompensationCredit } from '../../interfaces';
import { CompensationUtilService } from '../../services';
import { RefundType, ValueType } from '../../enums';
import { Observable } from 'rxjs';

@Component({
    selector: 'compensation-credit-table',
    template: require('./compensation-credit-table.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        '.dl-horizontal > dt { text-align: left; font-weight: normal; }',
        '.dl-horizontal > dd { text-align: right; }'
    ]
})
export class CompensationCreditTableComponent {
    @Input()
    public credits: CompensationCredit[];

    constructor(
        protected util: CompensationUtilService
    ) {
    }

    public getRefundTypeTitle(refundType: RefundType): Observable<string> {
        return this.util.getRefundTypeTitle(refundType);
    }

    public formatValue(value: number, valueType: ValueType): string {
        return this.util.formatValue(value, valueType);
    }
}
