import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '@ro-ngx/orders';
import { Observable ,  Subject } from 'rxjs';
import { CompensationCalculatorState, CompensationStepState } from './compensation-calculator.state';
import { CompensationStep } from './enums';
import { Compensation } from '../../interfaces';
import { takeUntil } from 'rxjs/operators';
import { CompensationCalculatorConfig } from './compensation-calculator-config';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'compensation-calculator',
    template: require('./compensation-calculator.component.html'),
    providers: [ CompensationCalculatorState ],
    styles: [ '.nav.nav-tabs { margin-bottom: 15px; }']
})
export class CompensationCalculatorComponent extends BaseComponent implements OnInit {
    @Input()
    public order: Order;

    @Input()
    public compensation: Compensation;

    @Input()
    public config: CompensationCalculatorConfig;

    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onComplete: EventEmitter<void> = new EventEmitter<void>();

    public steps$: Observable<CompensationStepState[]>;

    public step$: Observable<CompensationStepState>;

    public CompensationStep: typeof CompensationStep = CompensationStep;

    constructor(
        protected state: CompensationCalculatorState
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ngUnsubscribe = new Subject<void>();

        this.steps$ = this.state.steps$;
        this.step$ = this.state.step$;

        this.state.onClose$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.onCancel.emit());

        this.state.onComplete$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.onComplete.emit());

        if (this.config) {
            // TODO: use ngOnChanges
            this.state.setConfig(this.config);
        }
    }

    public reset(): void {
        this.state.reset();
    }

    public setStep(state: CompensationStepState): void {
        if (! state.complete) {
            return;
        }

        this.state.setStep(state.step);
    }
}
