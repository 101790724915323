import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManageOrderDriver } from '../../models/manage-order-driver.models';
import {  ManageOrderTransportMode } from '../../models/driver.models';
import { ManageOrderBase } from '../../models/manage-order-base.models';

@Component({
    selector: 'driver-list-item',
    template: require('./driver-list-item.component.html'),
    styles: [require('./driver-list-item.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverListItemComponent {
    @Input() public driver: ManageOrderDriver;
    @Input() public hoveredOrderIDs: number[] = [];
    @Output() public driverClick: EventEmitter<void> = new EventEmitter();
    @Output() public hoverOrder: EventEmitter<number> = new EventEmitter();

    constructor(
        private translate: TranslateService,
    ) {
    }

    get transportModeIconSrc(): string {
        return this.driver.transportMode === ManageOrderTransportMode.Car
            ? '/assets/@ro-ngx/delivery/icon-car.png'
            : '/assets/@ro-ngx/delivery/icon-bicycle.png';
    };

    get unpositionedTitleAttr(): string {
        return !this.driver.driverPositions
            ? this.translate.instant('delivery.manage.drivers.position_failed_title')
            : null;
    }

    public deliveryTrackFn(index: number, order: ManageOrderBase): number {
        return order.deliveryOrderID;
    }

    public isHovered(id: number): boolean {
        return this.hoveredOrderIDs.indexOf(id) > -1;
    }
}
