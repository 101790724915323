import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CouponGroup } from '../../../../interfaces';

@Injectable()
export class CouponGroupState {
    public couponGroup$: Observable<CouponGroup>;

    protected couponGroup: BehaviorSubject<CouponGroup>;

    constructor() {
        this.couponGroup = new BehaviorSubject<CouponGroup>(null);
        this.couponGroup$ = this.couponGroup.asObservable();
    }

    public setCouponGroup(couponGroup: CouponGroup): void {
        this.couponGroup.next(couponGroup);
    }
}
