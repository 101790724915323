import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Order, OrderRow } from '@ro-ngx/orders';
import { flatten } from 'lodash';
import { CompensationsConfig } from '../../../compensations-config';
import { BaseComponent } from '../../../base.component';

export interface CartInputEntry {
    form: FormGroup;
    orderRow: OrderRow;
}

export interface CartRowForm {
    rowID: number;
    quantity: number;
    price: number;
    amount: number;
    deliveryPriceAdd: number;
    taxRate: number;
    title: string;
}

@Component({
    selector: 'cart-input',
    template: require('./cart-input.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CartInputComponent),
            multi: true
        }
    ]
})
export class CartInputComponent extends BaseComponent implements OnInit, ControlValueAccessor {
    public forms: FormArray;

    @Input()
    public order: Order;

    public entries: CartInputEntry[] = [];

    protected orderRows: OrderRow[];

    protected onChange: any;

    constructor(
        protected formBuilder: FormBuilder,
        protected config: CompensationsConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.orderRows = flatten(
            this.order.orderrows.map((orderRow) => {
                if (orderRow.extras.length > 0) {
                    return [
                        orderRow,
                        ...orderRow.extras
                    ];
                }

                return orderRow;
            })
        );
    }

    public writeValue(cartRows: CartRowForm[]): void {
        if (cartRows) {
            this.entries = cartRows.map((cartRow) => this.createEntry(cartRow));
            this.forms = this.formBuilder.array(this.entries.map((entry) => entry.form));
            this.forms.valueChanges.subscribe((value) => {
                if (this.onChange) {
                    this.onChange(value);
                }
            });
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // tslint:disable
    public registerOnTouched(fn: any): void {
    }
    // tslint:enable

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.forms.disable();
        } else {
            this.forms.enable();
        }
    }

    public addCustomCartRow(): void {
        const form = this.formBuilder.group({
            rowID: null,
            quantity: 0,
            price: 0,
            amount: null,
            deliveryPriceAdd: null,
            taxRate: this.config.taxRates[0].value,
            title: null
        });

        this.entries.push({
            form,
            orderRow: null
        });

        this.forms.push(form);
    }

    protected createEntry(formData: CartRowForm): CartInputEntry {
        const orderRow = this.findOrderRow(formData.rowID);
        const form = this.formBuilder.group(formData);

        return {
            orderRow,
            form
        };
    }

    protected findOrderRow(rowID: number): OrderRow {
        return this.orderRows.find((orderRow) => orderRow.rowID === rowID);
    }
}
