import { TranslateParser } from '@ngx-translate/core';
import { DefaultTranslations } from './default-translations';
import { HungrigTranslateParser } from './hungrig-translate.parser';

export const defaultTranslate = {
    parser: {
        provide: TranslateParser,
        useFactory: (defaultTranslations: DefaultTranslations) => new HungrigTranslateParser(defaultTranslations),
        deps: [DefaultTranslations]
    }
};
