
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, Paginate, ErrorFactory, PaginateFactory, ParamsUtil } from '@ro-ngx/core';
import { Coupon, CouponGroup } from '../interfaces';
import { Observable } from 'rxjs';
import { ResponseContentType } from '@angular/http';

@Injectable()
export class CouponService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroup(groupId: number): Observable<CouponGroup> {
        const url = `${this.hungrigClient.baseUrl}/cms/coupongroups/${groupId}`;
        return this.get(url);
    }

    public getGroups(options: GetGroupsOptions): Observable<Paginate<CouponGroup[]>> {
        const search = ParamsUtil.toHttpParams(options);
        const url = `${this.hungrigClient.baseUrl}/cms/coupongroups`;
        return this.hungrigClient.get(url, { search }).pipe(
            map(PaginateFactory.from),
            catchError(ErrorFactory.throwObservable));
    }

    public createGroup(group: Partial<CouponGroup>): Observable<CouponGroup> {
        const url = `${this.hungrigClient.baseUrl}/cms/coupongroups`;
        return this.post(url, group);
    }

    public getCoupons(options: GetCouponsOptions): Observable<Paginate<Coupon[]>> {
        const url = `${this.hungrigClient.baseUrl}/cms/coupons`;
        const search = ParamsUtil.toHttpParams(options);
        return this.hungrigClient.get(url, { search }).pipe(
            map(PaginateFactory.from),
            catchError(ErrorFactory.throwObservable));
    }

    public getGroupCouponsCsv(groupId: number): Observable<Blob> {
        const url = `${this.hungrigClient.baseUrl}/cms/coupongroups/${groupId}/export`;
        return this.hungrigClient.get(url, { responseType: ResponseContentType.Blob }).pipe(
            map((response) => response.blob()));
    }

    public createCoupon(coupon: Partial<Coupon>): Observable<Coupon> {
        return this.post(`${this.hungrigClient.baseUrl}/cms/coupons`, coupon);
    }

    public updateCoupon(couponID: number, coupon: Partial<Coupon>): Observable<Coupon> {
        return this.put(`${this.hungrigClient.baseUrl}/cms/coupons/${couponID}`, coupon);
    }

    public deleteCoupon(couponId: number): Observable<boolean> {
        const url = `${this.hungrigClient.baseUrl}/cms/coupons/${couponId}`;
        return this.delete(url);
    }
}

export interface GetGroupsOptions {
    page?: number;
    filter?: string;
    clientKey?: string[];
}

export interface GetCouponsOptions {
    page?: number;
    filter?: string;
    clientKey?: string[];
    couponGroupID?: number[];
}
