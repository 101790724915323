import { ModuleWithProviders, NgModule } from '@angular/core';
import { CurrencySupport } from './currency-support';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyTextPipe } from './currency-text.pipe';
import { CurrencyInputComponent } from './currency-input.component';
import { CommonModule } from '@angular/common';
import { CurrencyFormatPipe } from './currency-format.pipe';

export interface CurrencyModuleConfig {
    currency: string;
    decimalPoints: number;
    multiplier: number;

}

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        CurrencyTextPipe,
        CurrencyFormatPipe,
        CurrencyInputComponent
    ],
    declarations: [
        CurrencyTextPipe,
        CurrencyFormatPipe,
        CurrencyInputComponent
    ],
    providers: [],
    entryComponents: [
        CurrencyInputComponent
    ]
})
export class CurrencyModule {
    public static forRoot(config: CurrencyModuleConfig): ModuleWithProviders {
        return {
            ngModule: CurrencyModule,
            providers: [
                {
                    provide: CurrencySupport,
                    useFactory: (translateService: TranslateService) => {
                        const radix = 10;

                        return new CurrencySupport(
                            config.currency,
                            parseInt(config.decimalPoints.toString(), radix),
                            parseInt(config.multiplier.toString(), radix),
                            translateService
                        );
                    },
                    deps: [TranslateService]
                }
            ]
        };
    }
}
