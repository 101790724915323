import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { ConfirmDialog } from './confirm-dialog';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm-dialog',
    template: `
        <div bsModal [config]="{ backdrop: 'static' }" (onHide)="cancel()"
             class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">{{ dialog.options.title }}</h4>
                    </div>
                    <div *ngIf="dialog.options.body" class="modal-body" style="white-space: pre-wrap;">{{ dialog.options.body }}</div>
                    <div class="modal-footer">
                        <button (click)="cancel()" class="btn btn-default">
                            {{ dialog.options.cancel ||  ('general.cancel' | translate) }}
                        </button>
                        <button (click)="ok()" class="btn" [ngClass]="dialog.options.accent ? 'btn-' + dialog.options.accent : 'btn-success'">
                            {{ dialog.options.ok || ('general.ok' | translate) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ConfirmDialogComponent implements DialogComponent, AfterViewInit {
    @Input() public dialog: ConfirmDialog;

    @ViewChild(ModalDirective) public modal: ModalDirective;

    public cancel(): void {
        this.dialog.complete(false);
    }

    public ok(): void {
        this.dialog.complete(true);
    }

    public ngAfterViewInit(): void {
        this.modal.show();
    }
}
