import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DeliveryDistrict } from '../../../../interfaces/delivery-district';
import { IAcceptHurrierDelayEvent } from '../../../../interfaces/delivery-times';
import { generateRange } from '../../utils';

@Component({
    selector: 'modal-delivery-times-hurrier-delay-edit',
    template: require('./modal-delivery-times-hurrier-delay-edit.component.html'),
    styles: [require('./modal-delivery-times-hurrier-delay-edit.component.less')],
})
export class ModalDeliveryTimesHurrierDelayEditComponent {
    @ViewChild('modal')
    public modal: ModalDirective;

    @Output()
    public ok: EventEmitter<IAcceptHurrierDelayEvent> = new EventEmitter();

    public item: DeliveryDistrict;
    public form: FormGroup;
    public timeRanges: number[];

    constructor(
        fb: FormBuilder,
    ) {
        this.form = fb.group({
            customFleetMinutesDelay: 0,
        });

        this.timeRanges = (() => {
            const STARTAT = -5;
            const ENDAT = 30;
            const STEP = 1;
            return generateRange(STARTAT, ENDAT, STEP);
        })();
    }

    public open(item: DeliveryDistrict): void {
        this.item = item;
        this.form.setValue({ customFleetMinutesDelay: +item.settings.customFleetMinutesDelay || 0 });
        this.modal.show();
    }

    public close(): void {
        this.modal.hide();
    }

    public complete(): void {
        const { form, item } = this;
        if (form.valid) {
            this.ok.emit({ formState: form.value, item });
        }
    }

    public displayRange(range: number): string {
        return `${range} min`;
    }
}
