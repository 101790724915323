import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { DefaultTranslations } from './default-translations';

@Injectable()
export class HungrigTranslateParser extends TranslateDefaultParser {

    constructor(protected defaults: DefaultTranslations) {
        super();
    }

    public interpolate(expr: string, params?: any): string {
        params = params ? Object.assign({}, params, this.defaults.defaults) : this.defaults.defaults;

        return super.interpolate(expr, params);
    }
}
