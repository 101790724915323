import { Injectable } from '@angular/core';
import { DeliveryDriver } from '../interfaces';
import { Observable } from 'rxjs';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';

@Injectable()
export class DeliveryDriverService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public createDriver(driver: Partial<DeliveryDriver>): Observable<DeliveryDriver> {
        return this.post(`${this.hungrigClient.baseUrl}/delivery/driver`, driver);
    }

    public updateDriver(id: number, driver: Partial<DeliveryDriver>): Observable<DeliveryDriver> {
        return this.put(`${this.hungrigClient.baseUrl}/delivery/driver/${id}`, driver);
    }

    public getDrivers(options: GetDriversOptions): Observable<DeliveryDriver[]> {
        const search = ParamsUtil.toHttpParams(options);
        return this.get(`${this.hungrigClient.baseUrl}/delivery/driver`, { search });
    }
}

export interface GetDriversOptions {
    deliveryCompanyID?: number[];
    driverName?: string;
    isOnline?: boolean;
    limit?: number;
}
