import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompensationUtilService } from '../../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompensationCalculatorCreditResult } from '../../../../interfaces';
import { Observable } from 'rxjs';
import { RefundType, ValueType } from '../../../../enums';
import { BaseComponent } from '../../../../base.component';
import { CompensationCalculatorState } from '../../compensation-calculator.state';
import { map, takeUntil } from 'rxjs/operators';

export interface ConfirmCompensationCreditEntry {
    credit: CompensationCalculatorCreditResult;
    form: FormGroup;
}

@Component({
    selector: 'confirm-compensation-credits',
    template: require('./confirm-compensation-credits.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmCompensationCreditsComponent extends BaseComponent implements OnChanges, OnInit {
    @Input()
    public credits: CompensationCalculatorCreditResult[];

    public entries: ConfirmCompensationCreditEntry[];

    public ValueType: typeof ValueType = ValueType;

    public advancedView$: Observable<boolean>;

    constructor(
        protected compensationCalculatorState: CompensationCalculatorState,
        protected compensationUtilService: CompensationUtilService,
        protected formBuilder: FormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.advancedView$ = this.compensationCalculatorState.config$
            .pipe(
                map((config) => config.advancedView),
                takeUntil(this.ngUnsubscribe)
            );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['credits']) {
            this.initEntries();
        }
    }

    public isValid(): boolean {
        return ! this.entries.some((entry) => entry.form.invalid);
    }

    public getCredits(): CompensationCalculatorCreditResult[] {
        return this.entries.map((entry) => {
            const value = entry.form.get('value').value;

            return {
                ...entry.credit,
                value
            };
        });
    }

    public getRefundTypeTitle(refundType: RefundType): Observable<string> {
        return this.compensationUtilService.getRefundTypeTitle(refundType);
    }

    protected initEntries(): void {
        this.entries = this.credits.map((credit) => {
            const form = this.formBuilder.group({
                value: [credit.value, Validators.required]
            });

            return {
                credit,
                form
            };
        });
    }
}
