import { User as UserContract, UserConnection as UserConnectionContract } from './interfaces';
import { PermissionName, RoleName, UserConnectionType } from './enums';
import { UserConnection } from './user-connection';

export class User implements UserContract {
    public userID: number;
    public firstName: string;
    public lastName: string;
    public email: string;

    public userConnections?: UserConnectionContract[];

    constructor(data: Partial<UserContract>) {
        Object.assign(this, data, {
            userConnections: data.userConnections
                ? data.userConnections.map((userConnection) => new UserConnection(userConnection)) : []
        });
    }

    public hasPermission(name: PermissionName): boolean {
        return !! this.userConnections.find((userConnection) => userConnection.hasPermission(name));
    }

    public hasPermissions(permissions: PermissionName[]): boolean {
        return !! this.userConnections.find((userConnection) => userConnection.hasPermissions(permissions));
    }

    public hasRole(name: RoleName): boolean {
        return !! this.userConnections.find((userConnection) => userConnection.hasRole(name));
    }

    public hasUserConnection(userConnectionType: UserConnectionType, id: number | string): boolean {
        return !! this.findUserConnection(userConnectionType, id);
    }

    public getUserConnectionType(userConnectionType: UserConnectionType): UserConnectionContract[] {
        return this.userConnections.filter((userConnection) => {
            return userConnection.type === userConnectionType;
        });
    }

    public findUserConnection(userConnectionType: UserConnectionType, id: number | string): UserConnectionContract {
        return this.userConnections.find((userConnection) => {
            return userConnection.type === userConnectionType && (userConnection.getId() === id || id === '*');
        });
    }
}
