import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { PaginateBody } from '../../http/response/hungrig/paginate/paginate-body';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TEMPLATE =
`<nav>
    <ul class="pagination">
        <li *ngFor="let page of pages" [class.active]="currentPage === page.number">
            <a (click)="setPage(page.number)">
                {{ page.text }}
            </a>
        </li>
    </ul>
</nav>`;

export interface Page {
    number: number;
    text: string;
}

export const PAGINATOR_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PaginatorComponent),
    multi: true
};

@Component({
    selector: 'ro-paginator',
    template: TEMPLATE,
    providers: [PAGINATOR_CONTROL_VALUE_ACCESSOR],
    styles: [
        '.pagination > li:not(.active) > a { cursor: pointer; }'
    ]
})
export class PaginatorComponent implements ControlValueAccessor {
    @Input()
    public set paginate(paginate: PaginateBody) {
        this._paginate = paginate;
        this.calculatePages();
    }

    @Input()
    public maxSize: number = 3;

    public currentPage: number = 1;
    public pages: Page[];
    public disabled: boolean;
    public _paginate: PaginateBody;
    protected onTouch: () => void;
    protected onChange: (_: any) => void;

    constructor() {}

    public setPage(page: number): void {
        this.writeValue(page);
        if (this.onChange) {
            this.onChange(page);
        }
    }

    public writeValue(page: any): void {
        if (page !== this.currentPage) {
            this.currentPage = page;
            this.calculatePages();
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    protected calculatePages(): void {
        const pages: Page[] = [];
        const isMaxSized = this.maxSize !== undefined && this.maxSize < this._paginate.lastPage;

        let start = 1;
        let end = this._paginate.lastPage;

        if (isMaxSized) {
            start = Math.max(1, this.currentPage - Math.floor(this.maxSize / 2));
            end = start + this.maxSize - 1;

            if (end > this._paginate.lastPage) {
                end = this._paginate.lastPage;
                start = end - this.maxSize + 1;
            }
        }

        for (let number = start; number <= end; number++) {
            pages.push({
                number,
                text: number.toString()
            });
        }

        if (isMaxSized) {
            if (start > this.maxSize - 1) {
                pages.unshift({
                    number: start - 1,
                    text: '...'
                });
            }

            if (start > 1) {
                pages.unshift({
                    number: 1,
                    text: '1'
                });
            }

            if (end < this._paginate.lastPage - 1) {
                pages.push({
                    number: end + 1,
                    text: '...'
                });
            }

            if (end < this._paginate.lastPage) {
                pages.push({
                    number: this._paginate.lastPage,
                    text: this._paginate.lastPage.toString()
                });
            }
        }

        this.pages = pages;
    }
}
