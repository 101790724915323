import { Component, Input } from '@angular/core';
import { CompensationDebit } from '../../interfaces';
import { Debtor } from '../../enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'compensation-debit-table',
    template: require('./compensation-debit-table.component.html'),
    styles: [
        '.dl-horizontal > dt { text-align: left; font-weight: normal; }',
        '.dl-horizontal > dd { text-align: right; }'
    ]
})
export class CompensationDebitTableComponent {
    @Input()
    public debits: CompensationDebit[];

    constructor(
        protected translateService: TranslateService
    ) {
    }

    public getDebtorName(debit: CompensationDebit): string {
        switch (debit.debtor) {
            case Debtor.DELIVERY_DRIVER:
                return debit.deliveryDriver.driverName;
            case Debtor.DELIVERY_COMPANY:
                return debit.deliveryCompany.companyName;
            case Debtor.RESTAURANT:
                return debit.restaurant.restaurantName;
            case Debtor.HUNGRIG:
                return this.translateService.instant('general.brand');
            default:
                return debit.debtor;
        }
    }
}
