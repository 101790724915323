import { ModuleWithProviders, NgModule } from '@angular/core';
import { PaymentService } from './services';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class OrdersModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: OrdersModule,
            providers: [
                PaymentService
            ]
        };
    }
}
