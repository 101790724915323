import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
    pure: true
})
export class CapitilizePipe implements PipeTransform {

    public transform(value: any): any {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }

        return value;
    }
}
