import { NotificationType } from "./notification-type";

export class Notification {
    public type: NotificationType;
    public title: string;
    public body: string;
    public classes: string[] = [];

    constructor(type: NotificationType, title: string, body: string = '', classes: string[] = []) {
        this.type = type;
        this.title = title;
        this.body = body;
        this.classes = classes;
    }
}
