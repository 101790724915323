import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompensationCalculatorCreditResult } from '../../../../interfaces';
import { RefundTypeOption, refundTypeOptions, ValueTypeOption, valueTypeOptions } from '../../../../common/options';
import { ValueType } from '../../../../enums';
import {startWith} from "rxjs/operators";

@Component({
    selector: 'configure-custom-credit',
    template: require('./configure-custom-credit.component.html')
})
export class ConfigureCustomCreditComponent implements OnInit {
    @Input()
    public customCredit: CompensationCalculatorCreditResult;

    public valueTypeOptions: ValueTypeOption[] = valueTypeOptions;

    public refundTypeOptions: RefundTypeOption[] = refundTypeOptions;

    public form: FormGroup;

    public refundType = 'money_back';

    public ValueType: typeof ValueType = ValueType;

    constructor(
        protected formBuilder: FormBuilder
    ) {
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            reason: [null, Validators.required],
            value: [null, Validators.required],
            valueType: [null, Validators.required],
            refundType: [this.refundType, Validators.required],
        });

        const valueTypeCtrl = this.form.get('valueType');
        const valueCtrl = this.form.get('value');

        valueTypeCtrl.valueChanges.pipe(
            startWith(valueTypeCtrl.value)
        )
            .subscribe((valueType) => {
                if (valueType === null) {
                    valueCtrl.disable();
                } else if (valueCtrl.disabled) {
                    valueCtrl.enable();
                }
            });

        this.form.patchValue(this.customCredit);
    }

    get valueTypeCtrl(): AbstractControl {
        return this.form.get('valueType');
    }

    public isValid(): boolean {
        return this.form.valid;
    }

    public getValue(): any {
        return this.form.value;
    }
}
