import { Observable ,  Subject } from 'rxjs';
import { Type } from '@angular/core';
import { DialogContract } from '../dialog.contract';

export interface PromptDialogOptions {
    readonly title: string;
    readonly label: string;
    readonly prefill?: string;
    readonly ok?: string;
    readonly cancel?: string;
    readonly accent?: 'warning' | 'danger' | 'success' | 'primary' | 'default';
}

export class PromptDialog implements DialogContract<string> {
    public result$: Observable<string>;
    protected result: Subject<string>;

    public constructor(
        public readonly component: Type<any>,
        public options: PromptDialogOptions
    ) {
        this.result = new Subject<string>();
        this.result$ = this.result.asObservable();
    }

    public complete(result: string): void {
        this.result.next(result);
        this.result.complete();
    }
}
