import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CouponFormComponent } from './coupon-form.component';
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder } from '@angular/forms';
import { DialogService, NotificationStorage, CurrencySupport } from '@ro-ngx/core';
import { Coupon } from '../../../interfaces';
import { CouponService } from '../../../services';
import { CouponTemplateService } from '../template';
import { CampaignGroupService } from '../../../services/campaign-group.service';
import { filter, finalize, flatMap } from 'rxjs/operators';
import { CouponManagerConfig } from '../coupon-manager-config';

@Component({
    selector: 'edit-coupon',
    template: require('./coupon-form.component.html')
})
export class EditCouponComponent extends CouponFormComponent {
    @Input() public coupon: Coupon;
    @Output() public onUpdate: EventEmitter<Coupon> = new EventEmitter<Coupon>();
    @Output() public onRemove: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        protected couponService: CouponService,
        protected templateService: CouponTemplateService,
        protected campaignGroupService: CampaignGroupService,
        protected translateService: TranslateService,
        protected dialogService: DialogService,
        protected notificationStorage: NotificationStorage,
        protected currencySupport: CurrencySupport,
        protected formBuilder: FormBuilder,
        public config: CouponManagerConfig
    ) {
        super(campaignGroupService, templateService, translateService, currencySupport, formBuilder, config);
        this.isNew = false;
    }

    public save(): void {
        this.saving$.next(true);

        this.couponService.updateCoupon(this.coupon.couponID, this.getValue())
            .pipe(
                finalize(() => this.saving$.next(false))
            )
            .subscribe((coupon) => {
                this.onUpdate.emit(coupon);
                this.setCoupon(coupon);
                this.notificationStorage.success(this.translateService.instant('general.saved'));
            }, (error) => {
                const alreadyExists: number = 409;
                if (error.statusCode === alreadyExists) {
                    this.dialogService.notify(this.translateService.instant('coupon.coupon_already_exists_dialog'))
                        .subscribe();
                }
            });
    }

    public remove(): void {
        this.deleting$.next(true);

        this.dialogService.confirm({
                ...this.translateService.instant('dialog.confirm_delete'),
                accent: 'danger'
            })
            .pipe(
                filter((confirm) => confirm),
                flatMap(() => this.couponService.deleteCoupon(this.coupon.couponID)),
                finalize(() => this.deleting$.next(false))
            )
            .subscribe(() => {
                this.onRemove.emit();
                this.notificationStorage.success(this.translateService.instant('general.success'));
            });
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.setCoupon(this.coupon);
    }
}
