import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { differenceInMinutes } from "date-fns";

const ORDER_SEVERITY_NORMAL_CLASS = 'severity-normal';
const ORDER_SEVERITY_WARNING_CLASS = 'severity-warning';
const ORDER_SEVERITY_DANGER_CLASS = 'severity-danger';

export const getOrderSeverity = (duePickupDateString: string): string => {
    /*
        Unassigned orders:
        RED: < 10 min
        ORANGE: < 20 min
        // YELLOW >= 20 min // agreed on no color with FW
        (never green)
    */

    const MAX_FOR_DANGER = 10;
    const MAX_FOR_WARNING = 20;
    const dateNow = Date.now();
    const mmLeft = differenceInMinutes(new Date(duePickupDateString), dateNow);

    if (mmLeft < MAX_FOR_DANGER) {
        return ORDER_SEVERITY_DANGER_CLASS;
    } else if (mmLeft < MAX_FOR_WARNING) {
        return ORDER_SEVERITY_WARNING_CLASS;
    } else {
        return ORDER_SEVERITY_NORMAL_CLASS;
    }
};

/*
    Colors an element in response to time left to a reference time.
*/
@Directive({
    selector: '[orderSeverity]',
})
export class OrderSeverityDirective {
    constructor(
        private readonly renderer: Renderer2,
        private readonly elRef: ElementRef,
    ) {
    }

    @Input('orderSeverity')
    public set order(pickupTime: string) {
        const el = this.elRef.nativeElement;

        // Remove possible previous class
        [ORDER_SEVERITY_NORMAL_CLASS, ORDER_SEVERITY_WARNING_CLASS, ORDER_SEVERITY_DANGER_CLASS]
            .forEach((klass) => {
                this.renderer.removeClass(el, klass);
            });

        // Apply new class
        this.renderer.addClass(el, getOrderSeverity(pickupTime));
    }
}
