export type EnvValue = string | number | boolean;
export type EnvStorage = { [key: string]: EnvValue };

export class Env {

    /**
     * Key value storage for env.
     */
    protected static env: EnvStorage;

    /**
     * Get environmnet variable.
     *
     * @param {string} key
     * @return {EnvValue}
     */
    public static get(key: string): EnvValue {
        if (typeof Env.env === 'undefined') {
            Env.env = Env.fetchEnv();
        }

        if ( ! Env.env.hasOwnProperty(key)) {
            throw new Error(`Missing env variable: '${key}'`);
        }

        return Env.env[key];
    }

    /**
     * Fetch environment from element with id of env.
     *
     * @return {EnvStorage}
     */
    protected static fetchEnv(): EnvStorage {
        if (typeof window['env'] === 'undefined') {
            const element = document.getElementById('env');
            if (element === null) throw new Error(`Missing element with ID: 'env'.`);

            return JSON.parse(element.innerHTML);
        }

        return window['env'];
    }
}
