import { Order, OrderRow } from './interfaces';

/**
 * @dynamic
 */
export class OrderSummary {
    public rows: {
        totalQuantity: number,
        totalWithoutDeliveryPrice: number,
        totalPrice: number,
        totalPcsPrice: number,
        totalDeliveryValue: number
    } = {
        totalQuantity: 0,
        totalWithoutDeliveryPrice: 0,
        totalPrice: 0,
        totalPcsPrice: 0,
        totalDeliveryValue: 0,
    };

    public totalWithoutDeliveryPrice: number = 0;
    public totalDeliveryValue: number = 0;
    public totalPrice: number = 0;

    public static fromOrder(order: Order): OrderSummary {
        return {
            rows: {
                totalQuantity: OrderSummary.getOrderRowTotalQuantity(order.orderrows),
                totalWithoutDeliveryPrice: OrderSummary.getOrderRowsTotalWithoutDeliveryPrice(order.orderrows),
                totalPrice: OrderSummary.getOrderRowsTotal(order.orderrows),
                totalPcsPrice: OrderSummary.getOrderRowsPcsTotalValue(order.orderrows),
                totalDeliveryValue: OrderSummary.getOrderRowsDeliveryTotalValue(order.orderrows)
            },
            totalWithoutDeliveryPrice: OrderSummary.getOrderTotalWithoutDeliveryPrice(order),
            totalDeliveryValue: OrderSummary.getOrderDeliveryTotalValue(order),
            totalPrice: OrderSummary.getOrderTotal(order)
        };
    }

    public static getOrderTotalWithoutDeliveryPrice(order: Order): number {
        return OrderSummary.getOrderRowsTotalWithoutDeliveryPrice(order.orderrows) -
            order.couponValue +
            ['hungrigFee', 'minimumProductAmountDifference', 'invoiceFee', 'deliveryPrice', 'deliveryDiscount']
                .map((column) => order[column])
                .reduce((totalValue, value) => totalValue + value, 0);
    }

    public static getOrderDeliveryTotalValue(order: Order): number {
        return OrderSummary.getOrderRowsDeliveryTotalValue(order.orderrows) - order.deliveryDiscount;
    }

    public static getOrderTotal(order: Order): number {
        return OrderSummary.getOrderTotalWithoutDeliveryPrice(order) + OrderSummary.getOrderDeliveryTotalValue(order);
    }

    public static getOrderRowTotalQuantity(orderRows: OrderRow[]): number {
        return orderRows.map((orderRow) => orderRow.quantity)
            .reduce((totalPrice, orderRowQuantity) => totalPrice + orderRowQuantity, 0);
    }

    public static getOrderRowTotalValueWithoutDeliveryPriceAdd(orderRow: OrderRow): number {
        return (orderRow.totalPrice - orderRow.deliveryPriceAdd) * orderRow.quantity;
    }

    public static getOrderRowTotalValue(orderRow: OrderRow): number {
        return orderRow.totalPrice * orderRow.quantity;
    }

    public static getOrderRowsTotalWithoutDeliveryPrice(orderRows: OrderRow[]): number {
        return orderRows.map((orderRow) => OrderSummary.getOrderRowTotalValueWithoutDeliveryPriceAdd(orderRow))
            .reduce((totalPrice, orderRowTotalPrice) => totalPrice + orderRowTotalPrice, 0);
    }

    public static getOrderRowsTotal(orderRows: OrderRow[]): number {
        return orderRows.map((orderRow) => OrderSummary.getOrderRowTotalValue(orderRow))
            .reduce((totalPrice, orderRowTotalPrice) => totalPrice + orderRowTotalPrice, 0);
    }

    public static getOrderRowsPcsTotalValue(orderRows: OrderRow[]): number {
        return orderRows.map((orderRow) => OrderSummary.getOrderRowPcsTotalValue(orderRow))
            .reduce((totalPrice, orderRowTotalPrice) => totalPrice + orderRowTotalPrice, 0);
    }

    public static getOrderRowPcsTotalValue(orderRow: OrderRow): number {
        return orderRow.price;
    }

    public static getOrderRowsDeliveryTotalValue(orderRows: OrderRow[]): number {
        return orderRows.map((orderRow) => OrderSummary.getOrderRowDeliveryValue(orderRow))
            .reduce((totalPrice, orderRowTotalPrice) => totalPrice + orderRowTotalPrice, 0);
    }

    public static getOrderRowDeliveryValue(orderRow: OrderRow): number {
        return orderRow.deliveryPriceAdd * orderRow.quantity;
    }
}
