import { Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CouponGroupsState {
    public refresh$: Observable<void>;

    protected refresh: Subject<void>;

    constructor() {
        this.refresh = new Subject<void>();
        this.refresh$ = this.refresh.asObservable();
    }

    public doRefresh(): void {
        this.refresh.next();
    }
}
