import { Injectable } from '@angular/core';
import { LocaleConfig } from './locale-config';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Country } from './country';
import { Language } from './language';

@Injectable()
export class LocaleService {
    public country$: Observable<Country>;
    public language$: Observable<Language>;

    protected country: BehaviorSubject<Country>;
    protected language: BehaviorSubject<Language>;

    constructor(
        protected config: LocaleConfig
    ) {
        this.country = new BehaviorSubject<Country>(config.country);
        this.country$ = this.country.asObservable();
        this.language = new BehaviorSubject<Language>(config.language);
        this.language$ = this.language.asObservable();
    }

}
