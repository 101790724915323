export const ENV = {
    ENV: process.env.ENV,
    DEBUG: process.env.DEBUG,
    API_LOCATION: process.env.API_LOCATION,
    CMS_LOCATION: process.env.CMS_LOCATION,
    KONTROLL_LOCATION: process.env.KONTROLL_LOCATION,
    BASE_URL: process.env.BASE_URL,
    BASE_HREF: process.env.BASE_HREF,
    BASE_SITE: process.env.BASE_SITE,
    LIVERELOAD: process.env.LIVERELOAD,
    HEADER_ACCEPT: process.env.HEADER_ACCEPT,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    MAP_BOX_ACCESS_TOKEN: process.env.MAP_BOX_ACCESS_TOKEN,
    AUTH_CLIENT_ID: process.env.AUTH_CLIENT_ID,
    AUTH_CLIENT_SECRET: process.env.AUTH_CLIENT_SECRET,
    APP_VERSION: process.env.APP_VERSION,
    APP_BRAND: process.env.APP_BRAND,
    APP_LANGUAGE: process.env.APP_LANGUAGE,
    APP_CURRENCY: process.env.APP_CURRENCY,
    APP_CURRENCY_MULTIPLIER: process.env.APP_CURRENCY_MULTIPLIER,
    APP_COUNTRY: process.env.APP_COUNTRY,
    APP_WHITE_LABEL: process.env.APP_WHITE_LABEL,
    CURRENCY_DECIMAL_POINTS: process.env.CURRENCY_DECIMAL_POINTS,
    TAX_RATES: process.env.TAX_RATES
};
