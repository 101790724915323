import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencySupport } from './currency-support';

const TEMPLATE = `
<div [ngClass]="{ 'input-group': showCurrencySymbol }">
    <input #inputRef
        class="form-control"
        [ngClass]="inputClasses"
        (change)="onInputChange(inputRef.value)"
        (keyup)="onInputChange(inputRef.value)"
        (mouseup)="onInputChange(inputRef.value)"
        (blur)="onTouch()"
        [disabled]="disabled"
        [min]="min"
        [max]="max"
        type="number"
        [placeholder]="placeholder"
        [attr.readonly]="readOnly ? 'readonly' : null">
    <span *ngIf="showCurrencySymbol" class="input-group-addon">{{ currencySymbol }}</span>
</div>
`;

export const CURRENCY_INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true
};

export type TouchListener = () => void;
export type ChangeListener = (_: any) => void;

@Component({
    selector: 'currency-input',
    template: TEMPLATE,
    providers: [CURRENCY_INPUT_VALUE_ACCESSOR]
})
export class CurrencyInputComponent implements OnInit, ControlValueAccessor {
    /**
     * Whether to force the value to be unsigned, i.e. a positive number. Defaults to false.
     * @type {boolean}
     */
    @Input()
    public set unsigned(isUnsigned: boolean) {
        this.min = isUnsigned ? 0 : null;
    }

    /**
     * Minimum input value.
     * @type {number}
     */
    @Input() public min: number = null;

    /**
     * Maximum input value.
     * @type {number}
     */
    @Input() public max: number = null;

    /**
     * Whether to show the currency symbol. Defaults to true.
     * @type {boolean}
     */
    @Input('symbol') public showCurrencySymbol: boolean = true;
    @Input('customsymbol') public customSymbol: string;

    /**
     * Input placeholder.
     * @type {string}
     */
    @Input() public placeholder: string = '';

    @Input() public inputClasses: string[] = [];

    @Input() public readOnly: boolean = false;

    @ViewChild('inputRef',{static:true}) public inputRef: ElementRef;
    public value: number;
    public disabled: boolean;
    public currencySymbol: string;
    protected onChangeListener: ChangeListener[] = [];
    protected onTouchListener: TouchListener[] = [];

    constructor(protected currencySupport: CurrencySupport) {}

    public onInputChange(value: string): void {
        let newValue: number = parseFloat(value);
        if (isNaN(newValue)) {
            newValue = null;
        }
        this.value = Math.round(this.currencySupport.multiply(newValue));
        this.onChange();
    }

    public ngOnInit(): void {

        if ( this.customSymbol === '' ) {
            this.currencySymbol = this.currencySupport.symbol();
        } else {
            this.currencySymbol = this.customSymbol;
        }
    }

    public writeValue(value: number): void {
        if (this.value !== value) {
            this.value = value;
            if (value === 0 || value === null) {
                this.inputRef.nativeElement.value = value;
            } else {
                this.inputRef.nativeElement.value = this.currencySupport.divide(value);
            }
        }
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public registerOnChange(fn: any): void {
        this.onChangeListener.push(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouchListener.push(fn);
    }

    public onTouch(): void {
        this.onTouchListener.forEach((listener) => listener());
    }

    public onChange(): void {
        this.onChangeListener.forEach((listener) => listener(this.value));
    }
}
