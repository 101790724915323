import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Restaurant } from '@ro-ngx/restaurants';
import { UserStorage } from '@ro-ngx/users';
import { CouponManagerConfig } from '../../coupon-manager-config';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'create-coupon-group',
    template: require('./create-coupon-group.component.html')
})
export class CreateCouponGroupComponent implements OnInit, AfterViewInit {
    @ViewChild(ModalDirective)
    public modal: ModalDirective;

    @Output()
    public onComplete: EventEmitter<CreateCouponGroupEvent> = new EventEmitter<CreateCouponGroupEvent>();

    public form: FormGroup;

    public restaurantConnections$: Observable<Restaurant[]>;

    @ViewChild('inputElement')
    protected inputElement: ElementRef;

    constructor(
        protected userStorage: UserStorage,
        protected formBuilder: FormBuilder,
        public config: CouponManagerConfig
    ) {}

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            title: [null, Validators.required],
            clientKey: [this.config.asAdmin ? 'hungrig' : null, Validators.required]
        });

        this.restaurantConnections$ = this.userStorage.restaurantConnections$.pipe(
            tap((restaurants) => {
                if (restaurants.length === 1 && ! this.config.asAdmin) {
                    this.form.patchValue({
                        clientKey: restaurants[0].clientKey
                    });
                }
            })
        );
    }

    public ok(): void {
        this.form.markAsDirty();
        if (this.form.valid) {
            this.onComplete.emit(this.form.value);
        }
    }

    public cancel(): void {
        this.onComplete.emit(null);
    }

    public focusInput(): void {
        this.inputElement.nativeElement.focus();
    }

    public ngAfterViewInit(): void {
        this.modal.show();
    }
}

export interface CreateCouponGroupEvent {
    title: string;
    clientKey: string;
}
