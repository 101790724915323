import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationInstance, NotificationStorage } from '@ro-ngx/core';
import { TranslateService } from '@ngx-translate/core';

export class BaseComponent implements OnDestroy {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    protected translate: TranslateService;

    protected notification: NotificationStorage;

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public updateNotification(name: string): NotificationInstance {
        return this.getNotificationStorage().success(
            this.getTranslateService().instant('general.saved') + '!',
            name + ' ' + this.getTranslateService().instant('general.saved').toLowerCase() + '.'
        );
    }

    protected getNotificationStorage(): NotificationStorage {
        if ( ! this.notification) {
            throw new  Error('Component must include NotificationStorage as this.notification.');
        }

        return this.notification;
    }

    protected getTranslateService(): TranslateService {
        if ( ! this.translate) {
            throw new Error('Component must include TranslateService as this.translate.');
        }

        return this.translate;
    }
}
