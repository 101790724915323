import { Injectable } from '@angular/core';
import { HungrigClient, BaseService } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryOrder } from '../interfaces';

@Injectable()
export class DeliveryOrderService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public cancelDeliveryOrder(deliveryOrderID: number): Observable<DeliveryOrder> {
        const url = `${this.hungrigClient.baseUrl}/delivery/orders/${deliveryOrderID}/cancel`;
        return this.put(url, {});
    }

    public timeoutDeliveryOrder(deliveryOrderID: number): Observable<DeliveryOrder> {
        const url = `${this.hungrigClient.baseUrl}/delivery/orders/${deliveryOrderID}/timeout`;
        return this.put(url, {});
    }

    public removeDeliveryOrderTimeout(deliveryOrderID: number): Observable<DeliveryOrder> {
        const url = `${this.hungrigClient.baseUrl}/delivery/orders/${deliveryOrderID}/timeout`;
        return this.delete(url);
    }
}
