import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable ,  Subject } from 'rxjs';
import { Order, PaymentInfo, PaymentInfoRow, PaymentRefund } from '../../../interfaces';
import { PaymentProcessor } from '../../../enums';
import { PaymentService } from '../../../services';
import { Util } from '../../../util';
import { map } from 'rxjs/operators';

@Component({
    selector: 'modal-order-payment-refund-info',
    template: require('./modal-order-payment-refund-info.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalOrderPaymentRefundInfoComponent implements OnInit {

    /**
     * Component loading.
     */
    public loading: { [key: string]: boolean } = {
        discard: false
    };

    @ViewChild('modal') public modal: ModalDirective;
    @Input() public order: Order;

    public paymentInfo$: Observable<PaymentInfo>;
    public translationMap: { [key: string]: string } = {
        [PaymentProcessor.Braintree]: 'braintree',
        [PaymentProcessor.BraintreeVault]: 'braintree',
        [PaymentProcessor.DibsD2]: 'dibs_d2',
        [PaymentProcessor.DibsD2Ticket]: 'dibs_d2',
        [PaymentProcessor.Tpay]: 'tpay',
        [PaymentProcessor.Svea]: 'svea',
        [PaymentProcessor.Invoice]: 'invoice',
        [PaymentProcessor.SwishECommerce]: 'swish',
        [PaymentProcessor.SwishMCommerce]: 'swish',
        [PaymentProcessor.KlarnaPayLater]: 'klarna',
        [PaymentProcessor.KlarnaPayNow]: 'klarna',
    };

    constructor(
        protected paymentService: PaymentService,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.paymentInfo$ = new Subject();
    }

    public open(paymentRefund: PaymentRefund): void {
        this.paymentInfo$ = this.paymentService.getRefundInfo(paymentRefund)
            .pipe(
                map((paymentInfo) => {
                    paymentInfo.rows = paymentInfo.rows.map((row) => this.transformPaymentInfoRow(row));
                    return paymentInfo;
                })
            );

        this.modal.show();
        this.changeDetectorRef.markForCheck();
    }

    protected transformPaymentInfoRow(row: PaymentInfoRow): PaymentInfoRow {
        row.key = 'payment.' + this.translationMap[this.order.paymentProcessor] + '.' + row.key;

        if (row.type === 'timestamp') {
            row.value = Util.fullDate(row.value);
        }

        return row;
    }
}
