import { Injectable } from "@angular/core";
import { Observable ,  throwError as _throw ,  Subject } from "rxjs";
import { catchError, finalize, first, switchMap } from "rxjs/operators";
import { AuthenticationTokenStorage } from "./authentication-token.storage";
import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";
import { AuthenticationConfig } from "../authentication-config";
import { DialogService, Error, ErrorFactory, NotifyDialogOptions } from "@ro-ngx/core";
import { UserStorage } from "@ro-ngx/users";
import { TranslateService } from "@ngx-translate/core";

const HTTP_UNAUTHORIZED: number = 401;
const ERROR_CODE = {
    AUTH_ACCESS_DENIED: 3,
    AUTH_UNAUTHORIZED: 5,
    PERMISSION_MISSING: 4
};

@Injectable()
export class AuthorizationInterceptor {

    public excludePaths: string[];

    protected set isRefreshing(isRefreshing: boolean) {
        localStorage.setItem('authentication.isRefreshing', JSON.stringify(isRefreshing));
    }

    protected get isRefreshing(): boolean {
        return Boolean(
            JSON.parse(localStorage.getItem('authentication.isRefreshing'))
        );
    }

    protected refreshSubject: Subject<boolean>;

    constructor(
        protected authenticationTokenStorage: AuthenticationTokenStorage,
        protected authenticationService: AuthenticationService,
        protected authenticationConfig: AuthenticationConfig,
        protected translateService: TranslateService,
        protected dialogService: DialogService,
        protected userStorage: UserStorage,
        protected router: Router
    ) {
        this.refreshSubject = new Subject();
        this.excludePaths = [
            '/auth/refresh'
        ];
    }

    public handleResponse(response: Observable<any>): Observable<any> {
        return response.pipe(
            catchError((error) => {
                let url;
                try {
                    url = new URL(error.url);
                }
                catch (e) {
                    return _throw(error);
                }

                const doRefresh = error.status === HTTP_UNAUTHORIZED
                    && this.excludePaths.indexOf(url.pathname) === -1
                    && this.authenticationTokenStorage.hasAuthorizationToken();

                if (doRefresh && ! this.isRefreshing) {
                    this.initiateAccessTokenRefresh(
                        ErrorFactory.from(error)
                    );
                }

                if (doRefresh && this.isRefreshing) {
                    return this.refreshSubject.pipe(
                        first(),
                        switchMap((isRefreshed) => isRefreshed ? response : _throw(error))
                    );
                }

                return _throw(error);
            })
        );
    }

    protected initiateAccessTokenRefresh(originError: Error<any>): void {
        this.isRefreshing = true;

        const refreshToken = this.authenticationTokenStorage.getAuthorizationToken();
        this.authenticationService.refresh(refreshToken.refresh_token)
            .pipe(finalize(() => this.isRefreshing = false))
            .subscribe(
                () => this.refreshSubject.next(true),
                () => {
                    this.redirect().then(() => {
                        this.dialogService.notify(
                            this.getDialogOptionsFromError(originError)
                        );
                    });

                    this.authenticationTokenStorage.setAuthorizationToken(undefined);
                    this.userStorage.clearMyself();
                    this.refreshSubject.next(false);
                }
            );
    }

    protected getDialogOptionsFromError(error: Error<any>): NotifyDialogOptions {
        if (error.errorCode === ERROR_CODE.PERMISSION_MISSING) {
            return {
                title: this.translateService.instant('authentication.error.permission_missing.title'),
                body: this.translateService.instant('authentication.error.permission_missing.body')
            };
        }

        return {
            title: this.translateService.instant('authentication.error.access_denied.title'),
            body: this.translateService.instant('authentication.error.access_denied.body')
        };
    }

    protected redirect(): Promise<boolean> {
        return this.router.navigate([this.authenticationConfig.unauthorizedUrl]);
    }
}
