import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { Router } from '@angular/router';

export interface History {
    name: string;
    url: string;
    date: Date;
}

@Injectable()
export class HistoryState {

    public history$: Observable<History[]>;

    protected history: BehaviorSubject<History[]>;

    protected keep: number = 10;

    protected reversed: History;

    constructor(protected router: Router) {
        this.history = new BehaviorSubject([]);
        this.history$ = this.history.asObservable();
    }

    public changeValue(callback: (history: History[]) => History[]): this {
        const value = this.history.getValue();
        this.history.next(callback(value));

        return this;
    }

    public addHistory(name: string, url: string): History {
        const historyInstance = {
            name: name,
            url: url,
            date: new Date()
        };

        this.changeValue((history: History[]) => {
            history.unshift(historyInstance);
            history.splice(this.keep, history.length);
            return history;
        });

        return historyInstance;
    }

    public wasReversed(): boolean {
        return !!this.reversed;
    }

    public setReversed(history: History): this {
        this.reversed = history;

        return this;
    }

    public latest(): History | null {
        return this.history.getValue()[0] ? this.history.getValue()[0] : null;
    }

    public gotoLatest(): void {
        const latest = this.latest();

        if (latest) {
            this.setReversed(latest)
                .changeValue((history) => {
                    history.splice(0, 1);

                    return history;
                });

            this.router.navigateByUrl(latest.url)
                .then();
        }
    }
}
