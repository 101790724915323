import { format, isValid } from 'date-fns';

export class Util {

    public static fullDate(timestamp: string): string {
        return isValid(new Date(timestamp)) && timestamp ? format(timestamp, 'YYYY-MM-DD, HH:mm:ss') : '--:--';
    }

    public static time(timestamp: string): string {
        return isValid(new Date(timestamp)) && timestamp ? format(timestamp, 'HH:mm') : '--:--';
    }

    public static date(timestamp: string): string {
        return isValid(new Date(timestamp)) && timestamp ? format(timestamp, 'DD/MM') : '--/--';
    }

    public static distance(distance: number): string {
        const metric = 1000;

        return ( distance / metric ).toFixed(1) + ' km';
    }
}
