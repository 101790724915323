import { NgModule } from '@angular/core';
import { CouponsModule } from '../../coupons.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CurrencyModule, RoNg2Module } from '@ro-ngx/core';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { routes } from './routes';
import { TranslateModule } from '@ngx-translate/core';
import { CouponTemplateService } from './template';
import { CouponGroupsComponent, CouponGroupsState } from './groups';
import { CouponGroupComponent } from './groups/group';
import { CouponsComponent } from './coupons';
import { CreateCouponGroupComponent } from './groups/create-group';
import { EditCouponComponent } from './coupon-form/edit-coupon.component';
import { GenerateCouponComponent } from './coupon-form/generate-coupon.component';
import { TimeSelectModule } from '@ro-ngx/common';
import { RestaurantConnectionSelectModule } from '@ro-ngx/users';

@NgModule({
    imports: [
        CouponsModule,
        CommonModule,
        CurrencyModule,
        ModalModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        RoNg2Module,
        TranslateModule,
        TimeSelectModule,
        RestaurantConnectionSelectModule
    ],
    exports: [],
    declarations: [
        CouponGroupComponent,
        CouponsComponent,
        CouponGroupsComponent,
        CreateCouponGroupComponent,
        EditCouponComponent,
        GenerateCouponComponent
    ],
    providers: [
        CouponTemplateService,
        CouponGroupsState
    ],
})
export class CouponManagerModule {
}
