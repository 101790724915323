import { ModuleWithProviders, NgModule } from '@angular/core';
import { RestaurantCheckoutService } from './services/restaurant-checkout.service';
import { RestaurantPaymentProcessorService, RestaurantTagService, RestaurantService } from './services';
import { RestaurantCartService } from "./services/restaurant-cart.service";
import { RestaurantSummaryService } from "./services/restaurant-summary.service";
import { RestaurantSummaryListComponent } from "./components/restaurant-summary-list/restaurant-summary-list.component";
import { CommonModule } from "@angular/common";
import { RoNg2Module } from "@ro-ngx/core";


declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

@NgModule({
    imports: [
        CommonModule,
        RoNg2Module
    ],
    exports: [
        RestaurantSummaryListComponent
    ],
    declarations: [
        RestaurantSummaryListComponent
    ],
    providers: [],
})
export class RestaurantsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: RestaurantsModule,
            providers: [
                RestaurantService,
                RestaurantCartService,
                RestaurantCheckoutService,
                RestaurantPaymentProcessorService,
                RestaurantSummaryService,
                RestaurantTagService
            ]
        };
    }
}
