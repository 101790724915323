import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Env } from '../../enviroment';
import { EnviromentModule } from '../../enviroment-module';
import { find } from 'lodash';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../../http/user/user';
import { UserService } from '../../http/user/user.service';
import { RestaurantService, Restaurant } from '@ro-ngx/restaurants';
import { UserConnectionType, UserStorage } from '@ro-ngx/users';
import {filter, flatMap, map, mergeMap} from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import {NotificationStorage} from "@ro-ngx/core";

export interface Navigation {
    key: string;
    path: string;
}

export interface DropdownNavigation {
    key: string;
    children: Navigation[];
}

@Component({
    selector: 'navbar',
    template: require('./navbar.component.html'),
    styles: [
        '.Clicked {background-color: #b50952;}',
    ],
})
export class NavbarComponent extends BaseComponent implements OnInit {

    public restaurantName$: Observable<string>;

    public brandUsers$: Observable<User[]>;

    public displayMobileNavbar: boolean = false;

    public simpleNavigations: Navigation[] = [];

    public dropdownNavigations: DropdownNavigation[] = [];

    public brandLogo: string[];

    public brand: string;

    public restaurant$: Observable<Restaurant>;

    public enClicked: boolean = false;

    public seClicked: boolean = false;

    public onlineControl: FormControl;

    public showStatusSwitch: boolean = false;

    protected clientKey: string;


    constructor(
        protected restaurantService: RestaurantService,
        protected userService: UserService,
        protected userStorage: UserStorage,
        protected translateService: TranslateService,
        protected notifications: NotificationStorage,
        protected router: Router) {
        super();

        this.brandLogo = [
            'brand-navbar-logo',
            Env.BRAND_NAME.toLowerCase()
        ];

        (JSON.parse(Env.MODULES) as EnviromentModule[])
            .filter((module) => module.navbar)
            .forEach((module) => {
                const navigation = {
                    key: module.navbarKey,
                    path: module.path
                };

                if (!module.navbarCategory || module.navbarCategory.length === 0) {
                    this.simpleNavigations.push(navigation);
                }
                else {
                    const dropdown = find(this.dropdownNavigations, { key: module.navbarCategory });

                    if (dropdown) {
                        dropdown.children.push(navigation);
                    }
                    else {
                        this.dropdownNavigations.push({
                            key: module.navbarCategory,
                            children: [
                                navigation
                            ]
                        });
                    }
                }
            });
    }

    public ngOnInit(): void {
        if(localStorage.getItem('language') == 'sv') {
            this.seClicked = true;
        }else if(localStorage.getItem('language') == 'en') {
            this.enClicked = true;
        }

        this.onlineControl = new FormControl(false);


        this.restaurant$ = this.userStorage.myself$.pipe(
            filter((user) => Boolean(user)),
            flatMap((user) => {
                const clientKey = user.findUserConnection(UserConnectionType.RESTAURANT, '*').clientKey;
                this.fetchOnlinestatus(clientKey)
                return this.restaurantService.getRestaurant(clientKey, { includes: ['restaurantSlaves'] }).pipe(
                    map((restaurant) => {
                        restaurant.restaurantSlaves = (restaurant.restaurantSlaves || []).filter(
                            (restaurantSlave) => restaurant.brandSlave === 0
                                && restaurantSlave.clientKey !== restaurant.clientKey
                        );

                        return restaurant;
                    })
                );
            })
        );

        this.onlineControl.valueChanges.pipe(mergeMap((online) =>
            this.userService.setOnline(this.clientKey, online)))
            .subscribe(() =>
                this.notifications.success(this.translateService.instant('general.saved')));


    }


    protected fetchOnlinestatus( clientKey:string ): void {
        this.clientKey = clientKey;
       this.userService.getRestaurantOnlinestatus(clientKey)
            .subscribe((onlinestatus) => {
                this.showStatusSwitch = onlinestatus.showStatusSwitchCMS
                this.onlineControl.setValue(onlinestatus.online, { emitEvent: false })
            })
    }

    public toggleDisplayMobileNavbar(): void {
        this.displayMobileNavbar = !this.displayMobileNavbar;
    }

    public closeMobileNavbar(event: Event): void {
        event.preventDefault();

        this.displayMobileNavbar = false;
    }

    /**
     * Logout user from application.
     *
     * @param event
     */
    public logout(event: Event): void {
        event.preventDefault();

        window.location.href = window.location.origin + '/login';
    }

    public loginAs(restaurant: Restaurant): void {
        this.restaurantService.getTokenBearer(restaurant.clientKey)
            .subscribe((tokenBearer) => {
                const query = Object.keys(tokenBearer)
                    .map((key) => key + '=' + tokenBearer[key])
                    .join('&');

                window.location.replace(
                    window.location.origin + '/login/remote?' + query
                );
            });
    }

    public setLanguageChange(resp){
        localStorage.setItem('language',resp)
        this.translateService.use(localStorage.getItem('language'))

        if(resp == 'sv'){
            this.enClicked = false;
            this.seClicked = true;
        }else{
            this.seClicked = false;
            this.enClicked = true;
        }
    }
}
