import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DriverListHeaderComponent } from './components/driver-list/driver-list-header.component';
import { OrderListHeaderComponent } from './components/order-list/order-list-header.component';
import { DeliveryManageConfigModule } from './delivery-manage-config.module';
import { deliveryManageRoutes } from './delivery-manage.routes';
import { ManagePageComponent } from './containers/manage-page/manage-page.component';
import { DeliveryManageService } from '../../services/delivery-manage.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderListItemComponent } from './components/order-list-item/order-list-item.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrderAssignComponent } from './components/order-assign/order-assign.component';
import { DriverListComponent } from './components/driver-list/driver-list.component';
import { DriverListItemComponent } from './components/driver-list-item/driver-list-item.component';
import { ManageOrderMapComponent } from './containers/manage-order-map/manage-order-map.component';
import { DriverLoadDirective } from './directives/driver-load.directive';
import { OrderSizeDirective } from './directives/order-size.directive';
import { RoMapStatesDirective } from './directives/ro-map-states.directive';
import { AsKilometersPipe } from './pipes/as-kilometers.pipe';
import { AsMinutesPipe } from './pipes/as-minutes.pipe';
import { AsTimeLeftPipe } from './pipes/as-time-left.pipe';
import { CurrencyModule, defaultTranslate, RoNg2Module } from '@ro-ngx/core';
import { HttpClientModule } from '@angular/common/http';
import { DriverDeliveryComponent } from './components/driver-delivery/driver-delivery.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { OrderSeverityDirective } from './directives/order-severity.directive';
import { RoMapDirective } from './directives/ro-map.directive';
import { TimedProgressBarModule } from '@ro-ngx/common';
import { OrderDistancePipe } from './pipes/order-distance.pipe';

@NgModule({
    declarations: [
        ManagePageComponent,
        ManageOrderMapComponent,
        OrderListItemComponent,
        OrderListComponent,
        OrderListHeaderComponent,
        OrderDetailComponent,
        OrderAssignComponent,
        DriverListComponent,
        DriverListHeaderComponent,
        DriverListItemComponent,
        DriverDeliveryComponent,
        AsMinutesPipe,
        AsTimeLeftPipe,
        AsKilometersPipe,
        OrderDistancePipe,
        OrderSeverityDirective,
        OrderSizeDirective,
        DriverLoadDirective,
        RoMapDirective,
        RoMapStatesDirective,
    ],
    providers: [
        DeliveryManageService,
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
    ],
    imports: [
        DeliveryManageConfigModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(deliveryManageRoutes),
        TranslateModule.forChild(defaultTranslate),
        RoNg2Module,
        HttpClientModule,
        ButtonsModule,
        CurrencyModule,
        TimedProgressBarModule,
    ],
})
export class DeliveryManageModule {}
