import { merge } from 'lodash';

export interface TranslateContext {
    // key = filename | product
    [key: string]: {

        // key = real string key | title
        [key: string]: string
    }
}

export interface Translations {
    // key = en | sv | pl
    [key: string]: TranslateContext
}

export class Translator {

    /**
     * Ex en.product.title
     *
     * @type {Translations}
     */
    public translations: Translations = {};

    /**
     * Merge translations into current translations
     *
     * @param translations
     * @returns {Translator}
     */
    public addTranslations(translations: Translations): this {
        this.translations = merge(this.translations, translations);

        return this;
    }

    /**
     * Import translations using require webpackContext
     *
     * @param require
     * @returns {Translator}
     */
    public fromWebpackContext(require: any): this {
        require.keys().forEach((file: string) => {
            let lang = '';
            let filename = '';

            [lang, filename] = file.replace('./', '')
                .replace('.json', '')
                .split('/');

            this.addTranslations({
                [lang]: {
                    [filename]: require(file) as { [key: string]: string }
                }
            });
        });

        return this;
    }

}
