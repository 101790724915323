import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, Paginate, slideDown } from '@ro-ngx/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { Coupon, CouponGroup } from '../../../interfaces';
import { CouponService } from '../../../services';
import { CouponGroupsState } from '../groups';
import { debounceTime, filter, first, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable ,  forkJoin ,  combineLatest } from 'rxjs';
import { BaseComponent } from '../../../base.component';
import { CouponGroupState } from '../groups/group';

@Component({
    animations: [slideDown.ng],
    selector: 'coupons',
    template: require('./coupons.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponsComponent extends BaseComponent implements OnInit, OnDestroy {
    public currentPage: FormControl;
    public filter: FormControl;
    public slideDown: typeof slideDown.velocity = slideDown.velocity;

    public loadingPage$: BehaviorSubject<boolean>;
    public loadingSticky$: BehaviorSubject<boolean>;
    public selectedCouponId$: BehaviorSubject<number>;
    public paginatedCoupons$: BehaviorSubject<Paginate<Coupon[]>>;
    public couponGroup$: Observable<CouponGroup>;

    constructor(
        protected couponService: CouponService,
        protected dialogService: DialogService,
        protected translateService: TranslateService,
        protected route: ActivatedRoute,
        protected couponGroupsState: CouponGroupsState,
        protected couponGroupState: CouponGroupState
    ) {
        super();
    }

    public ngOnInit(): void {
        this.loadingPage$ = new BehaviorSubject<boolean>(false);
        this.loadingSticky$ = new BehaviorSubject<boolean>(false);
        this.selectedCouponId$ = new BehaviorSubject<number>(null);
        this.paginatedCoupons$ = new BehaviorSubject<Paginate<Coupon[]>>(null);
        this.couponGroup$ = this.couponGroupState.couponGroup$.pipe(
            filter((group) => group !== null)
        );

        this.currentPage = new FormControl(1);
        this.filter = new FormControl(this.route.parent.snapshot.paramMap.get('code'));

        const currentPage$ = this.currentPage.valueChanges.pipe(
            startWith(this.currentPage.value)
        );

        combineLatest(
                currentPage$,
                this.couponGroup$.pipe(tap(() => this.paginatedCoupons$.next(null)))
            )
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(() => {
                    this.loadingPage$.next(true);
                    this.selectedCouponId$.next(null);
                }),
                switchMap(([page, couponGroup]) => {
                    const options = {
                        page,
                        filter: this.filter.value,
                        couponGroupID: [couponGroup.couponGroupID]
                    };

                    return this.couponService.getCoupons(options);
                })
            )
            .subscribe((paginatedCoupons) => {
                this.paginatedCoupons$.next(paginatedCoupons);
                this.loadingPage$.next(false);
            });

        const debounceTimeMs: number = 300;
        this.filter.valueChanges.pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(debounceTimeMs)
            )
            .subscribe(() => this.currentPage.setValue(1));
    }

    public onCouponUpdate(index: number, coupon: Coupon): void {
        const coupons = this.paginatedCoupons$.getValue();
        coupons.data[index] = coupon;
        this.paginatedCoupons$.next(coupons);
    }

    public onCouponRemove(index: number): void {
        const coupons = this.paginatedCoupons$.getValue();
        coupons.data.splice(index, 1);
        this.paginatedCoupons$.next(coupons);
    }

    public selectCoupon(id: number): void {
        const selectedCouponId = this.selectedCouponId$.getValue();
        this.selectedCouponId$.next(selectedCouponId === id ? undefined : id);
    }

    public trackCoupon(index: number, coupon: Coupon): number {
        return coupon ? coupon.couponID : undefined;
    }

    public downloadCsv(): void {
        const filename$ = this.dialogService.prompt({
                ...this.translateService.instant('dialog.export_filename'),
                prefill: 'coupons.csv'
            })
            .pipe(
                filter((filename) => filename !== null)
            );

        this.couponGroup$.pipe(
                first(),
                switchMap((couponGroup) => forkJoin(
                    this.couponService.getGroupCouponsCsv(couponGroup.couponGroupID),
                    filename$
                )),
            )
            .subscribe(([blob, filename]) => FileSaver.saveAs(blob, filename));
    }
}
