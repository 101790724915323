export enum PaymentProcessor {
    Invoice = 'invoice',
    Svea = 'svea',
    DibsD2 = 'dibs_d2',
    DibsD2Ticket = 'dibs_d2_ticket',
    Braintree = 'braintree',
    BraintreeVault = 'braintree_vault',
    Tpay = 'tpay',
    TpayBlik = 'tpay_blik',
    PayU = 'pay_u',
    SwishMCommerce = 'swish_m_commerce',
    SwishECommerce = 'swish_e_commerce',
    KlarnaPayments = 'klarna_payments',
    KlarnaPayLater = 'klarna_pay_later',
    KlarnaPayNow = 'klarna_pay_now'

}
