import { PaginateBody } from './paginate-body';

export class Paginate<T> {
    public meta: PaginateBody = {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
    };
    public data: T;
}
