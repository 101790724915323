import {
    animate,
    AnimationBuilder,
    AnimationMetadata,
    AnimationPlayer,
    group,
    query,
    style
} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-timed-progress-bar',
    template: require('./timed-progress-bar.component.html'),
    styles: [require('./timed-progress-bar.component.less')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimedProgressBarComponent implements OnChanges {
    @Input() public duration: number;
    @Input() public reverse: boolean;

    private player: AnimationPlayer;

    constructor(
        private elRef: ElementRef,
        private animationBuilder: AnimationBuilder,
        private cdRef: ChangeDetectorRef,
    ) {
    }

    @Input() set going(value: number) {
        this.createPlayer();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return;
        }

        const {duration} = changes;

        if (duration && duration.currentValue) {
            this.elRef.nativeElement.style.setProperty('--dur', `${duration}`);
            this.createPlayer();
        }
    }

    public createPlayer(): void {
        this.cdRef.detectChanges();

        if (this.player) {
            this.player.destroy();
        }

        // Build animation
        const animation = this.getBarAnimation();
        const animationFactory = this.animationBuilder.build(animation);
        this.cdRef.detectChanges();
        this.player = animationFactory.create(this.elRef.nativeElement);

        // Play animation
        this.player.play();
    }

    private getBarAnimation(): AnimationMetadata[] {
        const fadeIn = 800;

        return [
            group([
                query('*', [
                    style({opacity: 0}),
                    animate(fadeIn, style({opacity: 1})),
                ]),
                query('span', [
                    style({
                        transform: this.reverse ? 'scaleX(1)' : 'scaleX(0)',
                        transformOrigin: this.reverse ? 'right center' : 'left center',
                    }),
                    animate(this.duration, style({transform: this.reverse ? 'scaleX(0)' : 'scaleX(1)'})),
                ])
            ])
        ];
    }
}
