import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    DeliveryCompanyService,
    DeliveryDriverService,
    DeliveryOrderActionService,
    DeliveryOrderScheduleService,
    DeliveryOrderService,
    DeliveryTimesService,
    DeliveryManageService,
    DeliveryDistrictsService,
} from './services';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
    entryComponents: []
})
export class DeliveryModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: DeliveryModule,
            providers: [
                DeliveryCompanyService,
                DeliveryDriverService,
                DeliveryOrderActionService,
                DeliveryOrderScheduleService,
                DeliveryOrderService,
                DeliveryTimesService,
                DeliveryManageService,
                DeliveryDistrictsService,
            ]
        };
    }
}
