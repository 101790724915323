import { Component, AfterViewInit, Input, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogComponent } from '../dialog.component';
import { PromptDialog } from './prompt-dialog';

@Component({
    selector: 'prompt-dialog',
    template: `
        <div bsModal [config]="{ backdrop: 'static' }" (onHide)="cancel()" (onShown)="focusInput()"
             class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">{{ dialog.options.title }}</h4>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="form">
                            <div class="form-group"
                                 [ngClass]="{ 'has-error': form.controls.input.errors && ! form.controls.input.pristine }">
                                <label>{{ dialog.options.label }}</label>
                                <input #input (keyup.enter)="ok()" type="text" class="form-control" formControlName="input">
                                <div *ngIf="form.controls.input.errors && ! form.controls.input.pristine"
                                     class="form-control-errors">
                                    <p *ngIf="form.controls.input.hasError('required')" class="help-block">
                                        {{ 'validation.required' | translate }}
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button (click)="cancel()" class="btn btn-default">
                            {{ dialog.options.cancel ||  ('general.cancel' | translate) }}
                        </button>
                        <button (click)="ok()" class="btn"
                                [ngClass]="dialog.options.accent ? 'btn-' + dialog.options.accent : 'btn-primary'">
                            {{ dialog.options.ok || ('general.ok' | translate) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class PromptDialogComponent implements OnInit, AfterViewInit, DialogComponent {
    @Input() public dialog: PromptDialog;
    @ViewChild(ModalDirective) public modal: ModalDirective;
    public form: FormGroup;
    @ViewChild('input') protected inputView: ElementRef;

    constructor(protected formBuilder: FormBuilder) {}

    public ngOnInit(): void {
        const prefill = typeof this.dialog.options.prefill === 'undefined' ? '' : this.dialog.options.prefill;

        this.form = this.formBuilder.group({
            input: [prefill, Validators.required]
        });
    }

    public ok(): void {
        const input = this.form.get('input');
        input.markAsDirty();
        if (input.valid) {
            this.dialog.complete(input.value);
        }
    }

    public cancel(): void {
        this.dialog.complete(null);
    }

    public focusInput(): void {
        this.inputView.nativeElement.focus();
    }

    public ngAfterViewInit(): void {
        this.modal.show();
    }
}
