
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryDistrict } from './delivery-district';
import { DeliveryFleet } from './delivery-fleet';

@Injectable()
export class DistrictService extends BaseService {

    public resourceUrl: string = 'delivery/district';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDistricts(perPage: number, page: number, search: string): Observable<DeliveryDistrict[]> {
        return this.hungrigClient.get(this.url(), { search: { perPage, page, search }}).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getFleets(): Observable<DeliveryFleet[]> {
        return this.hungrigClient.get(`${this.hungrigClient.baseUrl}/delivery-fleets`).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
