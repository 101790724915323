import { Location } from '@angular/common';
import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@ro-ngx/boilerplate';
import { NotificationStorage } from '@ro-ngx/core';
import { BehaviorSubject, Observable, Subject ,  combineLatest ,  defer ,  forkJoin  ,  merge ,  of ,  timer ,  iif } from 'rxjs';
import {
    distinctUntilChanged, share, startWith, filter, finalize, map, shareReplay,
    switchMap, takeUntil, tap, throttleTime, pairwise, withLatestFrom,
} from 'rxjs/operators';
import { DeliveryManageService } from '../../../../services/delivery-manage.service';
import { DeliveryManageModuleConfig } from '../../delivery-manage-module-config';
import { getOrderSeverity } from '../../directives/order-severity.directive';
import { formatPickupTimeAbsolute, formatPickupTimeRelative, isOrderPositionable } from '../../helpers';
import { ManageOrderDistrict } from '../../models/district.models';
import { ManageOrderDriver } from '../../models/manage-order-driver.models';
import { ManageOrderRouteProperties } from '../../models/driver.models';
import {ManageOrderBase} from '../../models/manage-order-base.models';
import {ManageOrderDirections} from '../../models/manage-orders-directions.models';
import {ManageOrderDirectionsInfo} from '../../models/manage-order-directions-info.models';
import {ManageOrderWithCart} from '../../models/ManageOrderWithCart.models';
import {
    ManageOrderAssignOrderEvent,
    ManageOrderAssignOrderParams,
} from '../../models/request.models';
import {
    ManageMapOptions,
    ManageOrderMapComponent,
    RouteOptimizationField,
} from '../manage-order-map/manage-order-map.component';

// presets - use 'undefined' for 'all' - the param will then be excluded from URL params
const PICKUP_DISTANCE_PRESETS = [100, 200, 300, 400, 500, -1]; // restaurants
const DEFAULT_PICKUP_DISTANCE = PICKUP_DISTANCE_PRESETS[0];

const DELIVERY_DISTANCE_PRESETS = [500, 700, 900, 1100, 1500, -1]; // customers
const DEFAULT_DELIVERY_DISTANCE = DELIVERY_DISTANCE_PRESETS[0];

const DRIVER_DISTANCE_PRESETS = [200, 400, 600, 800, 1000, -1]; // drivers
const DEFAULT_DRIVER_DISTANCE = DRIVER_DISTANCE_PRESETS[5];

const PICKUP_TIME_SPAN_PRESETS = [5, 10, 15, 20, -1]; // time span
const DEFAULT_PICKUP_TIME_SPAN = PICKUP_TIME_SPAN_PRESETS[0];

// rxjs
const THROTTLE_TRIGGER_TIME = 500;
const POLL_INTERVAL = 10_000;

/*
    /{deliveryDistrictID}/{deliveryOrderID}

    deliveryOrderID is not validated whether it belongs to the district referred to by the URL.
    <router-outlet> is not used - URLs are refreshed by adding a state to History API (address bar only).
    If the order is an old one, it might never be included in the order list.
 */

@Component({
    selector: 'manage-page',
    template: require('./manage-page.component.html'),
    styles: [require('./manage-page.component.less')],
})
export class ManagePageComponent extends BaseComponent implements OnInit {
    public orders$: Observable<ManageOrderBase[]>;
    public drivers$: Observable<ManageOrderDriver[]>;
    public districts$: Observable<ManageOrderDistrict[]>;

    public routeParamDistrictID$: Observable<number>;
    public routeParamOrderID$: Observable<number>;

    public selectedOrder$: Observable<ManageOrderWithCart>;
    public selectedOrderID$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    public hoveredOrderIDs$: BehaviorSubject<number[]> = new BehaviorSubject([]);

    public selectedDriver$: Observable<ManageOrderDriver>;
    public selectedDriverID$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    public selectedDriverOrdersIDs$: Observable<any[]>;
    public disabledParamButtons$: Observable<boolean>;

    public selectedOrderRoute$: Observable<ManageOrderDirectionsInfo>;
    public otherOrdersRoutes$: Observable<ManageOrderDirectionsInfo[]>;

    public selectDistrictCtrl: FormControl = new FormControl();
    public validatedDistrict$: Observable<ManageOrderDistrict>;
    public requestedDistrictID$: Observable<number>;
    public isValidDistrict$: Observable<boolean>;
    public validatedDistrictID$: Observable<number>;

    public assignOrderError$: BehaviorSubject<any> = new BehaviorSubject(null);
    public dataChange$: Subject<any> = new Subject();

    public mapReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    @ViewChild('map') mapComp: ManageOrderMapComponent;

    public driversParamsForm: FormGroup;
    public ordersParamsForm: FormGroup;

    public loading: any = {
        districts: false,
        orders: false,
        order: false,
        drivers: false,
        assign: false,
    };

    public pickupDistancePresets: number[] = PICKUP_DISTANCE_PRESETS;
    public deliveryDistancePresets: number[] = DELIVERY_DISTANCE_PRESETS;
    public driverDistancePresets: number[] = DRIVER_DISTANCE_PRESETS;
    public pickupTimeSpanPresets: number[] = PICKUP_TIME_SPAN_PRESETS;

    public poll$: Observable<number>;
    public pollInterval: number = POLL_INTERVAL;
    public mapOptions$: Observable<ManageMapOptions>;

    constructor(
        private readonly config: DeliveryManageModuleConfig,
        private readonly api: DeliveryManageService,
        private readonly appRef: ApplicationRef,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private readonly translate: TranslateService,
        private readonly notification: NotificationStorage,
        fb: FormBuilder,
    ) {
        super();

        this.ordersParamsForm = fb.group({
            showAssigned: false,
        });

        this.driversParamsForm = fb.group({
            pickupDistance: DEFAULT_PICKUP_DISTANCE,
            deliveryDistance: DEFAULT_DELIVERY_DISTANCE,
            driverDistance: DEFAULT_DRIVER_DISTANCE,
            pickupTimeSpan: DEFAULT_PICKUP_TIME_SPAN,
        });
    }

    get driverDistanceCtrl() {
        return this.driversParamsForm.get('driverDistance');
    }

    public ngOnInit(): void {
        this.mapOptions$ = of({
            freezeMap: true,
            routeOptimalBy: RouteOptimizationField.Distance,
        });

        this.poll$ = timer(0, this.pollInterval).pipe(
            share(),
        );

        // route params
        this.routeParamDistrictID$ = this.route.params.pipe(
            map(({ deliveryDistrictID }) => deliveryDistrictID),
        );

        this.routeParamOrderID$ = this.route.params.pipe(
            map(({ deliveryOrderID }) => isNaN(+deliveryOrderID) ? null : +deliveryOrderID),
        );

        // districts
        this.districts$ = this.loadDistricts();

        this.requestedDistrictID$ = merge(
            this.selectDistrictCtrl.valueChanges,
            this.routeParamDistrictID$,
        ).pipe(
            map(id => +id || 0),
            shareReplay(1),
        );

        this.validatedDistrict$ = combineLatest(
            this.requestedDistrictID$,
            this.districts$,
            this.mapComp.mapReady, // need to re-emit district when map becomes ready
        )
            .pipe(
                map(([id, districts]) => districts.find(district => district.deliveryDistrictID === id)),
                share(),
            );

        this.validatedDistrict$.pipe(
            takeUntil(this.ngUnsubscribe),
        ).subscribe(found => {
            const valueToSelect = found ? +found.deliveryDistrictID : 0;
            this.selectDistrictCtrl.setValue(valueToSelect, { emitEvent: false });
            setTimeout(() => {
                this.appRef.tick();
            });
        });

        this.isValidDistrict$ = combineLatest(
            this.routeParamDistrictID$,
            this.validatedDistrict$,
        ).pipe(
            map(([routeParam, district]) => !routeParam || (!!routeParam && !!district)),
            startWith(true),
        );

        this.validatedDistrictID$ = combineLatest(
            this.requestedDistrictID$,
            this.isValidDistrict$,
        ).pipe(
            map(([id, isValid]) => isValid ? +id : 0),
            distinctUntilChanged(),
        );

        this.selectDistrictCtrl.valueChanges
            .pipe(
                filter(id => id > 0),
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((id) => {
                this.updateUrlLocation(id);
                this.deselectDriverAndOrder();
            });

        // orders
        const getOrdersTrigger$ = merge(
            this.poll$,
            this.dataChange$,
        ).pipe(
            throttleTime(THROTTLE_TRIGGER_TIME),
        );

        this.orders$ = combineLatest(
            this.validatedDistrictID$,
            this.ordersParamsForm.valueChanges.pipe(startWith(this.ordersParamsForm.value)),
            getOrdersTrigger$,
        ).pipe(
            filter(([deliveryDistrictID]) => !!deliveryDistrictID),
            switchMap(([deliveryDistrictID, { showAssigned }]) => {
                this.loading['orders'] = true;
                return this.api.getOrders(deliveryDistrictID, showAssigned).pipe(
                    finalize(() => this.loading['orders'] = false),
                );
            }),
        );

        const getSelectedOrderTrigger$ = merge(
            this.poll$,
            this.dataChange$,
        ).pipe(
            throttleTime(THROTTLE_TRIGGER_TIME),
        );

        // @ts-ignore
        this.selectedOrder$ = combineLatest(
            [ this.selectedOrderID$,
                this.mapComp.mapReady,
                getSelectedOrderTrigger$]
        )
            .pipe(
                switchMap(([deliveryOrderID]) => iif(
                    () => !!deliveryOrderID && !isNaN(deliveryOrderID),

                    defer(() => {
                        this.loading['order'] = true;
                        return this.api.getOrder(deliveryOrderID).pipe(
                            finalize(() => {
                                this.loading['order'] = false;
                                this.appRef.tick();
                            }),
                        );
                    }),

                    of(null),
                )),
                tap((order) => {
                    const controls = [this.driverDistanceCtrl];
                    order
                        ? controls.forEach((c) => c.enable())
                        : controls.forEach((c) => c.disable());
                }),
                shareReplay(1),
            );

        this.selectedOrder$.pipe(
            pairwise(),
            map(([previous, current]) => {
                if (!previous || !current) {
                    return false;
                }

                const sameOrder = previous.deliveryOrderID === current.deliveryOrderID;
                const gotDriver = !previous.deliveryDriverID && !!current.deliveryDriverID;

                return sameOrder && gotDriver;
            }),
            filter(Boolean),
            withLatestFrom(this.selectedOrder$),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(([,{ restaurantName }]) => {
            this.deselectDriverAndOrder();
            const body = this.translate.instant('delivery.manage.assign.driver_assigned_notification_body', { restaurantName });
            this.notification.info(restaurantName, body);
        });

        this.routeParamOrderID$.pipe(
            takeUntil(this.ngUnsubscribe),
        ).subscribe(id => {
            this.selectOrderByID(id);
        });

        // drivers
        const getDriversTrigger$ = merge(
            this.poll$,
        ).pipe(
            throttleTime(THROTTLE_TRIGGER_TIME),
        );

        this.drivers$ = combineLatest(
                this.validatedDistrictID$, // selectDistrictCtrl.valueChanges won't work here bco (emitEvent: false)
                this.selectedOrderID$,
                this.driversParamsForm.valueChanges.pipe(startWith(this.driversParamsForm.value)),
                getDriversTrigger$,
        ).pipe(
            filter(([deliveryDistrictID]) => !!deliveryDistrictID),
            switchMap(([deliveryDistrictID, deliveryOrderID, driversParams]) => {
                const { pickupDistance, deliveryDistance, driverDistance, pickupTimeSpan } = driversParams;
                this.loading['drivers'] = true;

                return iif(
                    () => !!deliveryOrderID,

                    defer(() => {
                        const removeIfFetchAll = value => value === -1 ? undefined : value;

                        const params = {
                            deliveryOrderID,
                            deliveryDistrictID: [deliveryDistrictID],
                            deliveryCompanyID: null,
                            pickupDistance: removeIfFetchAll(pickupDistance),
                            deliveryDistance: removeIfFetchAll(deliveryDistance),
                            pickupTimeSpan: removeIfFetchAll(pickupTimeSpan),
                            driverDistance: removeIfFetchAll(driverDistance),
                        };

                        return this.api.getDrivers(params).pipe(
                            finalize(() => {
                                this.loading['drivers'] = false;
                                this.appRef.tick();
                            }),
                        );
                    }),

                    defer(() => {
                        return this.api.getDriversInDistrict(deliveryDistrictID).pipe(
                            finalize(() => {
                                this.loading['drivers'] = false;
                                this.appRef.tick();
                            }),
                        );
                    }),
                );
            }),
            shareReplay(1),
        );

        this.selectedDriver$ = combineLatest(
            this.drivers$,
            this.selectedDriverID$,
        ).pipe(
            map(([all, selectedID]) => all.find((driver) => driver.deliveryDriverID === selectedID)),
        );

        this.selectedDriverOrdersIDs$ = this.selectedDriver$.pipe(
            filter(Boolean),
            //@ts-ignore, this component is mixed with ts and js, don't want to make the effort to improve it.
            map(driver => driver.deliveryOrders.map((order) => order.deliveryOrderID)),
        );

        this.selectedOrderRoute$ = this.selectedOrder$.pipe(
            // Get route from restaurant to customer
            switchMap((order) => iif(
                () => !!order,
                defer(() => this.getOrderRouteDirections(order, true).pipe(
                    map((orderDirections) => {
                        const drawPickupTime = formatPickupTimeAbsolute(order.driverShouldPickupAt);
                        return {
                            orderDirections,
                            severity: getOrderSeverity(order.driverShouldPickupAt),
                            drawPickupTime,
                            deliveryOrderID: order.deliveryOrderID,
                            deliveryDriverID: order.deliveryDriverID,
                            restaurantName: order.restaurantName,
                        };
                    }),
                    tap(() => setTimeout(() => {
                        this.appRef.tick();
                    })),
                )),
                of(null),
            )),
        );

        this.otherOrdersRoutes$ = combineLatest([
            this.drivers$,
            this.selectedOrderID$,
            this.selectedOrder$,
        ]).pipe(
            map(([drivers, selectedOrderID, selectedOrder]) => {
                const deliveryOrders = drivers
                // Extend order with deliveryDriverID
                    .map(driver => driver.deliveryOrders.map(order => ({
                        ...order,
                        deliveryDriverID: driver.deliveryDriverID,
                    })))
                    // Pluck and flatten orders from drivers
                    .reduce((aggr, deliveryOrders) => [...aggr, ...deliveryOrders], [])
                    // Skip currently selected order
                    .filter((order) => order.deliveryOrderID !== selectedOrderID);

                return { deliveryOrders, selectedOrder };
            }),

            switchMap(({ deliveryOrders, selectedOrder }) => {
                // @ts-ignore
                const requests = deliveryOrders.map((order) => this.getOrderRouteDirections(order).pipe(
                    map((orderDirections) => {

                        const drawPickupTime = !!selectedOrder
                            ? formatPickupTimeRelative(
                                new Date(selectedOrder.driverShouldPickupAt),
                                // @ts-ignore
                                order.driverShouldPickupAt,
                            )
                            : formatPickupTimeAbsolute(
                                order.driverShouldPickupAt,
                            );

                        return {
                            orderDirections,
                            severity: getOrderSeverity(order.driverShouldPickupAt),
                            drawPickupTime,
                            deliveryDriverID: order.deliveryDriverID,
                            deliveryOrderID: order.deliveryOrderID,
                            restaurantName: order.restaurantName,
                        } as ManageOrderDirectionsInfo;
                    }),
                ));

                return requests.length ? forkJoin(requests) : of([]);
            }),
        );

        this.disabledParamButtons$ = this.selectedOrder$.pipe(
            map(isOrderSelected => !isOrderSelected),
            share(),
        );

        this.initFormValues();
    }

    public initFormValues(): void {
        this.ordersParamsForm.setValue({
            showAssigned: false,
        });

        this.driversParamsForm.setValue({
            pickupDistance: DEFAULT_PICKUP_DISTANCE,
            deliveryDistance: DEFAULT_DELIVERY_DISTANCE,
            driverDistance: DEFAULT_DRIVER_DISTANCE,
            pickupTimeSpan: DEFAULT_PICKUP_TIME_SPAN,
        });
    }

    public selectOrder(order: ManageOrderBase): void {
        this.selectOrderByID(order.deliveryOrderID);
    }

    public hoverOrder(order: ManageOrderBase): void {
        this.handleRouteHover(order ? [order.deliveryOrderID] : []);
    }

    public selectOrderByID(deliveryOrderID: number | null): void {
        this.clearError();
        this.selectedOrderID$.next(+deliveryOrderID);
    }

    public handleRouteHover($event: number[]) {
        this.hoveredOrderIDs$.next($event);
        this.appRef.tick();
    }

    public handleRouteClick({ deliveryDriverID }: ManageOrderRouteProperties) {
        this.selectDriverByID(deliveryDriverID);
    }

    public selectDriver(driver: ManageOrderDriver): void {
        this.selectDriverByID(driver.deliveryDriverID);
    }

    public selectDriverByID(deliveryDriverID: number) {
        this.clearError();
        this.selectedDriverID$.next(deliveryDriverID);
    }

    public handleDriverClick($event: number): void {
        this.selectDriverByID($event);
    }

    public doAssignOrder({ order, driver, confirmOrder }: ManageOrderAssignOrderEvent): void {
        this.assignOrderError$.next(null);

        const params: ManageOrderAssignOrderParams = {
            fromDeliveryCompanyID: order.deliveryCompanyID,
            toDeliveryCompanyID: driver.deliveryCompanyID,
            toDeliveryDriverID: driver.deliveryDriverID,
            confirmOrder,
        };

        this.api.assignOrder(order.deliveryOrderID, params)
            .subscribe({
                error: this.assignErrorHandler,
                complete: this.assignSuccessHandler,
            });
    }

    public cancelAssignOrder(): void {
        this.deselectDriverAndOrder();
    }

    private getOrderRouteDirections(
        order: ManageOrderBase, withAlternatives: boolean = false): Observable<ManageOrderDirections> {
        // safe guard against unpositionable orders
        if (!isOrderPositionable(order)) {
            return of(null);
        }

        const { destLat, destLon, originLat, originLon } = order;
        const serializedCoords = [
            [originLon, originLat].join(','),
            [destLon, destLat].join(','),
        ].join(';');

        return this.api.getDirections(serializedCoords, withAlternatives);
    }

    private assignSuccessHandler = (): void => {
        this.dataChange$.next();
        this.cancelAssignOrder();
    };

    private assignErrorHandler = (err: any): void => {
        this.assignOrderError$.next(err);
    };

    private deselectDriverAndOrder(): void {
        setTimeout(() => {
            this.selectedOrderID$.next(undefined);
            this.selectedDriverID$.next(undefined);
            this.clearError();
        });
    }

    private clearError(): void {
        this.assignOrderError$.next(null);
    }

    private updateUrlLocation(id: number) {
        // update history state URL - without reloading entire page
        const url = this.router.createUrlTree([id], { relativeTo: this.route.parent }).toString();
        this.location.go(url);
    }

    private loadDistricts() {
        this.loading['districts'] = true;

        const params = {
            orderBy: 'name',
            sortBy: 'asc',
        };

        return this.api.getDistricts(params).pipe(
            shareReplay(1),
            finalize(() => this.loading['districts'] = false),
        );
    }
}
