import { isBoolean } from 'lodash';

function get(key: string) {
    const env = window['env'] || {};

    if (typeof env === 'undefined') {
        throw new Error('env not set correctly!');
    }

    if ( ! env.hasOwnProperty(key)) {
        throw new Error('env missing key: ' + key);
    }

    return env[key];
}

function string(key: string): string {
    return get(key).toString();
}

function boolean(key: string): boolean {
    let value = get(key);

    if (isBoolean(value)) {
        return value;
    }

    if (!isNaN(value)) {
        const radix = 10;
        return !!parseInt(value, radix);
    }

    if (['true', 'false'].indexOf(value.toString().toLowerCase())) {
        return value.toString().toLowerCase() === 'true';
    }

    return !!value;
}

function integer(key: string): number {
    let value = get(key);

    const radix = 10;
    return parseInt(value, radix);
}

function float(key: string): number {
    let value = get(key);

    return parseFloat(value);
}

export { get, string, boolean, integer, float };
