
import {mergeMap} from 'rxjs/operators';
import { RequestOptions, Headers, Http, Request, Response, RequestOptionsArgs } from "@angular/http";
import { Injectable } from "@angular/core";
import { HttpClient } from "../http-client";
import { of ,  Observable } from 'rxjs';

export type ResponseHook = (response: Observable<Response>) => Observable<Response>;

@Injectable()
export class HungrigClient extends HttpClient {

    public requestOptions: RequestOptions;

    protected responseHooks: ResponseHook[];

    constructor(public http: Http) {
        super(http);

        this.responseHooks = [];
        this.requestOptions = new RequestOptions({
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
    }

    public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.request(url, options)
        );
    }

    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.get(url, this.requestOptions.merge(options))
        );
    }

    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.post(url, body, options)
        );
    }

    public put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.put(url, body, options)
        );
    }

    public delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.delete(url, options)
        );
    }

    public patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.patch(url, body, options)
        );
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.head(url, options)
        );
    }

    public options(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.passThrough(
            () => super.options(url, options)
        );
    }

    public addResponseHook(hook: ResponseHook): void {
        this.responseHooks.push(hook);
    }

    protected hookResponse(response: Observable<Response>): Observable<Response> {
        this.responseHooks.forEach((hook) => response = hook(response));
        return response;
    }

    protected passThrough(callback: () => Observable<Response>): Observable<Response> {
        const responseObservable = of({}).pipe(mergeMap(() => callback()));
        return this.hookResponse(responseObservable);
    }

}
