import {
    Http, RequestOptions, Headers, RequestOptionsArgs, Response, Request
} from "@angular/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpClient {

    protected requestOptions: RequestOptions = new RequestOptions({
        headers: new Headers()
    });

    public baseUrl: string = '';

    constructor(public http: Http) {}

    public getOptions(options?: RequestOptionsArgs): RequestOptions {
        return this.requestOptions.merge(options);
    }

    public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.request(url, options);
    }

    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.get(url, this.getOptions(options));
    }

    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.post(url, JSON.stringify(body), this.getOptions(options));
    }

    public put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.put(url, JSON.stringify(body), this.getOptions(options));
    }

    public delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.delete(url, this.getOptions(options));
    }

    public patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.patch(url, JSON.stringify(body), this.getOptions(options));
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.head(url, this.getOptions(options));
    }

    public options(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.http.options(url, this.getOptions(options));
    }
}
