// layers and sources
export const SOURCE_DIRECTIONS = 'directions';
export const SOURCE_DRIVERS = 'markers';
export const SOURCE_DRIVERS_DIRECTIONS = 'drivers-directions';

export const LAYER_DRIVER_MARKER_STATES = 'markers-marker-states';
export const LAYER_DRIVER_MARKERS = 'markers-marker';
export const LAYER_ALTERNATIVE_ROUTE = 'alternative-route';
export const LAYER_DRIVER_ROUTES = 'order-route';
export const LAYER_ROUTES = 'route';

// colors
export const COLOR_ROUTE_SELECTED_ORDER = '#1d8aaf';
export const COLOR_ROUTE_ALT = '#a0a0a0';

export const COLOR_ROUTE_DEFAULT = '#3c763d';
export const COLOR_ROUTE_WARNING = '#ffc87f';
export const COLOR_ROUTE_DANGER = '#a85c6d';

export const COLOR_DRIVER_LOAD_WARNING = '#faebcc';
export const COLOR_DRIVER_LOAD_DEFAULT = '#d6e9c6';

export const COLOR_SELECTED = '#1e90ff';

// conditionals
export const COLOR_DEPENDS_ON_ORDER_SEVERITY = [
    'match',
    ['get', 'severity'],
    'severity-warning', COLOR_ROUTE_WARNING,
    'severity-danger', COLOR_ROUTE_DANGER,
    COLOR_ROUTE_DEFAULT,
];

export const COLOR_DEPENDS_ON_ORDER_COUNT = [
    'case',
    ['<', ['get', 'load'], 2],
    COLOR_DRIVER_LOAD_DEFAULT,
    COLOR_DRIVER_LOAD_WARNING,
];

export const WIDTH_DEPENDS_ON_STATE = [
    'case',
    ['boolean', ['feature-state', 'hovered'], false],
    4,
    ['boolean', ['feature-state', 'selected'], false],
    6,
    2,
];
