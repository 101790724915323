
import {takeUntil} from 'rxjs/operators';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocaleService } from '../locale.service';
import { Country } from '../country';
import { Subject } from 'rxjs';


@Directive({ selector: '[ifCountry]' })
export class IfCountryDirective implements OnInit, OnDestroy {
    @Input('ifCountry')
    public country: Country;

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        protected viewContainer: ViewContainerRef,
        protected templateRef: TemplateRef<any>,
        protected localeService: LocaleService
    ) {
    }

    public ngOnInit(): void {
        this.localeService.country$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((country) => this.toggleElement(country));
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected toggleElement(country: Country): void {
        if (country === this.country) {
            this.display();
        } else {
            this.clear();
        }
    }

    protected display(): void {
        this.viewContainer.createEmbeddedView(
            this.templateRef
        );
    }

    protected clear(): void {
        this.viewContainer.clear();
    }
}
