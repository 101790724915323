import { Pipe, PipeTransform } from '@angular/core';
import { addSeconds, format } from "date-fns";

@Pipe({
    name: 'asMinutes',
})
export class AsMinutesPipe implements PipeTransform {
    public transform(value: number): string {
        return `${format(addSeconds(new Date(0), value), 'mm')} min`;
    }
}
