import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import { DriverFormComponent } from './driver-form';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RoNg2Module,
        ButtonsModule
    ],
    exports: [],
    declarations: [ DriverFormComponent ],
    providers: [],
    entryComponents: [ DriverFormComponent ]
})
export class DeliveryDriverModule {
}
