import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompensationCalculatorDebitResult } from '../../../../interfaces';
import { DeliveryType, Order } from '@ro-ngx/orders';
import { DebtorOption, getDebtorOptions } from '../../../../common/options';
import { Debtor } from '../../../../enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompensationsConfig, TaxRate } from '../../../../compensations-config';
import { DeliveryCompany, DeliveryCompanyService } from '@ro-ngx/delivery';
import { getDebtorID } from '../../../../common/debtor';
import { MultipleSelect } from '@ro-ngx/core';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'configure-custom-debit',
    template: require('./configure-custom-debit.component.html')
})
export class ConfigureCustomDebitComponent implements OnInit {
    @Input()
    public customDebit: CompensationCalculatorDebitResult;

    @Input()
    public order: Order;

    public debtorOptions: DebtorOption[];

    public Debtor: typeof Debtor = Debtor;

    public form: FormGroup;

    public deliveryCompanySelect: MultipleSelect;

    public taxRates: TaxRate[];

    constructor(
        protected compensationsConfig: CompensationsConfig,
        protected deliveryCompanyService: DeliveryCompanyService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected formBuilder: FormBuilder
    ) {
    }

    public ngOnInit(): void {
        this.taxRates = this.compensationsConfig.taxRates;
        this.debtorOptions = getDebtorOptions(this.order);

        this.form = this.formBuilder.group({
            reason: [null, Validators.required],
            summaryReason: [null, Validators.required],
            debtor: [null, Validators.required],
            taxRate: [this.compensationsConfig.taxRates[0].value, Validators.required],
            debtorID: null,
            amount: [null, Validators.required]
        });

        this.form.get('debtor').valueChanges.subscribe((debtor) => {
            if (debtor === Debtor.DELIVERY_COMPANY) {
                this.deliveryCompanySelect.selectItem(this.order.deliveryorder.deliverycompany);
            } else if (debtor !== null) {
                this.form.get('debtorID').setValue(getDebtorID(this.order, debtor));
            }
        });

        if (this.order.deliveryType !== DeliveryType.Pickup) {
            this.initDeliveryCompanySelect();
        }

        this.form.patchValue(this.customDebit);
    }

    public isValid(): boolean {
        return this.form.valid;
    }

    public getValue(): any {
        return this.form.value;
    }

    protected initDeliveryCompanySelect(): void {
        this.deliveryCompanySelect = new MultipleSelect({
            onSelection: (deliveryCompany: DeliveryCompany) => {
                this.form.get('debtorID').setValue(deliveryCompany.deliveryCompanyID);
            },
            initialValue: this.order.deliveryorder.deliverycompany,
            items$: (multipleSelect) => {
                const debounceTimeMs: number = 600;
                return multipleSelect.searchText$
                    .pipe(
                        tap(() => multipleSelect.setLoading(true)),
                        debounceTime(debounceTimeMs),
                        switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                        tap(() => {
                            multipleSelect.setLoading(false);
                            this.changeDetectorRef.markForCheck();
                        })
                    );
            },
            itemKeyVisible: 'companyName',
            itemKeyId: 'deliveryCompanyID',
            maxSelections: 1
        });
    }
}
