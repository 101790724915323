import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NotificationStorage } from './notification.storage';
import { NotificationType } from './notification-type';
import { Observable } from 'rxjs';
import { NotificationInstance } from './notification-instance';

@Component({
    animations: [
        trigger('notificationState', [
            state('in', style({
                height: '*',
                opacity: '1',
                transform: 'translate(0, 0)',
            })),
            transition('void => *', [
                style({
                    height: '0',
                    opacity: '0',
                    transform: 'translate(0px, 20px)',
                }),
                animate('300ms')
            ]),
            transition('* => void', [
                style({
                    height: '*',
                    marginBottom: '*'
                }),
                animate('300ms', style({
                    height: '0',
                    marginBottom: '0',
                    opacity: '0',
                    transform: 'translate(0px, 20px)',
                }))
            ])
        ])
    ],
    selector: 'notifications',
    template: `
        <div class="error-notifications-container">
            <div *ngFor="let notification of notifications$ | async"
                 [@notificationState]>
                <div
                    class="alert alert-dismissable"
                    [ngClass]="getClass(notification.notification.type)">
        
                    <button type="button"
                            class="close"
                            (click)="dismissAlert(notification)"
                            data-dismiss="alert">&times;</button>
        
                    <strong>
                        <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="notification.notification.type === notificationType.Loading"></i>
                        {{ notification.notification.title }}
                    </strong>
                    <span>{{ notification.notification.body }}</span>
                </div>
            </div>
        </div>
    `
})
export class NotificationComponent implements OnInit {

    public notifications$: Observable<NotificationInstance[]>;

    public notificationType: typeof NotificationType = NotificationType;

    constructor(protected notificationStorage: NotificationStorage) {
    }

    public ngOnInit(): void {
        this.notifications$ = this.notificationStorage.notifications$;
    }

    public dismissAlert(notification: NotificationInstance): void {
        notification.dismiss();
    }

    public getClass(notificationType: NotificationType): string {
        const arr = [];
        arr[NotificationType.Info] = 'alert-info';
        arr[NotificationType.Loading] = 'alert-info';
        arr[NotificationType.Success] = 'alert-success';
        arr[NotificationType.Warning] = 'alert-warning';
        arr[NotificationType.Danger] = 'alert-danger';

        return arr[notificationType];
    }
}
