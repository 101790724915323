import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'order-list-header',
    template: `
        <span class="pickup-time-merged-header">{{'delivery.manage.orders.column_header_pickup_times' | translate}}</span>
        <span class="restaurant-header">{{'delivery.manage.orders.column_header_restaurant' | translate}}</span>
        <span class="distance-header">{{'delivery.manage.orders.column_header_distance' | translate}}</span>
    `,
    styles: [`
        :host {
            display: flex;
            color: #999;
            margin-bottom: 0.5em;
        }

        .pickup-time-merged-header {
            flex: 0 0 110px;
            padding: 0 10px;
        }

        .restaurant-header {
            flex: auto;
            padding-left: 40px;
        }

        .distance-header {
            flex: 0 0 50px;
            padding: 0 10px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListHeaderComponent {}
