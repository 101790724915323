import { Permission, Role as RoleContract } from "./interfaces";
import { PermissionName, RoleName } from "./enums";

export class Role implements RoleContract {
    public name: RoleName;
    public permissions: Permission[];

    constructor(data: Partial<RoleContract>) {
        Object.assign(this, data);
    }

    public findPermission(name: PermissionName): Permission {
        return this.permissions.find(((permission) => permission.name === name));
    }

    public hasPermission(name: PermissionName): boolean {
        return !! this.findPermission(name);
    }
}
