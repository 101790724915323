import { Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as mapboxgl from 'mapbox-gl';
import { OnChange } from 'property-watch-decorator';
import { RoMapStatesDirective } from '../../directives/ro-map-states.directive';
import { RoMapDirective } from '../../directives/ro-map.directive';
import { ManageOrderDistrict } from '../../models/district.models';
import { ManageOrderRouteProperties, ManageOrderTransportMode } from '../../models/driver.models';
import {ManageOrderDriver} from '../../models/manage-order-driver.models'
import { ManageOrderRouteInfo } from '../../models/manage-order-route-info.models';
import { ManageOrderDirectionsInfo } from '../../models/manage-order-directions-info.models';
import { allStyles } from '../../styles';
import {
    LAYER_DRIVER_MARKER_STATES,
    LAYER_DRIVER_ROUTES,
    SOURCE_DIRECTIONS,
    SOURCE_DRIVERS,
    SOURCE_DRIVERS_DIRECTIONS,
} from '../../styles/constants';

export enum RouteOptimizationField {
    Distance = 'distance',
    Duration = 'duration',
}

export interface ManageMapOptions {
    freezeMap: boolean;
    routeOptimalBy: RouteOptimizationField;
}

const DEFAULT_OPTIONS: ManageMapOptions = {
    routeOptimalBy: RouteOptimizationField.Distance,
    freezeMap: true,
};

const BLANK_GEOJSON = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: [],
    },
};

const DISAMBIGUATION_POPUP_CONTENT_CLASS = 'maxbox-popup--disambiguate';

@Component({
    selector: 'manage-order-map',
    template: require('./manage-order-map.component.html'),
    styles: [require('./manage-order-map.component.less')],
})
export class ManageOrderMapComponent implements OnDestroy {
    @OnChange<ManageOrderDriver[]>(function(value) {
        if (this.map) {
            this.plotDrivers(value || []);
        }
    })
    @Input() public drivers: ManageOrderDriver[] = [];

    @OnChange<ManageOrderDistrict>(function(value) {
        if (this.map) {
            this.mapboxState.clearStates();
            this.zoomAroundDistrict(value);
        }
    })
    @Input() public district: ManageOrderDistrict;

    @OnChange<ManageOrderDirectionsInfo>(function(value) {
        if (this.map) {
            this.plotOrder(value);
        }
    })
    @Input() public selectedOrderDirections: ManageOrderDirectionsInfo;

    @OnChange<ManageOrderDirectionsInfo[]>(function(value) {
        if (this.map) {
            this.plotDriverRoutes(value || []);
        }
    })
    @Input() public otherOrdersDirections: ManageOrderDirectionsInfo[];

    @OnChange<number[]>(function(value) {
        if (this.map) {
            this.selectRoutesOnDriver(value || []);
        }
    })
    @Input() public selectedDriverOrderIDs: number[];

    @OnChange<number>(function(value) {
        if (this.map) {
            this.selectDriverByID(value);
        }
    })
    @Input() public selectedDriverID: number;

    @OnChange<number[]>(function(value) {
        if (this.map) {
            this.hoverRoutesForOrders(value || []);
        }
    })
    @Input() public hoveredOrderIDs: number[] = [];

    @Output() public mapReady: EventEmitter<mapboxgl.Map> = new EventEmitter();
    @Output() public routeHover: EventEmitter<number[]> = new EventEmitter();
    @Output() public routeClick: EventEmitter<ManageOrderRouteProperties> = new EventEmitter();
    @Output() public driverClick: EventEmitter<number> = new EventEmitter();

    @ViewChild(RoMapDirective) public mapbox: RoMapDirective;
    @ViewChild(RoMapStatesDirective) public mapboxState: RoMapStatesDirective;

    private map: any;
    private _popup: any;

    constructor(
        private readonly ngZone: NgZone,
        private readonly translate: TranslateService,
    ) {
        this.options = DEFAULT_OPTIONS;
    }

    private _options: ManageMapOptions;

    public get options(): Partial<ManageMapOptions> {
        return this._options;
    }

    @Input()
    public set options(value: Partial<ManageMapOptions>) {
        this._options = {
            ...DEFAULT_OPTIONS,
            ...value,
        };
    };

    public initDriversMap(mapbox: mapboxgl.Map): void {
        this.setMapReady(mapbox);
        this.initMapSourcesAndLayers(mapbox);
        this.addMapListeners(mapbox);
        this.loadImages(() => {
            allStyles.forEach((style) => mapbox.addLayer(style));
        });
    }

    public ngOnDestroy(): void {
        this.removeMapListeners(this.map);
        this.ngZone.runOutsideAngular(() => {
            this.map.remove();
        });
    }

    protected loadImages(onComplete: () => void): void {
        const prefix = '/assets/@ro-ngx/delivery';

        const images = [
            { id: 'origin', url: `${prefix}/icon-restaurant-white-12.png` },
            { id: 'destination', url: `${prefix}/icon-restaurant-1d8aaf-12.png` },
            { id: 'bicycle', url: `${prefix}/icon-bicycle.png` },
            { id: 'car', url: `${prefix}/icon-car.png` },
        ];

        let loadedImages = 0;

        const onImageLoaded = () => {
            if (++loadedImages === images.length) {
                onComplete();
            }
        };

        images.forEach((image) => {
            this.loadImage(image.id, image.url, onImageLoaded);
        });
    }

    protected loadImage(id: string, url: string, onLoaded: () => void): void {
        this.map.loadImage(url, (error, image) => {
            if (error) {
                throw error;
            }

            this.map.addImage(id, image);
            onLoaded();
        });
    }

    /*
        Generates Feature objects for any type of route: selected or drivers
     */
    protected getRouteFeatures(routeInfo: ManageOrderRouteInfo): any[] {
        const {
            origin,
            destination,
            primaryRoute,
            alternativeRoutes = [],
            severity = '',
            drawPickupTime,
            deliveryOrderID,
            deliveryDriverID,
            restaurantName,
        } = routeInfo;

        return [
            {
                'type': 'Feature',
                id: deliveryOrderID,
                'properties': {
                    'type': 'primary',
                    severity,
                    deliveryOrderID,
                    deliveryDriverID,
                    restaurantName,
                } as ManageOrderRouteProperties,
                'geometry': primaryRoute.geometry,
            },
            {
                'type': 'Feature',
                id: deliveryOrderID,
                'properties': {
                    'point-type': 'origin',
                    severity,
                    drawPickupTime,
                    deliveryOrderID,
                    deliveryDriverID,
                    restaurantName,
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': origin,
                },
            },
            {
                'type': 'Feature',
                id: deliveryOrderID,
                'properties': {
                    'point-type': 'destination',
                    severity,
                    deliveryOrderID,
                    deliveryDriverID,
                    restaurantName,
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': destination,
                },
            },
            ...alternativeRoutes.map((route) => {
                return {
                    'type': 'Feature',
                    'properties': {
                        'type': 'alternative',
                        deliveryOrderID,
                    },
                    'geometry': route.geometry,
                };
            }),
        ];
    }

    private setMapReady(mapbox: mapboxgl.Map): void {
        this.map = mapbox;
        this.mapReady.emit(mapbox);
    }

    private zoomAroundDistrict(district: ManageOrderDistrict): void {
        if (!district) {
            return;
        }

        const { latitude, longitude } = district;
        if (longitude && latitude) {
            this.mapbox.zoomAround([longitude, latitude], 11);
        }
    }

    private initMapSourcesAndLayers(mapbox: any): void {
        mapbox.addSource(SOURCE_DRIVERS, BLANK_GEOJSON);
        mapbox.addSource(SOURCE_DIRECTIONS, BLANK_GEOJSON);
        mapbox.addSource(SOURCE_DRIVERS_DIRECTIONS, BLANK_GEOJSON);
    }

    private addMapListeners(mapbox: any): void {
        mapbox.on('click', LAYER_DRIVER_MARKER_STATES, this.driverClickHandler);
        mapbox.on('mouseenter', LAYER_DRIVER_MARKER_STATES, this.driverMouseenterHandler);
        mapbox.on('mouseleave', LAYER_DRIVER_MARKER_STATES, this.driverMouseleaveHandler);

        mapbox.on('click', LAYER_DRIVER_ROUTES, this.routeClickHandler);
        mapbox.on('mouseenter', LAYER_DRIVER_ROUTES, this.routeMouseenterHandler);
        mapbox.on('mouseleave', LAYER_DRIVER_ROUTES, this.routeMouseleaveHandler);
    }

    private removeMapListeners(mapbox: any) {
        mapbox.off('click', LAYER_DRIVER_MARKER_STATES, this.driverClickHandler);
        mapbox.off('mouseenter', LAYER_DRIVER_MARKER_STATES, this.driverMouseenterHandler);
        mapbox.off('mouseleave', LAYER_DRIVER_MARKER_STATES, this.driverMouseleaveHandler);

        mapbox.off('click', LAYER_DRIVER_ROUTES, this.routeClickHandler);
        mapbox.off('mouseenter', LAYER_DRIVER_ROUTES, this.routeMouseenterHandler);
        mapbox.off('mouseleave', LAYER_DRIVER_ROUTES, this.routeMouseleaveHandler);
    }

    private driverClickHandler = e => {
        const drivers = e.features;
        const mapbox = e.target;

        if (drivers.length === 1) {
            const driver = drivers[0];

            if (driver) {
                this.driverClick.emit(driver.properties.deliveryDriverID);
            }

        } else if (drivers.length > 1) {
            console.info(`${drivers.length} drivers found at this location. Try to zoom in.`);

            const coordinates = e.features[0].geometry.coordinates;
            this._popup = this.showAmbiguousDriverPopup({ coordinates, drivers, mapbox });
        }
    };

    private driverMouseenterHandler = e => {
        e.target.getCanvas().style.cursor = 'pointer';
    };

    private driverMouseleaveHandler = e => {
        e.target.getCanvas().style.cursor = '';
    };

    private routeClickHandler = (e) => {
        const routes = e.features;
        const mapbox = e.target;

        if (routes.length === 1) {
            const [route] = routes;

            if (route) {
                this.routeClick.emit(route.properties);
            }
        } else if (routes.length > 1) {
            console.info(`${routes.length} routes found at this location. Try to zoom in.`);

            const coordinates = e.lngLat;
            this._popup = this.showAmbiguousRoutePopup({ coordinates, routes, mapbox });
        }
    };

    private routeMouseenterHandler = (e) => {
        this.routeHover.emit(e.features.map((f => f.id)));
    };

    private routeMouseleaveHandler = () => {
        this.routeHover.emit([]);
    };

    private plotOrder(directionsInfo: ManageOrderDirectionsInfo): void {

        if (!this.map) {
            this.mapNotReady();
            return;
        }

        this.plotOrderRoute(directionsInfo);

        if (!directionsInfo) {
            return;
        }

        const { orderDirections } = directionsInfo;
        if (!this.options.freezeMap) {
            this.fitBoundsOrderRoute(orderDirections.routes);
        }
    }

    private plotOrderRoute(directionsInfo: ManageOrderDirectionsInfo): void {
        if (!directionsInfo || !directionsInfo.orderDirections || !directionsInfo.orderDirections.routes.length) {
            this.map.getSource(SOURCE_DIRECTIONS).setData(BLANK_GEOJSON.data);
            return;
        }

        const { orderDirections, severity, drawPickupTime, deliveryOrderID, deliveryDriverID, restaurantName } = directionsInfo;

        const { waypoints, routes } = orderDirections;
        const origin = waypoints[0].location;
        const destination = waypoints[waypoints.length - 1].location;

        const sortField = this.options.routeOptimalBy;
        const sorterFn = (a, b) => a[sortField] - b[sortField];
        const [primaryRoute] = routes.sort(sorterFn); // skipping rest (alternativeRoutes) to prevent clutter

        const features = this.getRouteFeatures({
            origin,
            destination,
            primaryRoute,
            alternativeRoutes: [], // @DECIDE if we need to show alt routes
            severity,
            drawPickupTime,
            deliveryOrderID,
            deliveryDriverID,
            restaurantName,
        });

        this.map.getSource(SOURCE_DIRECTIONS).setData({
            type: 'FeatureCollection',
            features,
        });
    }

    private plotDriverRoutes(directionsInfos: ManageOrderDirectionsInfo[] = []): void {
        if (!directionsInfos.length) {
            this.map.getSource(SOURCE_DRIVERS_DIRECTIONS).setData(BLANK_GEOJSON.data);
            return;
        }

        // Plot all order routes
        const orderRoutes = directionsInfos
            .map((directionsInfo) => {
                const { orderDirections, severity, drawPickupTime, deliveryOrderID, deliveryDriverID, restaurantName } = directionsInfo;

                // handle missing geo coordinates for some orders
                if (!orderDirections) {
                    return null;
                } else {
                    const { waypoints, routes } = orderDirections;
                    const origin = waypoints[0].location;
                    const destination = waypoints[waypoints.length - 1].location;

                    const sortField = this.options.routeOptimalBy;
                    const sorterFn = (a, b) => a[sortField] - b[sortField];
                    const [primaryRoute] = routes.sort(sorterFn);

                    return this.getRouteFeatures({
                        origin,
                        destination,
                        primaryRoute,
                        severity,
                        drawPickupTime,
                        deliveryOrderID,
                        deliveryDriverID,
                        restaurantName,
                    });
                }
            })
            .reduce((aggr, curr) => curr ? [...aggr, ...curr] : aggr, []);

        this.map.getSource(SOURCE_DRIVERS_DIRECTIONS).setData({
            type: 'FeatureCollection',
            features: orderRoutes,
        });
    }

    private plotDrivers(drivers: ManageOrderDriver[] = []): void {
        if (!this.map) {
            this.mapNotReady();
            return;
        }

        const driverMarkers = {
            type: 'FeatureCollection',
            features: drivers
                .filter((driver) => driver.driverPositions) // Occasional lack of driverPositions.
                .map((driver) => {
                    const {
                        deliveryDriverID,
                        deliveryOrders,
                        driverName,
                        driverPositions: { longitude, latitude },
                    } = driver;

                    return {
                        type: 'Feature',
                        id: deliveryDriverID,
                        properties: {
                            deliveryDriverID,
                            driverName,
                            load: deliveryOrders.length,
                            icon: driver.transportMode === ManageOrderTransportMode.Car ? 'car' : 'bicycle',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude],
                        },
                    };
                }),
        };

        this.map.getSource(SOURCE_DRIVERS).setData(driverMarkers);
    }

    private selectDriverByID(selectedDriverID: number): void {
        if (typeof selectedDriverID !== 'undefined') {
            this.mapboxState.selectDrivers([selectedDriverID]);
        }
    }

    private selectRoutesOnDriver(orderIDs) {
        this.mapboxState.selectDriverRoutes(orderIDs);
    }

    private hoverRoutesForOrders(orderIDs) {
        this.mapboxState.hoverRoutes(orderIDs);
    }

    private fitBoundsOrderRoute(routes: any[]): void {
        if (!routes.length) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            const { coordinates } = routes[0].geometry;
            const bounds = coordinates.reduce(
                (aggr, curr) => aggr.extend(curr),
                new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
            );

            this.map.fitBounds(bounds, {
                maxZoom: 15,
                padding: 25,
                speed: 10,
            });
        });
    }

    private showAmbiguousDriverPopup = ({ coordinates, drivers, mapbox }): mapboxgl.Popup => {
        const popup = new mapboxgl.Popup({
            closeButton: false,
            className: DISAMBIGUATION_POPUP_CONTENT_CLASS,
        });

        const popupDOMContent = document.createElement('div');

        const popupHeader = document.createElement('p');
        popupHeader.textContent = this.translate.instant(
            'delivery.manage.map.driver_disambiguation_message_header', { count: drivers.length },
        ) + ':';

        const buildPopupDOMContent = (_drivers: any[]): any => {
            const DATA_ATTR = 'data-deliveryDriverID';
            const popupBody = document.createElement('ul');

            const listener = (e) => {
                e.preventDefault();
                const id = e.target.closest('li').getAttribute(DATA_ATTR);
                const found = _drivers.find(driver => driver.id === +id);

                if (found) {
                    this.routeClick.emit(found.properties);
                    popup.remove();
                }
            };

            const links = _drivers
                .map(({ properties }) => {
                    const li = document.createElement('li');
                    li.setAttribute(DATA_ATTR, properties.deliveryDriverID);

                    const link = document.createElement('a');
                    link.setAttribute('href', '#');
                    link.innerHTML = `<b>[${properties.driverName.toUpperCase()}]</b>`;

                    li.appendChild(link);
                    return li;
                });

            links.forEach(link => popupBody.appendChild(link));

            popup.on('open', () => {
                links.forEach(link => link.addEventListener('click', listener));
            });

            popup.on('close', () => {
                links.forEach(link => link.removeEventListener('click', listener));
            });

            return popupBody;
        };

        popupDOMContent.appendChild(popupHeader);
        popupDOMContent.appendChild(buildPopupDOMContent(drivers));

        return popup
            .setLngLat(coordinates)
            .setDOMContent(popupDOMContent)
            .addTo(mapbox);
    };

    private showAmbiguousRoutePopup = ({ coordinates, routes, mapbox }): mapboxgl.Popup => {
        const popup = new mapboxgl.Popup({
            closeButton: false,
            className: DISAMBIGUATION_POPUP_CONTENT_CLASS,
        });

        const popupDOMContent = document.createElement('div');

        const popupHeader = document.createElement('p');
        popupHeader.textContent = this.translate.instant(
            'delivery.manage.map.route_disambiguation_message_header', { count: routes.length },
        ) + ':';

        const buildPopupDOMContent = (_routes: any[]): any => {
            const DATA_ATTR = 'data-deliveryOrderID';
            const popupBody = document.createElement('ul');

            const listener = (e) => {
                e.preventDefault();
                const id = e.target.closest('li').getAttribute(DATA_ATTR);
                const found = _routes.find(route => route.id === +id);

                if (found) {
                    this.routeClick.emit(found.properties);
                    popup.remove();
                }
            };

            const links = _routes
                .map(({ properties }) => {
                    const li = document.createElement('li');
                    li.setAttribute(DATA_ATTR, properties.deliveryOrderID);

                    const link = document.createElement('a');
                    link.setAttribute('href', '#');
                    link.innerHTML = `<b>${properties.restaurantName}</b> (${properties.deliveryOrderID})`;

                    li.appendChild(link);
                    return li;
                });

            links.forEach(link => popupBody.appendChild(link));

            popup.on('open', () => {
                links.forEach(link => link.addEventListener('click', listener));
            });

            popup.on('close', () => {
                links.forEach(link => link.removeEventListener('click', listener));
            });

            return popupBody;
        };

        popupDOMContent.appendChild(popupHeader);
        popupDOMContent.appendChild(buildPopupDOMContent(routes));

        return popup
            .setLngLat(coordinates)
            .setDOMContent(popupDOMContent)
            .addTo(mapbox);
    };

    private mapNotReady(): void {
        console.info('map not ready');
    }
}
