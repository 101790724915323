
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { PaymentInfo, PaymentRefund } from '../interfaces';
import { PaymentProcessor } from '../enums/';

export interface PaymentReference {
    paymentProcessor: PaymentProcessor;
    paymentProcessorID: number;
}

export interface RefundPaymentOptions {
    amount?: number;
}

@Injectable()
export class PaymentService {

    constructor(public hungrigClient: HungrigClient) {
    }

    public getPaymentInfo(paymentProcessor: PaymentProcessor, paymentProcessorID: string): Observable<PaymentInfo> {
        const url = `${this.hungrigClient.baseUrl}/payment/${paymentProcessor}/${paymentProcessorID}/info`;
        return this.hungrigClient.get(url).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getPaymentRefunds(
        paymentProcessor: PaymentProcessor,
        paymentProcessorID: string
    ): Observable<PaymentRefund[]> {
        const url = `${this.hungrigClient.baseUrl}/payment/${paymentProcessor}/${paymentProcessorID}/paymentRefunds`;
        return this.hungrigClient.get(url).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    /**
     * Refund part of a
     */
    public refundPayment(
        paymentProcessor: PaymentProcessor,
        paymentProcessorID: string,
        options: RefundPaymentOptions
    ): Observable<boolean> {
        const url = `${this.hungrigClient.baseUrl}/payment-refund/${paymentProcessor}/${paymentProcessorID}`;
        return this.hungrigClient.post(url, options).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    /**
     * Get processor's information about refund.
     */
    public getRefundInfo(paymentRefund: PaymentRefund): Observable<PaymentInfo> {
        const url = `${this.hungrigClient.baseUrl}/payment-refund/${paymentRefund.oPaymentRefundID}/info`;
        return this.hungrigClient.get(url).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
