import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-linear',
    template: `
        <div class="loading-linear-wrapper"
            *ngIf="loadingIf">
            <div class="loader-linear">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    `
})
export class LoadingLinearComponent {

    @Input()
    public loadingIf: boolean = false;
}
