import { IControl } from 'mapbox-gl';

export interface FitCountryBoundsControlOptions {
    country: {
        name: string;
        onClick: EventListenerOrEventListenerObject;
    };
    features?: {
        onClick: EventListenerOrEventListenerObject;
    };
}

/**
 * RoFitBoundsControl
 *
 * @description
 * A Custom Mapbox IControl for fitting API country
 */
export class RoFitBoundsControl implements IControl {
    private _map: any;
    private _container: HTMLElement;
    private fitCountryEl: HTMLButtonElement;
    private fitFeaturesEl: HTMLButtonElement;

    constructor(private options?: FitCountryBoundsControlOptions) {
    }

    public onAdd(map: any): HTMLElement {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

        this._container.appendChild(this.buildCountryContainer());

        if (this.options.features) {
            this._container.appendChild(this.buildPointsContainer());
        }

        return this._container;
    }

    public onRemove(): void {
        const { country } = this.options;
        this.fitCountryEl.removeEventListener('click', country.onClick);

        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    private buildCountryContainer(): HTMLButtonElement {
        const { country } = this.options;

        const el = document.createElement('button');
        el.className = 'mapboxgl-ctrl-icon';
        el.innerHTML = country.name.toUpperCase();
        el.style.fontWeight = 'bold';

        if (typeof country.onClick === 'function') {
            el.addEventListener('click', country.onClick);
        }

        return (this.fitCountryEl = el);
    }

    private buildPointsContainer(): HTMLButtonElement {
        const { features } = this.options;

        const el = document.createElement('button');
        el.className = 'mapboxgl-ctrl-icon';
        el.innerHTML = '<i class="fa fa-arrows-alt"></i>';

        if (typeof features.onClick === 'function') {
            el.addEventListener('click', features.onClick);
        }

        return (this.fitFeaturesEl = el);
    }
}
