import * as Velocity from 'velocity-animate';
import { animate, transition, trigger, AnimationEvent } from '@angular/animations';
import { AnimationCarrier } from './animation-carrier';

export const slideDown: AnimationCarrier = {
    ng: trigger('slide', [
            transition('void => *', [
                animate('300ms')
            ]),
            transition('* => void', [
                animate('300ms')
            ])
        ]),

    velocity: (event: AnimationEvent) => {
        if (event.toState === 'void') {
            Velocity(event.element, { opacity: [0, 1] }, { duration: event.totalTime, easing: 'ease-in-out' });
            Velocity(event.element, 'slideUp', { duration: event.totalTime, easing: 'ease-in-out', queue: false });
        }

        if (event.toState === 'in') {
            Velocity(event.element, { opacity: [1, 0] }, { duration: event.totalTime, easing: 'ease-in-out' });
            Velocity(event.element, 'slideDown', { duration: event.totalTime, easing: 'ease-in-out', queue: false });
        }
    }
};
