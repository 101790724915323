import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2 } from '@angular/core';
import { COUNTRY_FIT_BOUNDS } from '@ro-ngx/mapbox-support';
import * as mapboxgl from 'mapbox-gl';
import { DeliveryManageModuleConfig } from "../delivery-manage-module-config";

const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/streets-v9';

@Directive({
    selector: '[roMap]',
    exportAs: 'roMap',
})
export class RoMapDirective implements OnInit {
    @Input() public mapStyle: string = DEFAULT_MAP_STYLE;
    @Output() public loaded: EventEmitter<mapboxgl.Map> = new EventEmitter();
    private readonly el: HTMLElement;
    private map: any;
    private mapLoaded: boolean = false;

    constructor(
        private readonly config: DeliveryManageModuleConfig,
        private readonly elRef: ElementRef,
        private readonly ngZone: NgZone,
        private readonly renderer: Renderer2,
    ) {
        this.el = elRef.nativeElement;
    }

    public ngOnInit(): void {
        this.initMap();
    }

    public fitCountryBounds(
        country: string = this.config.appCountry || 'se',
        fitBoundsOptions: mapboxgl.FitBoundsOptions = { padding: 50 },
    ): void {

        this.ngZone.runOutsideAngular(() => {
            this.map.fitBounds(COUNTRY_FIT_BOUNDS[country], fitBoundsOptions);
        });
    }

    public getMap(): any {
        return this.map;
    }

    public zoomAround(center: mapboxgl.LngLatLike, zoom: number = 10): void {
        this.map.setCenter(center).setZoom(zoom);
    }

    private initMap(): void {
        // Container height
        this.renderer.setStyle(this.el, 'height', '100%');

        this.ngZone.runOutsideAngular(() => {
            Object.assign(mapboxgl, {
                accessToken: this.config.accessToken
            });

            this.map = new mapboxgl.Map({
                container: this.el,
                style: this.mapStyle,
            });

            this.map.addControl(new mapboxgl.NavigationControl());

            this.map.on('load', (e) => {
                this.mapLoaded = true;
                this.loaded.emit(e.target);
            });
        });
    }
}
