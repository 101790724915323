import { string, boolean, integer } from './hungrig-environment';

export class Env {
    public static ENV: string = string('ENV');
    public static DEBUG: boolean = boolean('DEBUG');

    public static API_URL: string = string('API_URL');
    public static DEFAULT_AUTHORIZED_URL: string = string('DEFAULT_AUTHORIZED_URL');

    public static MODULES: string = string('MODULES');
    public static IFRAMED: boolean = boolean('IFRAMED');

    public static USERNAME_SUFFIX: string = string('USERNAME_SUFFIX');

    public static BRAND_NAME: string = string('BRAND_NAME');

    public static LANGUAGE_DEFAULT: string = string('LANGUAGE_DEFAULT');
    public static LANGUAGE_FALLBACK: string = string('LANGUAGE_FALLBACK');
    public static COUNTRY: string = string('COUNTRY');

    public static CURRENCY: string = string('CURRENCY');
    public static CURRENCY_MULTIPLIER: number = integer('CURRENCY_MULTIPLIER');
    public static CURRENCY_DECIMAL_POINTS: number = integer('CURRENCY_DECIMAL_POINTS');
    public static TAX_RATES: string = string('TAX_RATES');

    public static DISPLAY_TAX_RATES: boolean = boolean('DISPLAY_TAX_RATES');
}
