import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { Env } from './enviroment';
import { AuthAdminGuard } from '@ro-ngx/authentication';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: Env.DEFAULT_AUTHORIZED_URL
    },
    {
        loadChildren: 'app/components/restaurant/login/login.module#LoginModule',
        path: 'login',
        data: { displayNavbar: false }
    },
    {
        loadChildren: 'app/components/product-catalog/product-catalog.module#ProductCatalogModule',
        path: 'product-catalog',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: 'app/components/qr-code/qr-code.module#QrCodeModule',
        path: 'qr-code',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'transaction-history',
        redirectTo: 'order-history'
    },
    {
        loadChildren: '@ro-ngx/order-history#OrderHistoryModule',
        path: 'order-history',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: '@ro-ngx/summary-history#SummaryHistoryModule',
        path: 'summary-history',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: 'app/components/restaurant-summaries/restaurant-summaries.module#RestaurantSummariesModule',
        path: 'restaurant-summaries',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: '@ro-ngx/customer-export#CustomerExportModule',
        path: 'customers/export',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: '@ro-ngx/push-notification#PushNotificationModule',
        path: 'push-notifications',
        canActivate: [AuthAdminGuard]
    },
    {
        loadChildren: '@ro-ngx/coupons#CouponManagerModule',
        path: 'coupons',
        canActivate: [AuthAdminGuard]
    },
    {
        component: PageNotFoundComponent,
        path: '**',
        canActivate: [AuthAdminGuard]
    }
];

export { routes };
