
import {catchError, map, } from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ErrorFactory, HungrigClient, HungrigResponse, ResponseFactory } from "@ro-ngx/core";
import { ENV } from "../../enviroment";
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { RequestOptionsArgs } from '@angular/http';


@Injectable()
export abstract class BaseService {

    public apiUrl: string = ENV.API_LOCATION + '/';

    public resourceUrl: string = '';

    constructor(public hungrigClient: HungrigClient) { }

    public get<T>(url: string, options?: RequestOptionsArgs|any): Observable<T> {
        return this.hungrigClient.get(this.url(url), options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public post<T>(url: string, body: any, options?: RequestOptionsArgs|any): Observable<T> {
        return this.hungrigClient.post(this.url(url), body, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public delete<T>(url: string): Observable<T> {
        return this.hungrigClient.delete(this.url(url)).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    protected url(url: string = '', includeResourceUrl: boolean = true): string {
        return this.apiUrl + ( includeResourceUrl ? this.resourceUrl : '' ) + url;
    }

    protected toResponse(response: any): HungrigResponse {
        const body = response.json();

        return new HungrigResponse(body);
    }

    protected handleError(error: any): ErrorObservable<any> {
        const errorMessage = (error.message) ? error.message :
            error.status ? error.status + '-' + error.statusText : 'Server error';

        console.error(errorMessage);
        return observableThrowError(errorMessage);
    }
}
