import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from '@ro-ngx/core';
import { DeliveryOrderAction } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class DeliveryOrderActionService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public createAction(
        deliveryOrderID: number, action: Partial<DeliveryOrderAction>
    ): Observable<DeliveryOrderAction> {
        const url = `${this.hungrigClient.baseUrl}/delivery-orders/${deliveryOrderID}/delivery-order-actions`;
        return this.post(url, action);
    }

    public deleteAction(deliveryOrderID: number, dOrderActionID: number): Observable<boolean> {
        const url = `${this.hungrigClient.baseUrl}/delivery-orders/${deliveryOrderID}`
            + `/delivery-order-actions/${dOrderActionID}`;
        return this.delete(url);
    }
}
