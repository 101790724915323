import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'driver-list-header',
    template: `
        <span class="driver-header">{{'delivery.manage.drivers.column_header_driver' | translate}}</span>
        <span class="deliveries-header">{{'delivery.manage.drivers.column_header_deliveries' | translate}}</span>
    `,
    styles: [`
        :host {
            display: flex;
            color: #999;
            margin-bottom: 0.5em;
        }

        span {
            flex: 1 1 50%;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverListHeaderComponent {}
