export const directionStyles: any[] = [{
    'id': 'alternative-route',
    'type': 'line',
    'source': 'directions',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': '#a0a0a0',
        'line-width': 4
    },
    'filter': [
        'all',
        ['in', '$type', 'LineString'],
        ['==', 'type', 'alternative']
    ]
}, {
    'id': 'route',
    'type': 'line',
    'source': 'directions',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': '#1d8aaf',
        'line-width': 4
    },
    'filter': [
        'all',
        ['in', '$type', 'LineString'],
        ['==', 'type', 'primary']
    ]
}, {
    'id': 'directions-origin-point-circle-border',
    'type': 'circle',
    'source': 'directions',
    'paint': {
        'circle-radius': 12,
        'circle-color': '#000'
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'origin']
    ]
}, {
    'id': 'directions-origin-point-circle',
    'type': 'circle',
    'source': 'directions',
    'paint': {
        'circle-radius': 10,
        'circle-color': '#fff'
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'origin']
    ]
}, {
    'id': 'directions-origin-point',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
        'icon-image': 'origin',
        'icon-size': 0.03
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'origin']
    ]
}, {
    'id': 'directions-destination-point-circle-border',
    'type': 'circle',
    'source': 'directions',
    'paint': {
        'circle-radius': 12,
        'circle-color': '#000'
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'destination']
    ]
}, {
    'id': 'directions-destination-point-circle',
    'type': 'circle',
    'source': 'directions',
    'paint': {
        'circle-radius': 10,
        'circle-color': '#1d8aaf'
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'destination']
    ]
}, {
    'id': 'directions-destination-point',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
        'icon-image': 'destination',
        'icon-size': 0.02
    },
    'filter': [
        'all',
        ['in', '$type', 'Point'],
        ['==', 'id', 'destination']
    ]
}, {
    'id': 'markers-marker',
    'type': 'symbol',
    'source': 'markers',
    'layout': {
        'icon-image': '{icon}',
        'icon-size': 0.75
    },
    'filter': [
        'all',
        ['in', '$type', 'Point']
    ]
}];
