import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencySupport } from '@ro-ngx/core';
import { Debtor, RefundType, ValueType } from '../enums';
import { Observable } from 'rxjs';

@Injectable()
export class CompensationUtilService {

    constructor(
        protected translateService: TranslateService,
        protected currency: CurrencySupport
    ) {
    }

    public formatValue(value: number, valueType: ValueType): string {
        switch (valueType) {
            case ValueType.FLAT_AMOUNT:
                return this.currency.text(value);
            case ValueType.PERCENTAGE:
                return `${value}%`;
            default:
                return null;
        }
    }

    public getRefundTypeTitle(refundType: RefundType): Observable<string> {
        switch (refundType) {
            case RefundType.MONEY_BACK:
                return this.translateService.get('order_compensation.refund_type.money_back');
            case RefundType.COUPON:
                return this.translateService.get('order_compensation.refund_type.coupon');
            default:
                return null;
        }
    }

    public getDebtorTitle(debtor: Debtor): Observable<string> {
        switch (debtor) {
            case Debtor.RESTAURANT:
                return this.translateService.get('order_compensation.debtor.restaurant');
            case Debtor.DELIVERY_COMPANY:
                return this.translateService.get('order_compensation.debtor.delivery_company');
            case Debtor.DELIVERY_DRIVER:
                return this.translateService.get('order_compensation.debtor.delivery_driver');
            case Debtor.HUNGRIG:
                return this.translateService.get('general.creatives');
            default:
                return null;
        }
    }
}
