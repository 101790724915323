import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import { DeliveryDistrictsConfigModule } from './delivery-districts-config.module';
import { deliveryDistrictRoutes } from './delivery-districts.routes';
import { DistrictMapComponent } from './district-map/district-map.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DistrictsPageComponent } from './districts-page/districts-page.component';
import { DistrictFormComponent } from './district-form/district-form.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    declarations: [
        DistrictsPageComponent,
        DistrictMapComponent,
        DistrictFormComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(deliveryDistrictRoutes),
        TranslateModule.forChild(),
        ReactiveFormsModule,
        DeliveryDistrictsConfigModule,
        RoNg2Module,
        ModalModule,
        BsDropdownModule,
    ],
    exports: [
        DistrictsPageComponent,
    ]
})
export class DeliveryDistrictsModule {}
