import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable ,  BehaviorSubject ,  forkJoin } from 'rxjs';
import { PaymentProcessor } from '../../enums';
import { Order, PaymentInfo, PaymentInfoRow, PaymentRefund } from '../../interfaces';
import { PaymentService } from '../../services';
import { BaseComponent } from '../../base.component';
import { map, share, switchMap, takeUntil } from 'rxjs/operators';
import { Util } from '../../util';

@Component({
    selector: 'order-payment-info',
    template: require('./order-payment-info.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPaymentInfoComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public order: Order;
    public order$: BehaviorSubject<Order>;
    public paymentInfo$: Observable<PaymentInfo>;
    public paymentRefunds$: Observable<PaymentRefund[]>;
    public translationMap: { [key: string]: string } = {
        [PaymentProcessor.Braintree]: 'braintree',
        [PaymentProcessor.BraintreeVault]: 'braintree',
        [PaymentProcessor.DibsD2]: 'dibs_d2',
        [PaymentProcessor.DibsD2Ticket]: 'dibs_d2',
        [PaymentProcessor.Tpay]: 'tpay',
        [PaymentProcessor.TpayBlik]: 'tpay',
        [PaymentProcessor.PayU]: 'pay_u',
        [PaymentProcessor.Svea]: 'svea',
        [PaymentProcessor.Invoice]: 'invoice',
        [PaymentProcessor.SwishECommerce]: 'swish',
        [PaymentProcessor.SwishMCommerce]: 'swish',
        [PaymentProcessor.KlarnaPayLater]: 'klarna',
        [PaymentProcessor.KlarnaPayNow]: 'klarna',
    };

    constructor(protected paymentService: PaymentService) {
        super();
    }

    public ngOnInit(): void {


        this.order$ = new BehaviorSubject<Order>(this.order);

        const payment$ = this.order$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap(() => forkJoin(
                        this.paymentService.getPaymentInfo(this.order.paymentProcessor, this.order.paymentProcessorID),
                        this.paymentService.getPaymentRefunds(
                            this.order.paymentProcessor, this.order.paymentProcessorID
                        )
                    )
                ),
                share()
            );

        this.paymentInfo$ = payment$.pipe(
            map((arr) => arr[0]),
            map((paymentInfo) => {
                paymentInfo.rows = paymentInfo.rows.map((row) => this.transformPaymentInfoRow(row));
                return paymentInfo;
            })
        );

        this.paymentRefunds$ = payment$.pipe(
            map((arr) => arr[1]),
            map((refunds) => refunds.map(
                (refund) => this.transformPaymentRefund(refund))
            )
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['order'] && ! changes['order'].isFirstChange()) {
            this.order$.next(changes['order'].currentValue);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.order$.complete();
    }

    protected transformPaymentInfoRow(row: PaymentInfoRow): PaymentInfoRow {
        row.key = 'payment.' + this.translationMap[this.order.paymentProcessor] + '.' + row.key;

        if (row.type === 'timestamp') {
            row.value = Util.fullDate(row.value);
        }

        return row;
    }

    protected transformPaymentRefund(refund: PaymentRefund): PaymentRefund {

        refund.requestedAt = Util.fullDate(refund.requestedAt);
        refund.deniedAt = Util.fullDate(refund.deniedAt);
        refund.acceptedAt = Util.fullDate(refund.acceptedAt);
        return refund;
    }
}
