import { Injectable } from '@angular/core';
import { HungrigClient, BaseService } from '@ro-ngx/core';
import { DeliveryOrderRescheduleRequest } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class DeliveryOrderScheduleService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public createRescheduleRequest(
        rescheduleRequest: Partial<DeliveryOrderRescheduleRequest>
    ): Observable<DeliveryOrderRescheduleRequest> {
        const url = `${this.hungrigClient.baseUrl}/delivery-order-reschedule-requests`;
        return this.post(url, rescheduleRequest);
    }

    public confirmRescheduleRequest(rescheduleRequestID: number): Observable<DeliveryOrderRescheduleRequest> {
        const url = `${this.hungrigClient.baseUrl}/delivery-order-reschedule-requests/${rescheduleRequestID}/confirm`;
        return this.put(url, {});
    }

    public denyRescheduleRequest(rescheduleRequestID: number): Observable<DeliveryOrderRescheduleRequest> {
        const url = `${this.hungrigClient.baseUrl}/delivery-order-reschedule-requests/${rescheduleRequestID}/deny`;
        return this.put(url, {});
    }

    public settleRescheduleRequest(rescheduleRequestID: number): Observable<DeliveryOrderRescheduleRequest> {
        const url = `${this.hungrigClient.baseUrl}/delivery-order-reschedule-requests/${rescheduleRequestID}/settle`;
        return this.put(url, {});
    }
}
