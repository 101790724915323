import { Response } from '@angular/http';

export const CONTENT_DISPOSITION_FILENAME_REGEX: RegExp = /filename[^;\n=]*=((['"])(.*?)\2|[^;\n]*)/;

export const CONTENT_TYPE_FILE_SUFFIX_MAP: { [key: string]: string } = {
    'application/pdf': '.pdf',
    'application/zip': '.zip',
    'text/csv': '.csv'
};

export class FileResponse {
    public blob: Blob;
    public filename: string;

    constructor(response: Response) {
        this.filename = this.getFilename(response);
        this.blob = response.blob();
    }

    protected getFilename(response: Response): string {
        let filename: string = this.getAttachmentFilename(response);

        if (filename === null) {
            filename = Date.now().toString();

            if (
                response.headers.has('Content-Type') &&
                CONTENT_TYPE_FILE_SUFFIX_MAP.hasOwnProperty(response.headers.get('Content-Type'))
            ) {
                filename += CONTENT_TYPE_FILE_SUFFIX_MAP[response.headers.get('Content-Type')];
            }
        }

        return filename;
    }

    protected getAttachmentFilename(response: Response): string {
        if (! response.headers.has('Content-Disposition')) {
            return null;
        }

        const match = CONTENT_DISPOSITION_FILENAME_REGEX.exec(response.headers.get('Content-Disposition'));
        if (match === null) {
            return null;
        }

        return match[3];
    }
}
