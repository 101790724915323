import { Directive, Host } from '@angular/core';
import { SOURCE_DRIVERS, SOURCE_DRIVERS_DIRECTIONS } from '../styles/constants';
import { RoMapDirective } from './ro-map.directive';

@Directive({
    selector: '[roMapStates]',
    exportAs: 'roMapStates',
})
export class RoMapStatesDirective {
    private hoveredRoutes: number[] = [];
    private clickedRoutes: number[] = [];
    private clickedDrivers: number[] = [];

    constructor(
        @Host() private readonly roMap: RoMapDirective,
    ) {
        if (!roMap) {
            throw new Error('RoMapStatesDirective requires an instance of RoMapDirective on the element.');
        }
    }

    get mapbox(): any {
        return this.roMap.getMap();
    }

    // click driver routes
    public selectDriverRoutes(deliveryOrderIDs: number[] = []): void {
        this.clickedRoutes.forEach((id) => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS_DIRECTIONS, id }, { selected: false });
        });

        deliveryOrderIDs.forEach((id) => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS_DIRECTIONS, id }, { selected: true });
        });

        this.clickedRoutes = [...deliveryOrderIDs];
    }

    // hover driver routes
    public hoverRoutes(deliveryOrderIDs: number[] = []): void {
        this.hoveredRoutes.forEach(id => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS_DIRECTIONS, id }, { hovered: false });
        });

        deliveryOrderIDs.forEach(id => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS_DIRECTIONS, id }, { hovered: true });
        });

        this.hoveredRoutes = [...deliveryOrderIDs];
    }

    // click drivers
    public selectDrivers(deliveryDriverIDs: number[] = []) {
        this.clickedDrivers.forEach(id => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS, id }, { selected: false });
        });

        deliveryDriverIDs.forEach(id => {
            this.mapbox.setFeatureState({ source: SOURCE_DRIVERS, id }, { selected: true });
        });

        this.clickedDrivers = [...deliveryDriverIDs];
    }

    // reset
    public clearStates() {
        if(!this.mapbox){
            return;
        }

        [SOURCE_DRIVERS_DIRECTIONS, SOURCE_DRIVERS].forEach(source => {
            this.mapbox.removeFeatureState({ source });
        });
    }
}
