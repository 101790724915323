import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserService } from './services/user.service';
import { UserConnectionService } from './services/user-connection.service';
import { PermissionService } from './services/permission.service';
import { RoleService } from './services/role.service';
import { UserStorage } from './services';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class UsersModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: UsersModule,
            providers: [
                UserService,
                UserStorage,
                UserConnectionService,
                RoleService,
                PermissionService
            ]
        };
    }
}
