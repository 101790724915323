
import {map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { unionBy } from 'lodash';

@Injectable()
export class CollectionStorage<T> {

    /**
     * @type {BehaviorSubject}
     */
    public subject: BehaviorSubject<T[]>;

    /**
     * @type {Observable}
     */
    public $: Observable<T[]>;

    constructor() {
        this.subject = new BehaviorSubject([]);
        this.$ = this.subject.asObservable();
    }

    /**
     * Replace current value with value
     *
     * @param value
     */
    public nextSubject(value: T[]): void {
        return this.subject.next(value);
    }

    /**
     * Current value in param and emits returned value
     *
     * @param callback
     */
    protected changeValue(callback: (value: T[]) => T[]): void {
        const value = this.subject.getValue();

        this.nextSubject(callback(value));
    }

    /**
     * Union array with currentValue, by key
     *
     * @param newResource
     * @param key
     */
    public unionSubject(newResource: T[], key: string): void {
        this.changeValue((oldResource) => unionBy<T>(newResource, oldResource, key));
    }

    /**
     * Union a single resource with currentValue, by key
     *
     * @param newResource
     * @param key
     */
    public singleUnionSubject(newResource: T, key: string): void {
        this.unionSubject([newResource], key);
    }

    /**
     * Remove resource from currentValue where id is for key
     *
     * @param id
     * @param key
     */
    public spliceSubject(id: number | string, key: string): void {
        this.changeValue((subjectValue) => {
            subjectValue.splice(subjectValue.findIndex((value) => value[key] === id), 1);

            return subjectValue;
        });
    }

    /**
     * Push value into currentValue
     *
     * @param value
     */
    public pushSubject(value: any): void {
        this.changeValue((subjectValue) => {
            subjectValue.push(value);

            return subjectValue;
        });
    }

    /**
     * Takes values from Observable and override store.
     * Returns same observable
     *
     * @param observable
     * @returns {Observable<[]>}
     */
    public nextObservable(observable: Observable<T[]>): Observable<T[]> {
        return observable.pipe(tap(
            (value) => this.nextSubject(value)
        ));
    }

    /**
     * Union observable resource array with
     * currentValue in subject, by key
     *
     * @param observable
     * @param key
     * @returns {Observable}
     */
    public unionObservable(observable: Observable<T[]>, key: string): Observable<T[]> {
        return observable.pipe(tap(
            (resource) => this.unionSubject(resource, key)
        ));
    }

    /**
     * Union a single resource from observable
     * into current subject value
     *
     * @param observable
     * @param key
     * @returns {Observable}
     */
    public singleUnionObservable(observable: Observable<T>, key: string): Observable<T> {
        return observable.pipe(tap(
            (resource) => this.singleUnionSubject(resource, key)
        ));
    }

    /**
     * Push observable value into store value
     *
     * @param observable
     * @returns {Observable}
     */
    public pushObservable(observable: Observable<T>): Observable<T> {
        return observable.pipe(tap(
            (value) => this.pushSubject(value)
        ));
    }

    /**
     * Splice/Remove a resource using observable
     *
     * @param observable
     * @param key
     * @returns {Observable<number|string>}
     */
    public spliceObservable(observable: Observable<number|string>, key: string): Observable<number|string> {
        return observable.pipe(tap(
            (id) => this.spliceSubject(id, key)
        ));
    }

    /**
     * Subscribe to a specific resource event
     */
    public find$(resourceID: number, key: string): Observable<T> {
        return this.$.pipe(
            map((resources) => resources.find((resource) => resource[key] === resourceID)));
    }

    /**
     * Subscribe to filtered
     */
    public get$(key: string, value: any): Observable<T[]> {
        return this.$.pipe(
            map((resources) => resources.filter((resource) => resource[key] === value)));
    }
}
