import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RefundTypeOption, refundTypeOptions, ValueTypeOption, valueTypeOptions } from '../../common/options';
import { ValueType } from '../../enums';
import { CompensationCalculator, CompensationCredit } from '../../interfaces';

@Component({
    selector: 'compensation-credit-form',
    template: require('./compensation-credit-form.component.html')
})
export class CompensationCreditFormComponent implements OnInit {
    @Input()
    public credit: CompensationCredit;

    @Input()
    public calculators: CompensationCalculator[];

    public form: FormGroup;

    public refundTypeOptions: RefundTypeOption[] = refundTypeOptions;

    public valueTypeOptions: ValueTypeOption[] = valueTypeOptions;

    public ValueType: typeof ValueType = ValueType;

    public refundType = 'money_back';

    constructor(protected formBuilder: FormBuilder) {
    }

    public ngOnInit(): void {

        this.form = this.formBuilder.group({
            reason: [null, Validators.required],
            value: [null, Validators.required],
            valueType: [null, Validators.required],
            refundType: [null, Validators.required]
        });

        if (this.credit) {
            this.form.patchValue(this.credit);
        }
    }

    get valueTypeCtrl(): AbstractControl {
        return this.form.get('valueType');
    }

    public reset(): void {
        this.form.reset();
    }

    public validate(): void {
        this.form.markAsTouched();
    }

    public isValid(): boolean {
        return this.form.valid;
    }

    public getValue(): Pick<CompensationCredit, 'reason' | 'value' | 'valueType' | 'refundType'> {
        return this.form.value;
    }

    public setReason(reason: string): void {
        this.form.get('reason').setValue(reason);
    }
}
