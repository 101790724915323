import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { RestaurantPaymentProcessor } from '../interfaces/restaurant-payment-processor';

export interface GetRestaurantPaymentProcessors {
    clientKey: string;
}

@Injectable()
export class RestaurantPaymentProcessorService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);

        this.apiUrl = hungrigClient.baseUrl;
    }

    public getRestaurantPaymentProcessors(
        options: GetRestaurantPaymentProcessors): Observable<RestaurantPaymentProcessor[]> {
        const search = ParamsUtil.toHttpParams(options);
        return this.get('/restaurant-payment-processor', {search});
    }

    public updateRestaurantPaymentProcessor(
        rPaymentProcessorID: number, data: Partial<RestaurantPaymentProcessor>): Observable<boolean> {
        return this.put(`/restaurant-payment-processor/${rPaymentProcessorID}`, data);
    }
}
