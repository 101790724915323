import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { endOfDay, format, startOfDay } from 'date-fns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryCompany } from '../../interfaces';
import { MultipleSelect } from '@ro-ngx/core';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { DeliveryCompanyService } from '../../services';
import * as FileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { isNil } from 'lodash';

@Component({
    selector: 'delivery-driver-summaries',
    template: require('./delivery-driver-summaries.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryDriverSummariesComponent implements OnInit {
    public form: FormGroup;

    public deliveryCompany: DeliveryCompany;

    public deliveryCompanySelect: MultipleSelect;

    public loading$: BehaviorSubject<boolean>;

    constructor(
        protected deliveryCompanyService: DeliveryCompanyService,
        protected formBuilder: FormBuilder,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.loading$ = new BehaviorSubject<boolean>(false);

        this.form = this.formBuilder.group({
            from: [null, Validators.required],
            to: [null, Validators.required],
        });

        this.initDeliveryCompanySelect();
    }

    public onRangeSelected(range: Date[]): void {
        if (isNil(range)) {
            this.form.patchValue({
                from: null,
                to: null
            });

            return;
        }

        const dateFormat = 'YYYY-MM-DD HH:mm:ss';

        this.form.patchValue({
            from: format(startOfDay(range[0]), dateFormat),
            to: format(endOfDay(range[1]), dateFormat)
        });
    }

    public downloadSummary(): void {
        if (this.form.invalid || this.isDeliveryCompanyInvalid()) {
            return;
        }

        this.loading$.next(true);

        this.deliveryCompanyService.getDriverSummaries(this.deliveryCompany.deliveryCompanyID, this.form.value)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe((file) => FileSaver.saveAs(file.blob, file.filename));
    }

    public isRangeInvalid(): boolean {
        const from = this.form.get('from');
        const to = this.form.get('to');

        return from.invalid || to.invalid;
    }

    public isDeliveryCompanyInvalid(): boolean {
        return isNil(this.deliveryCompany);
    }

    protected initDeliveryCompanySelect(): void {
        this.deliveryCompanySelect = new MultipleSelect({
            onSelection: (deliveryCompany) => this.deliveryCompany = deliveryCompany,
            items$: (multipleSelect) => {
                const debounceTimeMs: number = 600;
                return multipleSelect.searchText$
                    .pipe(
                        tap(() => multipleSelect.setLoading(true)),
                        debounceTime(debounceTimeMs),
                        switchMap((search: string) => this.deliveryCompanyService.getCompanies({ search })),
                        tap(() => {
                            multipleSelect.setLoading(false);
                            this.changeDetectorRef.markForCheck();
                        })
                    );
            },
            itemKeyVisible: 'companyName',
            itemKeyId: 'deliveryCompanyID',
            maxSelections: 1
        });
    }
}
