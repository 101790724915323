import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from './http/push-notification.service';
import { PushNotification } from './http/push-notification';
import { PaginateBody } from '@ro-ngx/core';
import { FormControl } from '@angular/forms';
import {finalize, flatMap, tap} from "rxjs/operators";

@Component({
    selector: 'push-notification-history',
    template: require('./push-notification-history.component.html'),
    styles: [
        '#push-notification-history { margin-bottom: 0; }'
    ]
})

export class PushNotificationHistoryComponent implements OnInit {
    public notifications: PushNotification[];
    public paginate: PaginateBody;
    public currentPage: FormControl;
    public loading: {[key: string]: boolean} = {
        init: false,
        page: false
    };

    constructor(protected notificationService: PushNotificationService) {
    }

    public ngOnInit(): void {
        this.currentPage = new FormControl(1);
        const perPage: number = 15;

        this.currentPage.valueChanges.pipe(
            tap(() => this.loading['page'] = true),
            flatMap((page) =>
                this.notificationService.getNotifications(perPage, page).pipe(
                    finalize(() => this.loading['page'] = false)
                )

            ))
            .subscribe((response) => {
                this.notifications = response.data;
                this.paginate = response.meta;
            });

        this.loading.init = true;
        this.notificationService.getNotifications().pipe(
                finalize(() => this.loading.init = false)
        )
            .subscribe((response) => {
                this.notifications = response.data;
                this.paginate = response.meta;
            });
    }
}
