import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySupport } from './currency-support';

@Pipe({
    name: 'currencyFormat'
})

export class CurrencyFormatPipe implements PipeTransform {

    constructor(protected currencySupport: CurrencySupport) {}

    public transform(value: any, args: any[] = []): any {
        return this.currencySupport.format(value);
    }
}
