
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HungrigClient } from '../client/hungrig/hungrig-client';
import { RequestOptionsArgs, Response, Request } from '@angular/http';
import { Observable } from 'rxjs';
import { ResponseFactory } from '../response/hungrig/response-factory';
import { ErrorFactory } from '../response/hungrig/error/error-factory';

@Injectable()
export class BaseService {

    public apiUrl: string = '';

    constructor(public hungrigClient: HungrigClient) { }

    public url(url: string): string {
        return this.apiUrl + url;
    }

    public request<T>(url: string | Request, options?: RequestOptionsArgs): Observable<T> {
        return this.hungrigClient.request(url instanceof Request ? url : this.url(url), options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }
    public get<T>(url: string, options?: RequestOptionsArgs): Observable<T> {
        return this.hungrigClient.get(this.url(url), options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public post<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<T> {
        return this.hungrigClient.post(this.url(url), body, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public put<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<T> {
        return this.hungrigClient.put(this.url(url), body, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public delete<T>(url: string, options?: RequestOptionsArgs): Observable<T> {
        return this.hungrigClient.delete(this.url(url), options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.hungrigClient.patch(url, body, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.hungrigClient.head(url, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }

    public options(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.hungrigClient.options(url, options).pipe(
            map(ResponseFactory.toData)).pipe(
            catchError(ErrorFactory.throwObservable));
    }
}
