import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DiscountType } from '../interfaces';
import { CurrencySupport } from '@ro-ngx/core';

@Pipe({
    name: 'discountAmount'
})
export class DiscountAmountPipe implements PipeTransform {
    constructor(
        protected currencySupport: CurrencySupport,
        protected translateService: TranslateService
    ) {}

    public transform(value: any, type: DiscountType): string {
        switch (type) {
            case DiscountType.Amount:
                return this.currencySupport.text(value);
            case DiscountType.Percentage:
                return (value as number).toFixed(0).toString() + '%';
            case DiscountType.Delivery:
                return this.translateService.instant('coupon.discount_type_delivery_label');
            default:
                return value;
        }
    }
}
