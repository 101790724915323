export class Error<T> {
    public statusCode: number;
    public errorCode: number;
    public errorMessage: string;
    public errors: any[] | { [key: string]: any } | T;

    public setErrors(errors: any[] | { [key: string]: any } | T): this {
        this.errors = errors;
        return this;
    }

    public setStatusCode(statusCode: number): this {
        this.statusCode = statusCode;
        return this;
    }

    public setErrorCode(errorCode: number): this {
        this.errorCode = errorCode;
        return this;
    }

    public setErrorMessage(errorMessage: string): this {
        this.errorMessage = errorMessage;
        return this;
    }
}
