import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from "@ro-ngx/core";
import { Observable } from "rxjs";
import { RestaurantCart } from "../interfaces/restaurant-cart";

@Injectable()
export class RestaurantCartService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public findWhitelabelRestaurantCart(clientKey: string): Observable<RestaurantCart> {
        return this.get(`/restaurant/${clientKey}/whitelabel/restaurantCart`);
    }

    public findHungrigRestaurantCart(clientKey: string): Observable<RestaurantCart> {
        return this.get(`/restaurant/${clientKey}/hungrig/restaurantCart`);
    }

    public findRestaurantCart(restaurantCartID: number): Observable<RestaurantCart> {
        return this.get(`/restaurant-cart/${restaurantCartID}`);
    }

    public updateRestaurantCart(restaurantCartID: number, data: Partial<RestaurantCart>): Observable<RestaurantCart> {
        return this.put(`/restaurant-cart/${restaurantCartID}`, data);
    }
}
