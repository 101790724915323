import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserStorage } from '../../services';
import { Restaurant } from '@ro-ngx/restaurants';
import { MultipleSelect } from '@ro-ngx/core';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'restaurant-connection-select',
    template: require('./restaurant-connection-select.component.html')
})
export class RestaurantConnectionSelectComponent implements OnInit {
    @Output()
    public onUpdate: EventEmitter<Restaurant[]> = new EventEmitter<Restaurant[]>();

    @Input()
    public placeholder: string;

    public multipleSelect: MultipleSelect;

    constructor(
        protected userStorage: UserStorage,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        const filterFn = (text) => this.userStorage.restaurantConnections$.pipe(
            map((restaurants) => {
                if (! text) {
                    return restaurants;
                }

                return restaurants.filter(
                    (restaurant) => restaurant.restaurantName.toLowerCase().includes(text.toLowerCase())
                );
            })
        );

        this.multipleSelect = new MultipleSelect({
            items$: (multipleSelect: MultipleSelect) => {
                return multipleSelect.searchText$.pipe(
                    switchMap(filterFn),
                    tap(() => this.changeDetectorRef.markForCheck())
                );
            },
            itemKeyId: 'clientKey',
            itemKeyVisible: 'restaurantName',
            onSelections: (restaurants) => this.onUpdate.emit(restaurants),
            placeholder: this.placeholder
        });
    }
}
