import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { UserConnection } from '../interfaces/user-connection';

export interface FindUserConnectionOptions {
    includes?: string[];
}

@Injectable()
export class UserConnectionService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public findUserConnection(
        userConnectionID: number, options: FindUserConnectionOptions
    ): Observable<UserConnection> {
        return this.get(`/user-connection/${userConnectionID}`, {
            search: ParamsUtil.toHttpParams(options)
        });
    }
}
