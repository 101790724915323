import { NgModule } from '@angular/core';
import { DeliveryOrderActionsComponent } from './delivery-order-actions.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CreateActionModalComponent } from './create-action';
import { ReactiveFormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RoNg2Module,
        ModalModule,
        ReactiveFormsModule,
        MyDatePickerModule
    ],
    exports: [ DeliveryOrderActionsComponent ],
    declarations: [
        DeliveryOrderActionsComponent,
        CreateActionModalComponent
    ],
    providers: [],
    entryComponents: [ DeliveryOrderActionsComponent ]
})
export class DeliveryOrderActionsModule {
}
