import { ModuleWithProviders, NgModule } from '@angular/core';
import { DeliveryManageModuleConfig } from './delivery-manage-module-config';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class DeliveryManageConfigModule {
    public static forRoot(config: DeliveryManageModuleConfig): ModuleWithProviders {
        return {
            ngModule: DeliveryManageConfigModule,
            providers: [
                {
                    provide: DeliveryManageModuleConfig,
                    useValue: config
                }
            ]
        };
    }
}
