import { NgModule } from '@angular/core';
import { PushNotificationComponent } from './push-notification.component';
import { PushNotificationService } from './http/push-notification.service';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RoNg2Module } from '@ro-ngx/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SendPushNotificationComponent } from './send-push-notification.component';
import { PushNotificationHistoryComponent } from './push-notification-history.component';
import { MyDatePickerModule } from 'mydatepicker';
import { TimeSelectModule } from './time-select/time-select.module';
import { PushNotificationConfigModule } from "./push-notification-config.module";

const routes: Routes = [
    {
        path: '',
        component: PushNotificationComponent,
        children: [
            { path: 'send', component: SendPushNotificationComponent },
            { path: 'history', component: PushNotificationHistoryComponent },
            { path: '', pathMatch: 'full', redirectTo: 'send' }
        ]
    },
];

@NgModule({
    imports: [
        PushNotificationConfigModule,
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        RoNg2Module,
        ModalModule,
        MyDatePickerModule,
        TimeSelectModule
    ],
    exports: [],
    declarations: [
        PushNotificationComponent,
        SendPushNotificationComponent,
        PushNotificationHistoryComponent
    ],
    providers: [PushNotificationService],
})
export class PushNotificationModule {
}
