import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { LocaleConfig } from './locale-config';
import { LocaleService } from './locale.service';
import { IfCountryDirective, IfLanguageDirective } from './directives';

@NgModule({
    imports: [],
    exports: [
        IfCountryDirective,
        IfLanguageDirective
    ],
    declarations: [
        IfCountryDirective,
        IfLanguageDirective
    ],
    providers: [],
})
export class LocaleModule {
    public static forRoot(config: LocaleConfig): ModuleWithProviders {
        return {
            ngModule: LocaleModule,
            providers: [
                { provide: LocaleConfig, useValue: config },
                LocaleService
            ]
        };
    }
}
