import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { DialogContract } from './dialog.contract';
import { DialogComponent } from './dialog.component';
import { DialogDirective } from './dialog.directive';
import { DialogService } from './dialog.service';
import { Subscription } from 'rxjs';
import {finalize} from "rxjs/operators";

@Component({
    selector: 'dialogs',
    template: '<ng-template dialogHost></ng-template>'
})
export class DialogQueueComponent implements OnInit, OnDestroy {
    protected queue: DialogContract<any>[] = [];
    protected currentDialog: Subscription;
    protected dialogs: Subscription;
    @ViewChild(DialogDirective) protected host: DialogDirective;

    constructor(
        protected dialogService: DialogService,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    public ngOnInit(): void {
        this.dialogs = this.dialogService.dialog$.subscribe((dialog: DialogContract<any>) => {
            if (this.currentDialog === undefined || this.currentDialog.closed) {
                this.setCurrent(dialog);
                return;
            }

            this.queue.push(dialog);
        });
    }

    public ngOnDestroy(): void {
        this.dialogs.unsubscribe();
    }

    protected setCurrent(dialog: DialogContract<any>): void {
        this.currentDialog = dialog.result$.pipe(
            finalize(() => {
                this.host.viewContainerRef.clear();
                const next = this.queue.shift();
                if (next !== undefined) {
                    this.setCurrent(next);
                }
            })
        ).subscribe();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(dialog.component);
        const component = this.host.viewContainerRef.createComponent(componentFactory);
        (component.instance as DialogComponent).dialog = dialog;
    }
}
