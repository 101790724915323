import { ModuleWithProviders, NgModule } from '@angular/core';
import { DeliveryDistrictsModuleConfig } from './delivery-districts-module-config';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class DeliveryDistrictsConfigModule {
    public static forRoot(config: DeliveryDistrictsModuleConfig): ModuleWithProviders {
        return {
            ngModule: DeliveryDistrictsConfigModule,
            providers: [
                {
                    provide: DeliveryDistrictsModuleConfig,
                    useValue: config
                }
            ]
        };
    }
}
