import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CouponService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginate } from '@ro-ngx/core';
import { CouponGroup } from '../../../interfaces';
import { CouponGroupsState } from './coupon-groups.state';
import { debounceTime, flatMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { CreateCouponGroupEvent } from './create-group';
import { UserStorage } from '@ro-ngx/users';
import { CouponManagerConfig } from '../coupon-manager-config';
import { Restaurant } from '@ro-ngx/restaurants';

@Component({
    selector: 'coupon-groups',
    template: require('./coupon-groups.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponGroupsComponent extends BaseComponent implements OnInit, OnDestroy {
    public paginatedGroups$: BehaviorSubject<Paginate<CouponGroup[]>>;
    public selectedRestaurants$: BehaviorSubject<Restaurant[]>;
    public currentPage: FormControl;
    public filter: FormControl;
    public loadingPage$: BehaviorSubject<boolean>;
    public showCreateGroup: boolean = false;

    constructor(
        protected couponService: CouponService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected couponGroupsState: CouponGroupsState,
        protected userStorage: UserStorage,
        public config: CouponManagerConfig
    ) {
        super();
    }

    public ngOnInit(): void {
        this.loadingPage$ = new BehaviorSubject<boolean>(false);
        this.selectedRestaurants$ = new BehaviorSubject<Restaurant[]>([]);
        this.paginatedGroups$ = new BehaviorSubject<Paginate<CouponGroup[]>>(null);

        this.currentPage = new FormControl(1);
        this.filter = new FormControl(null);

        const currentPage$ = this.currentPage.valueChanges
            .pipe(startWith(this.currentPage.value));

        const debounceTimeMs: number = 250;
        const filter$ = this.filter.valueChanges
            .pipe(
                startWith(this.filter.value),
                debounceTime(debounceTimeMs)
            );

        combineLatest(
                this.userStorage.restaurantConnections$,
                this.selectedRestaurants$,
                currentPage$,
                filter$
            )
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(() => this.loadingPage$.next(true)),
                flatMap(([restaurantConnections, selectedRestaurants, page, filter]) => {
                    const options = {
                        page, filter
                    };

                    if (selectedRestaurants.length === 0) {
                        selectedRestaurants = restaurantConnections;
                    }

                    if (this.config.asAdmin) {
                        options['clientKey'] = ['hungrig'];
                    } else {
                        options['clientKey'] = selectedRestaurants.map((restaurant) => restaurant.clientKey);
                    }

                    return this.couponService.getGroups(options);
                })
            )
            .subscribe((response) => {
                this.paginatedGroups$.next(response);
                this.loadingPage$.next(false);
            });

        this.couponGroupsState.refresh$.pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => this.currentPage.setValue(1));
    }

    public createGroup(params: CreateCouponGroupEvent): void {
        this.showCreateGroup = false;

        if (params === null) {
            return;
        }

        this.couponService.createGroup(params)
            .subscribe((group) => {
                this.filter.setValue(group.title);
                this.router.navigate(['groups', group.couponGroupID], { relativeTo: this.route });
            });
    }
}
