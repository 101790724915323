import { QueryEncoder as NgQueryEncoder } from '@angular/http';

export class QueryEncoder extends NgQueryEncoder {
    public encodeKey(k: string): string {
        return encodeURIComponent(k);
    }

    public encodeValue(v: string): string {
        return encodeURIComponent(v);
    }
}
