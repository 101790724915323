import { ModuleWithProviders, NgModule } from '@angular/core';
import { DiscountAmountPipe } from './pipes';
import { CouponService } from './services';
import { CampaignGroupService } from './services/campaign-group.service';

@NgModule({
    imports: [],
    exports: [
        DiscountAmountPipe
    ],
    declarations: [
        DiscountAmountPipe
    ],
    providers: [],
})
export class CouponsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: CouponsModule,
            providers: [
                CouponService,
                CampaignGroupService
            ]
        };
    }
}
