import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionName, User } from '@ro-ngx/users';
import { of ,  Observable ,  throwError as _throw } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationConfig } from "../authentication-config";
import { AuthorizedService } from "./authorized.service";
import { Location } from "@angular/common";

@Injectable()
export class AuthAdminGuard implements CanActivate {

    protected user: User;

    public constructor(protected authenticationConfig: AuthenticationConfig,
                       protected authorizedService: AuthorizedService,
                       protected location: Location,
                       protected router: Router) {
        this.authorizedService.user$
            .subscribe((user) => this.user = user);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.guard(
            state.url,
            route.data.hasOwnProperty('permissionGroups') ? route.data.permissionGroups : []
        );
    }

    public canNg1Activate(url: string, permissionGroups: PermissionName[][]): Observable<boolean> {
        return this.guard(url, permissionGroups);
    }

    protected guard(wantedStateUrl: string, permissionGroups: PermissionName[][]): Observable<boolean> {
        return this.getUser().pipe(
            catchError((error) => {
                this.loginRedirect(wantedStateUrl);
                return _throw(error);
            }),

            tap((user) => {
                if ( ! this.hasConnection(user)) {
                    this.loginRedirect(wantedStateUrl);
                    return _throw(new Error());
                }
            }),

             tap((user) => {
                 if ( ! this.hasPermissionNames(user, permissionGroups)) {
                     this.location.back();
                     return _throw(new Error());
                 }
             }),

             map((user) => !! user),
             catchError(() => of(false))
        );
    }

    protected getUser(): Observable<User> {
        if (this.user) {
            return of(this.user);
        }

        return this.authorizedService.findMyself();
    }

    protected hasConnection(user: User): boolean {
        if (this.authenticationConfig.forceUserConnectionType) {
            return user.hasUserConnection(this.authenticationConfig.forceUserConnectionType, '*');
        }

        return true;
    }

    protected hasPermissionNames(user: User, permissionGroups: PermissionName[][]): boolean {
        if (permissionGroups.length) {
            return !! permissionGroups.find((permissionNames) => user.hasPermissions(permissionNames));
        }

        return true;
    }

    protected loginRedirect(url: string): void {
        this.router.navigate([this.authenticationConfig.unauthorizedUrl], {
            queryParams: { url }
        }).then();
    }
}
